var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Select export date",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-position": "top" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Date" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "date",
                  format: "dd/MM/yyyy",
                  "range-separator": "To",
                  "start-placeholder": "From date",
                  "end-placeholder": "To date",
                },
                model: {
                  value: _vm.form.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "date", $$v)
                  },
                  expression: "form.date",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              },
              [_vm._v("Close")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onSubmitOk } },
              [_vm._v("Select")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
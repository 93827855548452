var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "order-modal",
          attrs: {
            title:
              _vm.status == "create"
                ? "New export warehouse"
                : "Update export warehouse",
            visible: _vm.visible,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 0 } },
                    [
                      _c("product-order-form", {
                        ref: "product",
                        attrs: {
                          form: _vm.form,
                          statusForm: _vm.status,
                          orderStatus: _vm.orderStatus,
                        },
                      }),
                      _vm.status == "update"
                        ? _c("export-print-information-form", {
                            staticStyle: { "margin-top": "24px" },
                            attrs: {
                              form: _vm.form,
                              statusForm: _vm.status,
                              orderStatus: _vm.orderStatus,
                            },
                            on: {
                              "submit:ok": function ($event) {
                                return _vm.$emit("submit:ok")
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("export-warehouse-print", {
            ref: "printExportWarehouse",
            staticStyle: { height: "0", overflow: "hidden" },
            attrs: { data: _vm.form },
          }),
          _c("ExportWarehousePrintPaymentRequest", {
            ref: "printExportWarehousePaymentRequest",
            staticStyle: { height: "0", overflow: "hidden" },
            attrs: { data: _vm.form },
          }),
          _c("template", { slot: "footer" }, [
            _c("div"),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn-save",
                    attrs: { loading: _vm.loading, type: "primary" },
                    on: { click: _vm.handlePrintPaymentRequest },
                  },
                  [_vm._v("Print Payment Request")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn-save",
                    attrs: { loading: _vm.loading, type: "primary" },
                    on: { click: _vm.handlePrint },
                  },
                  [_vm._v("Print")]
                ),
              ],
              1
            ),
            _vm.form.type == _vm.ExportWarehouseType.Export
              ? _c(
                  "div",
                  [
                    _vm.status == "create"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "btn-save",
                            attrs: { loading: _vm.loading, type: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.status == "create"
                                  ? _vm.createData()
                                  : _vm.updateData()
                              },
                            },
                          },
                          [_vm._v("Save")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c("select-single-date-modal", {
        ref: "SelectDateModal",
        on: { submitOk: _vm.handleSelectDateOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
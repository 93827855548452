// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#003586",
	"menuText": "white",
	"menuActiveText": "#1596d2",
	"subMenuActiveText": "white",
	"menuBg": "#1a2739",
	"menuHover": "rgba(73, 85, 105, 0.4)",
	"subMenuBg": "#001429",
	"subMenuHover": "#198fff",
	"sideBarWidth": "240px",
	"linkColor": "#037aff"
};
module.exports = exports;

"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
_vue.default.use(_vueRouter.default);
/* Layout */

/* Router Modules */
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: "/redirect",
  component: _index.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index.vue"));
      });
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/auth/index.vue"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect.vue"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404.vue"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401.vue"));
    });
  },
  hidden: true
}, {
  path: "/",
  redirect: "/dashboard",
  hidden: true
}, {
  path: "/dashboard",
  component: _index.default,
  // redirect: "/merchant/index",
  meta: {
    title: "Dashboard",
    icon: "dashboard",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/admin/index.vue"));
      });
    },
    name: "dashboard-index",
    meta: {
      title: "Dashboard",
      permission: true
    }
  }]
}, {
  path: "/inventory",
  component: _index.default,
  redirect: "/inventory/index",
  meta: {
    title: "Inventory",
    icon: "hand-holding-usd",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/inventory/index.vue"));
      });
    },
    name: "inventory-index",
    meta: {
      title: "Inventory",
      permission: true
    }
  }]
}, {
  path: "/warehouse",
  component: _index.default,
  redirect: "/warehouse/index",
  meta: {
    title: "Warehouse",
    icon: "warehouse",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/warehouse/index.vue"));
      });
    },
    name: "warehouse-index",
    meta: {
      title: "Warehouse",
      permission: true
    }
  }]
}, {
  path: "/report",
  component: _index.default,
  redirect: "/report/index",
  meta: {
    title: "Report",
    icon: "clipboard",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/report/index.vue"));
      });
    },
    name: "report-index",
    meta: {
      title: "Report",
      permission: true
    }
  }]
}, {
  path: "/shopee",
  component: _index.default,
  redirect: "/shopee/product",
  meta: {
    title: "Shopee",
    icon: "hand-holding-usd",
    permission: true
  },
  alwaysShow: true,
  children: [{
    path: "product",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/shopee-product/index.vue"));
      });
    },
    name: "product-index",
    meta: {
      title: "Product",
      permission: true
    }
  }, {
    path: "order",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/shopee-order/index.vue"));
      });
    },
    name: "order-index",
    meta: {
      title: "Order",
      permission: true
    }
  }]
},
// {
//   path: "/shopify",
//   component: Layout,
//   redirect: "/shopify/product",
//   meta: {
//     title: "Shopify",
//     icon: "hand-holding-usd",
//     permission: true
//   },
//   alwaysShow: true,
//   children: [
//     {
//       path: "product",
//       component: () => import("@/views/shopify-product/index.vue"),
//       name: "product-index",
//       meta: {
//         title: "Product",
//         permission: true
//       },
//     },
//     {
//       path: "order",
//       component: () => import("@/views/shopify-order/index.vue"),
//       name: "order-index",
//       meta: {
//         title: "Order",
//         permission: true
//       },
//     },
//   ]
// },
{
  path: "/consignment",
  component: _index.default,
  redirect: "/consignment/index",
  meta: {
    title: "Consignment",
    icon: "documentation",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/consignment/index.vue"));
      });
    },
    name: "consignment-index",
    meta: {
      title: "Consignment",
      permission: true
    }
  }]
}, {
  path: "/return-order",
  component: _index.default,
  redirect: "/return-order/index",
  meta: {
    title: "Return Order",
    icon: "documentation",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/refund-consign/index.vue"));
      });
    },
    name: "return-order-index",
    meta: {
      title: "Return Order",
      permission: true
    }
  }]
}, {
  path: "/order",
  component: _index.default,
  redirect: "/order/index",
  meta: {
    title: "Order",
    icon: "file-invoice-dollar",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/index.vue"));
      });
    },
    name: "order-index",
    meta: {
      title: "Order",
      permission: true
    }
  }, {
    path: "order-edit",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/index.vue"));
      });
    },
    name: "order-edit",
    hidden: true,
    meta: {
      title: "Edit Order",
      permission: true
    }
  }]
}, {
  path: "/gift",
  component: _index.default,
  redirect: "/gift/index",
  meta: {
    title: "Gift",
    icon: "gift-box",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/give-away/index.vue"));
      });
    },
    name: "gift-index",
    meta: {
      title: "Gift",
      permission: true
    }
  }]
}, {
  path: "/address",
  component: _index.default,
  redirect: "/address/index",
  meta: {
    title: "Pickup Address",
    icon: "pin",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/pickup-address/index.vue"));
      });
    },
    name: "pickup-address-index",
    meta: {
      title: "Pickup Address",
      permission: true
    }
  }]
}, {
  path: "/customer",
  component: _index.default,
  redirect: "/customer/index",
  meta: {
    title: "Customer",
    icon: "peoples",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/customer/index.vue"));
      });
    },
    name: "customer-index",
    meta: {
      title: "Customer",
      permission: true
    }
  }]
}, {
  path: "/member",
  component: _index.default,
  redirect: "/member/index",
  meta: {
    title: "member",
    icon: "peoples",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/member/index.vue"));
      });
    },
    name: "member-index",
    meta: {
      title: "Member",
      permission: true
    }
  }]
}, {
  path: "/config",
  component: _index.default,
  redirect: "/config/index",
  meta: {
    title: "Staff",
    icon: "people",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/staff/index.vue"));
      });
    },
    name: "staff",
    meta: {
      title: "Staff",
      permission: false
    }
  }
  // {
  //   path: "staff",
  //   component: () => import("@/views/user/index.vue"),
  //   name: "staff",
  //   meta: {
  //     title: "Staff",
  //     permission: true
  //   },
  // },
  // {
  //   path: "role",
  //   component: () => import("@/views/permission/role.vue"),
  //   name: "Role",
  //   meta: {
  //     title: "Role",
  //     permission: true
  //   },
  // },
  ]
}, {
  path: "/setting",
  component: _index.default,
  redirect: "/setting/index",
  meta: {
    title: "Setting",
    icon: "control-fill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/config/index.vue"));
      });
    },
    name: "setting",
    meta: {
      title: "Setting",
      permission: true
    }
  }]
}, {
  path: "/message-template",
  component: _index.default,
  redirect: "/message-template/index",
  meta: {
    title: "Message template",
    icon: "control-fill",
    permission: true
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/message-template/index.vue"));
      });
    },
    name: "message-template",
    meta: {
      title: "Message template",
      permission: true
    }
  }]
}, {
  path: "/profile",
  component: _index.default,
  redirect: "/profile/index",
  meta: {
    title: "profile",
    icon: "qq",
    permission: false
  },
  children: [{
    path: "index",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/profile/index.vue"));
      });
    },
    name: "Profile",
    meta: {
      title: "profile",
      permission: false
    }
  }]
}, {
  path: "*",
  redirect: "/404",
  hidden: true
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [
  // 404 page must be placed at the end !!!
  // {
  //   path: '*',
  //   redirect: '/404',
  //   hidden: true
  // }
];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "hash",
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0,
        x: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
}
var _default = exports.default = router;
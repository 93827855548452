var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "date",
                  format: "dd/MM/yyyy",
                  "value-format": "yyyy-MM-dd",
                },
                on: {
                  change: function ($event) {
                    return _vm.fetchData()
                  },
                },
                model: {
                  value: _vm.query.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "date", $$v)
                  },
                  expression: "query.date",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.data,
            fit: "",
            "summary-method": _vm.getSummaries,
            "show-summary": "",
          },
          on: { "row-click": _vm.handleDetail },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Staff", prop: "staff.name" },
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Order" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.totalOrders)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Quantity" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.sumQuantity)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Origin" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.sumOrigin)) + "đ "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Price" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.sumReal)) + "đ "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Profit" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.sumProfit)) + "đ "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
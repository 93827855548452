"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shopeeProductApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var shopeeProductApi = exports.shopeeProductApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/shopeeProduct',
      params: params
    });
  },
  sync: function sync() {
    return (0, _request.default)({
      url: '/shopeeProduct/sync',
      method: 'POST'
    });
  }
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colsExcelTable = void 0;
var colsExcelTable = exports.colsExcelTable = [{
  "label": "Date Created",
  "rowspan": true,
  "prop": "createdAt",
  "format": true
}, {
  "label": "Receiver Name",
  "rowspan": true,
  "prop": "receiverName"
}, {
  "label": "Receiver Phone",
  "rowspan": true,
  "prop": "receiverPhone"
}, {
  "label": "Receiver Address",
  "rowspan": true,
  "prop": "receiverFullAddress"
}, {
  "label": "Staff",
  "rowspan": true,
  "prop": "staff.name"
}, {
  "label": "Purpose",
  "rowspan": true,
  "prop": "purpose"
}, {
  "label": "Note",
  "rowspan": true,
  "prop": "note"
}, {
  "label": "Product Name",
  "rowspan": false,
  "prop": "product.name"
}, {
  "label": "Product Quantity",
  "rowspan": false,
  "prop": "productQuantity"
}];
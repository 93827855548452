"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UnitTrans = exports.Unit = exports.Shape = void 0;
var Unit;
(function (Unit) {
  Unit["Box"] = "BOX";
  Unit["Bottle"] = "BOTTLE";
  Unit["Package"] = "PACKAGE";
  Unit["Bag"] = "BAG";
})(Unit || (exports.Unit = Unit = {}));
var UnitTrans;
(function (UnitTrans) {
  UnitTrans["BOX"] = "H\u1ED9p";
  UnitTrans["BOTTLE"] = "L\u1ECD";
  UnitTrans["PACKAGE"] = "Th\xF9ng";
  UnitTrans["BAG"] = "T\xFAi";
})(UnitTrans || (exports.UnitTrans = UnitTrans = {}));
var Shape;
(function (Shape) {
  Shape["Capsule"] = "CAPSULE";
  Shape["Water"] = "WATER";
})(Shape || (exports.Shape = Shape = {}));
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { overflow: "hidden", height: "0" } }, [
    _c("div", { attrs: { id: "giveaway-excel-table" } }, [
      _c("table", [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.cols, function (item, index) {
              return _c("td", { key: index }, [
                _vm._v(" " + _vm._s(item.label) + " "),
              ])
            }),
            0
          ),
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.dataTransform, function (item, index) {
              return _c(
                "tr",
                { key: index },
                _vm._l(_vm.cols, function (colsItem, colsIndex) {
                  return item.rowspan != 0 || !colsItem.rowspan
                    ? _c(
                        "td",
                        {
                          key: colsIndex,
                          attrs: {
                            rowspan: colsItem.rowspan ? item.rowspan : 1,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.generateValue(
                                  item,
                                  colsItem.prop,
                                  colsItem.format
                                )
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                0
              )
            }),
            _c("tr"),
            _c("tr"),
          ],
          2
        ),
      ]),
      _c("table", [
        _c("thead", [
          _c(
            "tr",
            [
              _c("td"),
              _vm._l(
                _vm.dataProductTransform.colsProducts,
                function (item, index) {
                  return _c("td", { key: index }, [
                    _vm._v(" " + _vm._s(index) + " "),
                  ])
                }
              ),
            ],
            2
          ),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.dataProductTransform.rowDate, function (item, index) {
            return _c(
              "tr",
              { key: index },
              [
                _c("td", [_vm._v(_vm._s(index))]),
                _vm._l(item, function (itemProduct, indexProduct) {
                  return _c("td", { key: indexProduct }, [
                    _vm._v(" " + _vm._s(itemProduct) + " "),
                  ])
                }),
              ],
              2
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm._l(_vm.config, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "setting_config" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.ConfigurationParamTrans[item.param])),
            ]),
            item.param == _vm.ConfigurationParam.VatFactor
              ? _c("el-input", {
                  directives: [
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: {
                        alias: "decimal",
                        groupSeparator: ".",
                        rightAlign: false,
                        autoGroup: true,
                        repeat: 20,
                        autoUnmask: true,
                        allowMinus: false,
                      },
                      expression:
                        "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                    },
                  ],
                  staticStyle: { width: "200px", "margin-left": "16px" },
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                })
              : _c("el-input", {
                  staticStyle: { width: "200px", "margin-left": "16px" },
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                }),
          ],
          1
        )
      }),
      _c(
        "el-button",
        {
          staticClass: "btn-save",
          staticStyle: { "margin-top": "16px" },
          attrs: { type: "primary", loading: _vm.loading },
          on: { click: _vm.handleUpdate },
        },
        [_vm._v("Update")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/esnext.iterator.map.js");
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _variables = _interopRequireDefault(require("@/styles/variables.scss"));
var _this = void 0; //
//
//
//
//
//
//
require("echarts/theme/macarons"); // echarts theme

var animationDuration = 6000;
var _default2 = exports.default = {
  mixins: [_resize.default],
  props: {
    dataChart: Array,
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    xAxisValue: {
      type: String,
      default: "date"
    },
    series: {
      type: Array,
      default: function _default() {
        return [{
          name: "Doanh số",
          type: "bar",
          itemStyle: {
            color: "#1b75bc"
          },
          yAxisIndex: 0,
          data: _this.dataChart.map(function (item) {
            return item.total["moneyIncome"] + item.total["moneyCommission"];
          })
        }];
      }
    },
    height: {
      type: String,
      default: "300px"
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this2 = this;
      var that = this;
      this.chart = _echarts.default.init(this.$el, "macarons");
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function formatter(params, ticket, callback) {
            var data = that.dataChart[params[0].dataIndex];
            var html = "";
            html += params[0].name + "<br />";
            html += "<span style=\"margin-right:5px; height: 10px;width: 10px;background-color: ".concat(params[0].color, ";border-radius: 50%;display: inline-block;\"></span>");
            html += "".concat(params[0].seriesName, ": ").concat(that.$formatNumberVN(params[0].value), " ").concat(data.unit.toLowerCase());
            return html;
          }
        },
        grid: {
          top: 10,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [{
          type: "value",
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: "category",
          data: this.dataChart.map(function (item) {
            return item[_this2.xAxisValue];
          })
          // axisLabel: {
          //   formatter: "{value} cuon",
          // },
        }],
        series: this.series
      });
    }
  }
};
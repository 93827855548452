var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title: _vm.status == "create" ? "New rank" : "Update rank",
        visible: _vm.visible,
        "close-on-click-modal": false,
        width: "1200px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.form, "label-position": "top" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Name", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Amount (VND)", prop: "amount" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.amount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "amount", $$v)
                          },
                          expression: "form.amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Discount Percent (%)",
                        prop: "discountPercent",
                      },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.discountPercent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "discountPercent", $$v)
                          },
                          expression: "form.discountPercent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Birthday Discount Percent (%)",
                        prop: "birthdayDiscountPercent",
                      },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.birthdayDiscountPercent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "birthdayDiscountPercent", $$v)
                          },
                          expression: "form.birthdayDiscountPercent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Birthday Discount Product",
                        prop: "birthdayDiscountProduct",
                      },
                    },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: false,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.birthdayDiscountProduct,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "birthdayDiscountProduct", $$v)
                          },
                          expression: "form.birthdayDiscountProduct",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 16, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Description", prop: "description" } },
                    [
                      _c("Tinymce", {
                        ref: "editor",
                        attrs: { width: "100%", height: 300 },
                        on: { onInit: _vm.setDescription },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            [
              _vm.status == "update"
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn-delete",
                      attrs: { loading: _vm.loadingButton },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v("Delete")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "btn-save",
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: {
                click: function ($event) {
                  _vm.status == "edit" ? _vm.updateData() : _vm.createData()
                },
              },
            },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
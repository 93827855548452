"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerRankApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var customerRankApi = exports.customerRankApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/customerRank",
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/customerRank",
      data: data,
      method: "post"
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/customerRank/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/customerRank/".concat(id, "/delete"),
      method: "post"
    });
  }
};
"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.giveAwayApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var giveAwayApi = exports.giveAwayApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/giveaway",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/giveaway/".concat(id)
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/giveaway/".concat(id, "/delete"),
      method: 'post'
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/giveaway",
      data: data,
      method: "post"
    });
  },
  addDetail: function addDetail(id, data) {
    return (0, _request.default)({
      url: "/giveaway/".concat(id, "/add/detail"),
      method: 'post',
      data: data
    });
  },
  deleteDetail: function deleteDetail(id) {
    return (0, _request.default)({
      url: "/giveawayDetail/".concat(id, "/delete"),
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/giveaway/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  updateDetail: function updateDetail(id, data) {
    return (0, _request.default)({
      url: "/giveawayDetail/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  updateDetailQuantity: function updateDetailQuantity(id, data) {
    return (0, _request.default)({
      url: "/giveawayDetail/".concat(id, "/update/quantity"),
      data: data,
      method: "post"
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/giveaway/".concat(id, "/delete"),
      method: "post"
    });
  },
  delivering: function delivering(id) {
    return (0, _request.default)({
      url: "/giveaway/".concat(id, "/delivering"),
      method: "post"
    });
  },
  complete: function complete(id) {
    return (0, _request.default)({
      url: "/giveaway/".concat(id, "/complete"),
      method: "post"
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/giveaway/".concat(id, "/cancel"),
      method: "post"
    });
  },
  updateCreatedAt: function updateCreatedAt(id, data) {
    return (0, _request.default)({
      url: "/giveaway/".concat(id, "/update/createdAt"),
      method: "post",
      data: data
    });
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Print Information" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "package-order-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "top",
            disabled: _vm.disabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Date" } },
            [
              _c("el-date-picker", {
                class:
                  _vm.$getValuePropObj(_vm.compare, "printDate") &&
                  "form-highlight",
                staticStyle: { width: "100%" },
                attrs: { "value-format": "dd/MM/yyyy", format: "dd/MM/yyyy" },
                model: {
                  value: _vm.form.printDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "printDate", $$v)
                  },
                  expression: "form.printDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "No." } },
            [
              _c("el-input", {
                class:
                  _vm.$getValuePropObj(_vm.compare, "printNo") &&
                  "form-highlight",
                model: {
                  value: _vm.form.printNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "printNo", $$v)
                  },
                  expression: "form.printNo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "btn-save",
          attrs: { loading: _vm.loading, type: "primary" },
          on: {
            click: function ($event) {
              return _vm.updateData()
            },
          },
        },
        [_vm._v("Save")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
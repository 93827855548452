var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-modal",
      attrs: {
        title:
          _vm.status == "create"
            ? "New import warehouse"
            : "Update import warehouse",
        visible: _vm.visible,
        fullscreen: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c("product-order-form", {
                    ref: "product",
                    attrs: {
                      form: _vm.form,
                      statusForm: _vm.status,
                      orderStatus: _vm.orderStatus,
                    },
                  }),
                  _vm.status == "update"
                    ? _c("import-print-information-form", {
                        staticStyle: { "margin-top": "24px" },
                        attrs: {
                          form: _vm.form,
                          statusForm: _vm.status,
                          orderStatus: _vm.orderStatus,
                        },
                        on: {
                          "submit:ok": function ($event) {
                            return _vm.$emit("submit:ok")
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.status == "update"
        ? _c("import-warehouse-print", {
            ref: "printMe",
            staticStyle: { height: "0", overflow: "hidden" },
            attrs: { data: _vm.form },
          })
        : _vm._e(),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _vm.status == "update"
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn-delete",
                    attrs: { loading: _vm.loading },
                    on: { click: _vm.handleDelete },
                  },
                  [_vm._v("Delete")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          [
            _vm.status == "update"
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn-save",
                    attrs: { loading: _vm.loading, type: "primary" },
                    on: { click: _vm.handlePrint },
                  },
                  [_vm._v("Print")]
                )
              : _vm._e(),
            _vm.status == "create"
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn-save",
                    attrs: { loading: _vm.loading, type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.status == "create"
                          ? _vm.createData()
                          : _vm.updateData()
                      },
                    },
                  },
                  [_vm._v("Save")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "upload-control" } },
    [
      _c(
        "el-upload",
        {
          staticStyle: { margin: "0 auto" },
          style: {
            height: _vm.height + "px",
            width: _vm.width + "px",
          },
          attrs: {
            name: "file",
            drag: "",
            "before-upload": _vm.beforeUpload,
            action: _vm.$baseUrl + _vm.urlUpload,
            "on-success": _vm.handleSuccess,
            "show-file-list": false,
            headers: { token: _vm.token },
            "on-progress": _vm.onProgress,
            "on-error": _vm.onError,
            disabled: _vm.disabled,
          },
        },
        [
          _vm.isProgressing
            ? _c("el-progress", {
                attrs: { type: "circle", percentage: _vm.percentUpload },
              })
            : _vm._e(),
          !_vm.isProgressing
            ? [
                _vm.pathImage
                  ? _c("img", {
                      staticClass: "image",
                      staticStyle: { "object-fit": "contain" },
                      style:
                        "width:" + _vm.width + "px;height:" + _vm.height + "px",
                      attrs: {
                        src: _vm.$baseUrlMedia + _vm.pathImage,
                        alt: "",
                      },
                    })
                  : [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v(" Drag or "),
                        _c("em", [_vm._v("click here to upload")]),
                      ]),
                    ],
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
                _c("el-input", {
                  attrs: {
                    icon: "el-icon-search",
                    placeholder: "Search order code,...",
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.query.page = 1
                      _vm.fetchData()
                    },
                  },
                  model: {
                    value: _vm.query.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "search", $$v)
                    },
                    expression: "query.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                attrs: {
                  size: "medium",
                  type: "primary",
                  icon: "el-icon-download",
                  loading: _vm.loadingExcel,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleExport()
                  },
                },
              },
              [_vm._v(" Export excel ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                attrs: { size: "medium", icon: "el-icon-set-up" },
                on: {
                  click: function ($event) {
                    return _vm.$refs.filter.handleOpen()
                  },
                },
              },
              [_vm._v("Filters")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, fit: "" },
          on: { "cell-click": _vm.handleCellClick },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Code", prop: "code", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          gap: "8px",
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(row.code))])]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Staff", prop: "staff.name", width: "160" },
          }),
          _c("el-table-column", {
            attrs: { label: "Type", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.tagOptions[row.type]
                            ? _vm.tagOptions[row.type].type
                            : "",
                          effect: _vm.tagOptions[row.type]
                            ? _vm.tagOptions[row.type].effect
                            : "dark",
                          size: "mini",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.type.replace("_", " ")) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "140px", label: "Products" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(
                    row.exportWarehouseDetails,
                    function (exportWarehouseDetail) {
                      return _c(
                        "div",
                        {
                          key: exportWarehouseDetail.id,
                          staticClass: "space-between",
                        },
                        [
                          _c("label", [
                            _vm._v(_vm._s(exportWarehouseDetail.product.name)),
                          ]),
                          _c("span", { staticStyle: { "min-width": "50px" } }, [
                            _vm._v(
                              "x" +
                                _vm._s(
                                  _vm.$formatNumberVN(
                                    exportWarehouseDetail.quantity
                                  )
                                )
                            ),
                          ]),
                        ]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Note", prop: "note" } }),
          _c("el-table-column", {
            attrs: { label: "Date", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.$formatDate(row.date)) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.query.page,
          limit: _vm.query.limit,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("filter-order", { ref: "filter", on: { apply: _vm.handleFilter } }),
      _c("warehouse-modal", {
        ref: "modal",
        on: { "submit:ok": _vm.fetchData },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Update Code",
            visible: _vm.updateModalVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.updateModalVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Code" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Private Code" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.privateCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "privateCode", $$v)
                      },
                      expression: "form.privateCode",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.updateModalVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loadingUpdateCode },
                  on: { click: _vm.handleUpdateCode },
                },
                [_vm._v("Update")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(_vm._s(_vm.$t("search"))),
              ]),
              _c("el-input", {
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    _vm.query.page = 1
                    _vm.fetchData()
                  },
                },
                model: {
                  value: _vm.query.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "search", $$v)
                  },
                  expression: "query.search",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "font-bold font-size--medium",
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v(" Add new ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.data, fit: "" },
          on: {
            "row-click": function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleUpdate($event)
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Code", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("p", [_vm._v(_vm._s(row.code))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Customer", width: "auto", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.customer
                      ? _c("div", { staticClass: "label-content" }, [
                          _c("label", { attrs: { for: "" } }),
                          _c("span", { staticClass: "danger-color" }, [
                            _vm._v("GIFT (HÀNG TẶNG KHÔNG THU TIỀN)"),
                          ]),
                        ])
                      : _c("div", [
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Name: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.customer.name))]),
                          ]),
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Phone: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.customer.phone))]),
                          ]),
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Address: "),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(row.customer.receiverAddress) +
                                  ", " +
                                  _vm._s(row.customer.receiverWardName) +
                                  ", " +
                                  _vm._s(row.customer.receiverDistrictName) +
                                  ", " +
                                  _vm._s(row.customer.receiverCityName)
                              ),
                            ]),
                          ]),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "200px", label: "Products" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.returnOrderDetails, function (orderDetail) {
                    return _c(
                      "div",
                      { key: orderDetail.id, staticClass: "space-between" },
                      [
                        _c("label", [_vm._v(_vm._s(orderDetail.product.name))]),
                        _c("span", { staticStyle: { "min-width": "50px" } }, [
                          _vm._v(
                            "x" +
                              _vm._s(_vm.$formatNumberVN(orderDetail.quantity))
                          ),
                        ]),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          pageSizes: [12, 24, 48, 64],
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("order-modal", {
        ref: "modal",
        attrs: { disabled: false },
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "filter-container filter-drawer" },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.$t("search"))),
                ]),
                _c("el-input", {
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.query.page = 1
                      _vm.fetchData()
                    },
                  },
                  model: {
                    value: _vm.query.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "search", $$v)
                    },
                    expression: "query.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Category")]),
                _c(
                  "el-select",
                  {
                    attrs: { clearable: "", filterable: "" },
                    on: {
                      change: function ($event) {
                        _vm.query.page = 1
                        _vm.fetchData()
                      },
                    },
                    model: {
                      value: _vm.query.productCategoryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "productCategoryId", $$v)
                      },
                      expression: "query.productCategoryId",
                    },
                  },
                  _vm._l(_vm.categories, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { value: item.id, label: item.name },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-button",
            {
              staticClass: "font-bold font-size--medium",
              attrs: { type: "primary", size: "medium", icon: "el-icon-plus" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v("Add new")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.data, fit: "" },
          on: { "row-click": _vm.handleUpdate },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Image", prop: "name", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "product-name" }, [
                      _c("div", { staticClass: "img-box" }, [
                        row.productGalleries.length
                          ? _c("img", {
                              attrs: {
                                src:
                                  _vm.$baseUrlMedia +
                                  row.productGalleries[0].image,
                                width: "80",
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Product name", prop: "name", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Shopee product name",
              prop: "shopeeProduct.name",
              width: "400",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.shopeeProducts.length != 0
                      ? _c(
                          "div",
                          {
                            staticClass: "font-bold",
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                            },
                          },
                          _vm._l(row.shopeeProducts, function (item, index) {
                            return _c("span", { key: index }, [
                              _vm._v("- " + _vm._s(item.name)),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Shopify product name",
              prop: "shopifyProduct.name",
              width: "400",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.shopifyProducts.length != 0
                      ? _c(
                          "div",
                          {
                            staticClass: "font-bold",
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "column",
                            },
                          },
                          _vm._l(row.shopifyProducts, function (item, index) {
                            return _c("span", { key: index }, [
                              _vm._v("- " + _vm._s(item.name)),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Quantity" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$formatNumberVN(row.quantity)) + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "SKU", prop: "sku" } }),
          _c("el-table-column", {
            attrs: {
              label: "Category",
              prop: "productCategory.name",
              width: "200",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Price", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.originPrice)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Retail price", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.retailPrice)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Status Link (Shopee)", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.shopeeProducts.length != 0
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              effect: "dark",
                              size: "small",
                            },
                          },
                          [_vm._v(" Linked Shopee Product ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Status Link (Shopify)", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.shopifyProducts.length != 0
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              effect: "dark",
                              size: "small",
                            },
                          },
                          [_vm._v(" Linked Shopify Product ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          pageSizes: [12, 24, 48, 64],
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("product-modal", {
        ref: "productModal",
        on: { "submit:success": _vm.fetchData },
      }),
      _c("product-rates-modal", { ref: "productRatesModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group"),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("customer-chart")],
              1
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "chart-wrapper" }, [_c("order-chart")], 1),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "chart-wrapper" }, [_c("Top10Staff")], 1),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "chart-wrapper" },
              [_c("Top10Product")],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 32 } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-bottom": "10px",
                    "font-weight": "700",
                    "font-size": "24px",
                  },
                },
                [_vm._v("Reminder")]
              ),
              _c("OrderTable", {
                attrs: {
                  data: _vm.transformOrders,
                  loading: _vm.loading,
                  onClickRow: _vm.handleUpdate,
                  query: _vm.query,
                  total: _vm.total,
                  type: "dashboard",
                },
                on: { fetchData: _vm.fetchOrders, update: _vm.handleRemind },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("order-modal", { ref: "modal", on: { "submit:ok": _vm.fetchOrders } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BarChart = _interopRequireDefault(require("../BarChart"));
var _dashboard = require("@/api/dashboard");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    BarChart: _BarChart.default
  },
  data: function data() {
    return {
      date: [(0, _moment.default)().subtract(1, "months").format("YYYY-MM-DD"), (0, _moment.default)().format("YYYY-MM-DD")],
      dataChart: []
    };
  },
  created: function created() {
    this.getDataChart();
  },
  methods: {
    getDataChart: function getDataChart() {
      var _this = this;
      var listQuery = {
        from: this.date[0],
        to: this.date[1]
      };
      _dashboard.dashboardApi.customerGroup(listQuery).then(function (res) {
        _this.dataChart = res.data.reverse();
        _this.$nextTick(function () {
          _this.$refs.chart.initChart();
        });
      });
    }
  }
};
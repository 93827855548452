"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _CommonSummary = _interopRequireDefault(require("./PanelGroup/CommonSummary.vue"));
var _CustomerSummary = _interopRequireDefault(require("./PanelGroup/CustomerSummary.vue"));
var _OrderSummary = _interopRequireDefault(require("./PanelGroup/OrderSummary.vue"));
var _ProfitSummary = _interopRequireDefault(require("./PanelGroup/ProfitSummary.vue"));
var _SaleSummary = _interopRequireDefault(require("./PanelGroup/SaleSummary.vue"));
var _DetailOrderSummary = _interopRequireDefault(require("./PanelGroup/DetailOrderSummary.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      numCol: 8
    };
  },
  components: {
    CommonSummary: _CommonSummary.default,
    CustomerSummary: _CustomerSummary.default,
    OrderSummary: _OrderSummary.default,
    ProfitSummary: _ProfitSummary.default,
    SaleSummary: _SaleSummary.default,
    DetailOrderSummary: _DetailOrderSummary.default
  },
  methods: {
    onDataNull: function onDataNull() {
      this.numCol = 8;
    }
  }
};
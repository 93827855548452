var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
                _c("el-input", {
                  attrs: {
                    icon: "el-icon-search",
                    placeholder: "Search order code,...",
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.query.page = 1
                      _vm.fetchOrders()
                    },
                  },
                  model: {
                    value: _vm.query.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "search", $$v)
                    },
                    expression: "query.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-dropdown",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { trigger: "click" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "font-size--medium font-bold",
                    attrs: {
                      size: "medium",
                      type: "primary",
                      icon: "el-icon-download",
                      loading: _vm.loadingExcel,
                    },
                  },
                  [_vm._v(" Export excel ")]
                ),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "menu-dropdown-custom",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleExportExcel(
                                _vm.ExportExcelType.Real
                              )
                            },
                          },
                        },
                        [_vm._v(" Export excel real ")]
                      ),
                    ]),
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleExportExcel(
                                _vm.ExportExcelType.RealPublish
                              )
                            },
                          },
                        },
                        [_vm._v(" Export excel real (publish) ")]
                      ),
                    ]),
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleExportExcel(
                                _vm.ExportExcelType.RealNoPublish
                              )
                            },
                          },
                        },
                        [_vm._v(" Export excel real (not publish) ")]
                      ),
                    ]),
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleExportExcel(
                                _vm.ExportExcelType.Accountant
                              )
                            },
                          },
                        },
                        [_vm._v(" Export excel accountant ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                attrs: { size: "medium", icon: "el-icon-set-up" },
                on: {
                  click: function ($event) {
                    return _vm.$refs.filter.handleOpen()
                  },
                },
              },
              [_vm._v("Filters")]
            ),
            _c(
              "el-dropdown",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { trigger: "click" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "font-bold font-size--medium",
                    attrs: { type: "primary", icon: "el-icon-plus" },
                  },
                  [_vm._v(" Add new ")]
                ),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "menu-dropdown-custom",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleCreate(_vm.OrderType.Order)
                            },
                          },
                        },
                        [_vm._v(" Order ")]
                      ),
                    ]),
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleCreate(_vm.OrderType.Gift)
                            },
                          },
                        },
                        [_vm._v(" Gift ")]
                      ),
                    ]),
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleCreate(_vm.OrderType.Consign)
                            },
                          },
                        },
                        [_vm._v(" Consignment ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("order-table", {
        attrs: {
          data: _vm.transformOrders,
          loading: _vm.loading,
          onClickRow: _vm.handleUpdate,
          onClickAction: _vm.handleModifyHistory,
          query: _vm.query,
          total: _vm.total,
        },
        on: { fetchData: _vm.fetchOrders },
      }),
      _c("filter-order", { ref: "filter", on: { apply: _vm.handleFilter } }),
      _c("order-modal", { ref: "modal", on: { "submit:ok": _vm.fetchOrders } }),
      _c("export-modal", { ref: "export" }),
      _c("ModifyHistoryModal", { ref: "ModifyHistoryModal" }),
      _c("SelectDateModal", {
        ref: "SelectDateModal",
        on: { submitOk: _vm.handleSelectDateOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
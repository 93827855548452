"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shopeeOrderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var shopeeOrderApi = exports.shopeeOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/shopeeOrder',
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/shopeeOrder/".concat(id)
    });
  },
  sync: function sync(data) {
    return (0, _request.default)({
      url: '/shopeeOrder/sync',
      data: data,
      method: 'post'
    });
  }
};
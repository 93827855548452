"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TemplateTag = exports.TemplateQuantity = exports.MessageTemplateTypeTrans = exports.MessageTemplateTypeDescriptionTrans = exports.MessageTemplateType = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/defineProperty.js"));
var MessageTemplateType;
(function (MessageTemplateType) {
  MessageTemplateType["InBirthday"] = "IN_BIRTHDAY";
  MessageTemplateType["Birthday"] = "BIRTHDAY";
  MessageTemplateType["RemindOrder"] = "REMIND_ORDER";
})(MessageTemplateType || (exports.MessageTemplateType = MessageTemplateType = {}));
var TemplateQuantity;
(function (TemplateQuantity) {})(TemplateQuantity || (exports.TemplateQuantity = TemplateQuantity = {}));
var TemplateTag;
(function (TemplateTag) {})(TemplateTag || (exports.TemplateTag = TemplateTag = {}));
var MessageTemplateTypeTrans = exports.MessageTemplateTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MessageTemplateType.InBirthday, 'Guests have a birthday this month'), MessageTemplateType.Birthday, 'Guest has a birthday that day'), MessageTemplateType.RemindOrder, 'Purchase reminder');
var MessageTemplateTypeDescriptionTrans = exports.MessageTemplateTypeDescriptionTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, MessageTemplateType.InBirthday, 'Send a message to a customer who has a birthday this month'), MessageTemplateType.Birthday, 'Send a message to a customer who has a birthday that day'), MessageTemplateType.RemindOrder, 'Send purchase reminders');
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.diffDayFromToday = diffDayFromToday;
exports.formatDate = formatDate;
exports.formatDateDay = formatDateDay;
exports.formatDateFromString = formatDateFromString;
exports.formatDateTime = formatDateTime;
exports.percentFromCurrentToExpired = percentFromCurrentToExpired;
require("core-js/modules/es.number.to-fixed.js");
var moment = require("moment");
function formatDate(timestamp) {
  if (timestamp) {
    return moment.unix(timestamp).format("DD/MM/YYYY");
  }
  return "--";
}
function formatDateFromString(dateString) {
  if (dateString) {
    return moment(dateString).format("DD/MM/YYYY");
  }
  return "--";
}
function formatDateTime(timestamp) {
  return moment.unix(timestamp).format("HH:mm:ss, DD/MM/YYYY");
}
function formatDateDay(timestamp) {
  return moment.unix(timestamp).format("dddd, MM/DD/YYYY");
}
/**
 * lay so ngày|tháng giữa ngay hom nay với một ngày bất kì
 */
function diffDayFromToday(date) {
  var unitOfTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'months';
  if (!date) {
    return 0;
  }
  var diff = moment.unix(date).diff(moment(), unitOfTime);
  return diff;
}
/**
 * function tính phần trăm còn lại so với tổng thời lượng hạn sử dụng
 */
function percentFromCurrentToExpired(dateExpired, dateOfManufacture) {
  if (!dateExpired) {
    return 0;
  }
  var current = moment().unix();
  var timeFromManuFactureToExpired = dateExpired - dateOfManufacture;
  var timeFromExpiredToCurrent = dateExpired - current;
  if (dateExpired < current) {
    return 0;
  }
  return (timeFromExpiredToCurrent / timeFromManuFactureToExpired * 100).toFixed(2);
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title:
          _vm.status == "create"
            ? "New pickup address"
            : "Update pickup address",
        visible: _vm.visible,
        width: "600px",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-position": "top",
            id: "product-info-form",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name", label: "Contact name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "phone", label: "Phone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "email", label: "Email" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.type == "GHTK"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "City", prop: "addressCityId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  on: { change: _vm.handleChangeCity },
                                  model: {
                                    value: _vm.form.addressCityId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "addressCityId", $$v)
                                    },
                                    expression: "form.addressCityId",
                                  },
                                },
                                _vm._l(_vm.cities, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.nameWithType,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "District",
                                prop: "addressDistrictId",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  on: { change: _vm.handleChangeDistrict },
                                  model: {
                                    value: _vm.form.addressDistrictId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "addressDistrictId",
                                        $$v
                                      )
                                    },
                                    expression: "form.addressDistrictId",
                                  },
                                },
                                _vm._l(_vm.districts, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.nameWithType,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Ward", prop: "addressWardId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  model: {
                                    value: _vm.form.addressWardId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "addressWardId", $$v)
                                    },
                                    expression: "form.addressWardId",
                                  },
                                },
                                _vm._l(_vm.wards, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.nameWithType,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "City", prop: "cityId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  on: { change: _vm.handleChangeGHNCity },
                                  model: {
                                    value: _vm.form.cityId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "cityId", $$v)
                                    },
                                    expression: "form.cityId",
                                  },
                                },
                                _vm._l(_vm.cities, function (item) {
                                  return _c("el-option", {
                                    key: item.ProvinceID,
                                    attrs: {
                                      label: item.ProvinceName,
                                      value: item.ProvinceID,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "District", prop: "districtId" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  on: { change: _vm.handleChangeGHNDistrict },
                                  model: {
                                    value: _vm.form.districtId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "districtId", $$v)
                                    },
                                    expression: "form.districtId",
                                  },
                                },
                                _vm._l(_vm.districts, function (item) {
                                  return _c("el-option", {
                                    key: item.DistrictID,
                                    attrs: {
                                      label: item.DistrictName,
                                      value: item.DistrictID,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Ward", prop: "wardCode" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { filterable: "" },
                                  model: {
                                    value: _vm.form.wardCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "wardCode", $$v)
                                    },
                                    expression: "form.wardCode",
                                  },
                                },
                                _vm._l(_vm.wards, function (item) {
                                  return _c("el-option", {
                                    key: item.WardCode,
                                    attrs: {
                                      label: item.WardName,
                                      value: item.WardCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "address", label: "Address" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            [
              _vm.status == "update"
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn-delete",
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v(" Delete ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "btn-save",
              attrs: { type: "primary", loading: _vm.loadingSubmit },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-modal",
      attrs: {
        title:
          _vm.status == "create" ? "New consignment" : "Update consignment",
        visible: _vm.visible,
        fullscreen: "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c("product-order-form", {
                    ref: "product",
                    attrs: {
                      form: _vm.form,
                      statusForm: _vm.status,
                      orderStatus: _vm.orderStatus,
                      disabled: _vm.disabledForm,
                      compare: _vm.compareOrder,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "40px" },
                  attrs: { span: _vm.status == "create" ? 24 : 12, offset: 0 },
                },
                [
                  _c("customer-order-form", {
                    ref: "customer",
                    attrs: {
                      form: _vm.form,
                      orderStatus: _vm.orderStatus,
                      checkCustomerMonthBirthday:
                        _vm.checkCustomerMonthBirthday,
                      disabled: _vm.disabledForm,
                      compare: _vm.compareOrder,
                    },
                    on: { checkCustomerRank: _vm.handleCheckRankCustomer },
                  }),
                ],
                1
              ),
              _vm.status == "update"
                ? _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "40px" },
                      attrs: { span: 12, offset: 0 },
                    },
                    [
                      _c("order-info-form", {
                        ref: "info",
                        attrs: {
                          order: _vm.form,
                          disabled: _vm.disabledForm,
                          compare: _vm.compareOrder,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.disabledForm
        ? _c("template", { slot: "footer" }, [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn-delete",
                    attrs: { loading: _vm.loading },
                    on: { click: _vm.handleDelete },
                  },
                  [_vm._v("Delete")]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn-save",
                    attrs: { loading: _vm.loading, type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.status == "create"
                          ? _vm.createData()
                          : _vm.updateData()
                      },
                    },
                  },
                  [_vm._v("Save")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
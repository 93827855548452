"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ShippingChargeBy = exports.ReturnStatus = exports.PackageStatus = exports.OrderType = exports.OrderStatus = exports.GHTKTransportTypeTrans = exports.GHTKTransportType = exports.ExportStatus = exports.DeliveryPartnerTrans = exports.DeliveryPartner = exports.DeliveryFeeTypeTrans = exports.DeliveryFeeType = exports.ConfirmDeliveryStatus = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/defineProperty.js"));
var GHTKTransportType;
(function (GHTKTransportType) {
  GHTKTransportType["Fly"] = "fly";
  GHTKTransportType["Road"] = "road";
})(GHTKTransportType || (exports.GHTKTransportType = GHTKTransportType = {}));
var GHTKTransportTypeTrans = exports.GHTKTransportTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, GHTKTransportType.Fly, "Đường bay"), GHTKTransportType.Road, "Đường bộ");
var OrderStatus;
(function (OrderStatus) {
  OrderStatus["Pending"] = "PENDING";
  OrderStatus["Processing"] = "PROCESSING";
  OrderStatus["Complete"] = "COMPLETE";
  OrderStatus["Cancel"] = "CANCEL"; // Huy
})(OrderStatus || (exports.OrderStatus = OrderStatus = {}));
var ShippingChargeBy;
(function (ShippingChargeBy) {
  ShippingChargeBy["Shiratori"] = "SHIRATORI";
  ShippingChargeBy["Customer"] = "CUSTOMER";
})(ShippingChargeBy || (exports.ShippingChargeBy = ShippingChargeBy = {}));
var DeliveryFeeType;
(function (DeliveryFeeType) {
  DeliveryFeeType["Publish"] = "PUBLISH";
  DeliveryFeeType["NoPublish"] = "NO_PUBLISH";
})(DeliveryFeeType || (exports.DeliveryFeeType = DeliveryFeeType = {}));
var DeliveryFeeTypeTrans = exports.DeliveryFeeTypeTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, DeliveryFeeType.Publish, "Publish"), DeliveryFeeType.NoPublish, "No Publish");
var DeliveryPartner;
(function (DeliveryPartner) {
  DeliveryPartner["GHTK"] = "GHTK";
  DeliveryPartner["Grab"] = "GRAB";
  DeliveryPartner["PostOffice"] = "POST_OFFICE";
})(DeliveryPartner || (exports.DeliveryPartner = DeliveryPartner = {}));
var DeliveryPartnerTrans = exports.DeliveryPartnerTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, DeliveryPartner.GHTK, "GHTK"), DeliveryPartner.Grab, "Grab"), DeliveryPartner.PostOffice, "Post office");
var ConfirmDeliveryStatus;
(function (ConfirmDeliveryStatus) {
  ConfirmDeliveryStatus["Pending"] = "PENDING";
  ConfirmDeliveryStatus["Complete"] = "COMPLETE"; // Da doi soat
})(ConfirmDeliveryStatus || (exports.ConfirmDeliveryStatus = ConfirmDeliveryStatus = {}));
var PackageStatus;
(function (PackageStatus) {
  PackageStatus["Pending"] = "PENDING";
  PackageStatus["Complete"] = "COMPLETE"; // Dong goi toan bo
})(PackageStatus || (exports.PackageStatus = PackageStatus = {}));
var ReturnStatus;
(function (ReturnStatus) {
  ReturnStatus["Pending"] = "PENDING";
  ReturnStatus["Complete"] = "COMPLETE"; // Da tra hang
})(ReturnStatus || (exports.ReturnStatus = ReturnStatus = {}));
var ExportStatus;
(function (ExportStatus) {
  ExportStatus["Pending"] = "PENDING";
  ExportStatus["Complete"] = "COMPLETE"; // Xuat kho toan bo
})(ExportStatus || (exports.ExportStatus = ExportStatus = {}));
var OrderType;
(function (OrderType) {
  OrderType["Order"] = "ORDER";
  OrderType["Gift"] = "GIFT";
  OrderType["Consign"] = "CONSIGN";
})(OrderType || (exports.OrderType = OrderType = {}));
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: {
        model: _vm.form,
        "label-position": "top",
        id: "product-info-form",
        rules: _vm.rules,
      },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "name", label: "Product name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Shopee Product" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.fetchShopeeProducts,
                        multiple: "",
                      },
                      model: {
                        value: _vm.form.shopeeProductIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "shopeeProductIds", $$v)
                        },
                        expression: "form.shopeeProductIds",
                      },
                    },
                    _vm._l(_vm.shopeeProducts, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name + " - " + item.syncId,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Shopify Product" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        remote: "",
                        multiple: "",
                        "remote-method": _vm.fetchShopifyProducts,
                      },
                      model: {
                        value: _vm.form.shopifyProductIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "shopifyProductIds", $$v)
                        },
                        expression: "form.shopifyProductIds",
                      },
                    },
                    _vm._l(_vm.shopifyProducts, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: item.name + " - " + item.syncId,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "sku", label: "SKU" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.sku,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sku", $$v)
                              },
                              expression: "form.sku",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Shape" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.shape,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "shape", $$v)
                                },
                                expression: "form.shape",
                              },
                            },
                            _vm._l(_vm.Shape, function (item, key) {
                              return _c("el-option", {
                                key: key,
                                attrs: { label: key, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Price", prop: "originPrice" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            model: {
                              value: _vm.form.originPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "originPrice", $$v)
                              },
                              expression: "form.originPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Retail price", prop: "retailPrice" },
                        },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            model: {
                              value: _vm.form.retailPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "retailPrice", $$v)
                              },
                              expression: "form.retailPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Category", prop: "productCategoryId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "" },
                      model: {
                        value: _vm.form.productCategoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "productCategoryId", $$v)
                        },
                        expression: "form.productCategoryId",
                      },
                    },
                    _vm._l(_vm.categories, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label: _vm.renderBreadcrumb(item),
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Weight" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.weight,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "weight", $$v)
                              },
                              expression: "form.weight",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Delivery Weight (kg)" } },
                        [
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: true,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                  min: 0.1,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false, min: 0.1}",
                              },
                            ],
                            model: {
                              value: _vm.form.deliveryWeight,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deliveryWeight", $$v)
                              },
                              expression: "form.deliveryWeight",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Size", size: "normal" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.size,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "size", $$v)
                              },
                              expression: "form.size",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Unit" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.unit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "unit", $$v)
                                },
                                expression: "form.unit",
                              },
                            },
                            _vm._l(_vm.Unit, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: {
                                  label: item + " - " + _vm.UnitTrans[item],
                                  value: item,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("description") } },
                [
                  _c("el-input", {
                    attrs: { rows: 4, type: "textarea" },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Published" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: true },
                              model: {
                                value: _vm.form.isPublished,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isPublished", $$v)
                                },
                                expression: "form.isPublished",
                              },
                            },
                            [_vm._v("Yes")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: false },
                              model: {
                                value: _vm.form.isPublished,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isPublished", $$v)
                                },
                                expression: "form.isPublished",
                              },
                            },
                            [_vm._v("No")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Barcode" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.barcode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "barcode", $$v)
                      },
                      expression: "form.barcode",
                    },
                  }),
                  _vm.form.barcode
                    ? _c(
                        "div",
                        { staticClass: "barcode" },
                        [_c("barcode", { attrs: { value: _vm.form.barcode } })],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Image" } },
                [
                  _vm.status == "update"
                    ? _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          staticStyle: { "margin-top": "20px" },
                          attrs: {
                            action: _vm.$baseUrl + "/product/upload",
                            "on-remove": _vm.handleRemove,
                            "on-success": _vm.uploadListSuccess,
                            "file-list": _vm.fileList,
                            headers: { token: _vm.token },
                            multiple: "",
                            "list-type": "picture-card",
                            accept: "image/*",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v(" Click to upload ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v(" jpg/png files 5 MB (Ratio: 1/1) ")]
                          ),
                        ],
                        1
                      )
                    : _c("p", [
                        _vm._v("Image will be update after create new product"),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
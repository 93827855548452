"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.skinStatusStore = exports.customerSourceStore = exports.customerRankStore = exports.customerGroupStore = exports.configurationStore = void 0;
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("./index"));
var _customerGroup = _interopRequireDefault(require("./modules/customerGroup"));
var _customerRank = _interopRequireDefault(require("./modules/customerRank"));
var _skinStatus = _interopRequireDefault(require("./modules/skinStatus"));
var _customerSource = _interopRequireDefault(require("./modules/customerSource"));
var _configuration = _interopRequireDefault(require("./modules/configuration"));
var customerRankStore = exports.customerRankStore = (0, _vuexModuleDecorators.getModule)(_customerRank.default, _index.default);
var customerGroupStore = exports.customerGroupStore = (0, _vuexModuleDecorators.getModule)(_customerGroup.default, _index.default);
var customerSourceStore = exports.customerSourceStore = (0, _vuexModuleDecorators.getModule)(_customerSource.default, _index.default);
var skinStatusStore = exports.skinStatusStore = (0, _vuexModuleDecorators.getModule)(_skinStatus.default, _index.default);
var configurationStore = exports.configurationStore = (0, _vuexModuleDecorators.getModule)(_configuration.default, _index.default);
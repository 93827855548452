var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      !!_vm.totalExpiredProducts
        ? _c("el-alert", {
            staticClass: "danger-color",
            staticStyle: { cursor: "pointer" },
            attrs: {
              title:
                _vm.totalExpiredProducts +
                " Products in stock are about to expire. Please import new products to warehouse.",
              type: "warning",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.handleToListExpired($event)
              },
            },
          })
        : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(
            "keep-alive",
            { attrs: { include: _vm.cachedViews } },
            [_c("router-view", { key: _vm.key })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
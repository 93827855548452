"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReturnOrderType = void 0;
var ReturnOrderType;
(function (ReturnOrderType) {
  ReturnOrderType["Order"] = "ORDER";
  ReturnOrderType["Gift"] = "GIFT";
})(ReturnOrderType || (exports.ReturnOrderType = ReturnOrderType = {}));
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        {
          staticClass: "filter-container filter-drawer",
          staticStyle: { "margin-bottom": "10px" },
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "filter-item" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(_vm._s(_vm.$t("search"))),
                  ]),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      placeholder: "Search name, phone customer...",
                      "prefix-icon": "el-icon-search",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getTableData($event)
                      },
                    },
                    model: {
                      value: _vm.listQuery.search,
                      callback: function ($$v) {
                        _vm.$set(_vm.listQuery, "search", $$v)
                      },
                      expression: "listQuery.search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "font-size--medium font-bold",
                  attrs: {
                    size: "medium",
                    type: "primary",
                    icon: "el-icon-download",
                    loading: _vm.loadingExcel,
                  },
                  on: { click: _vm.exportCustomer },
                },
                [_vm._v(" Export excel ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "font-size--medium font-bold",
                  attrs: { size: "medium", icon: "el-icon-set-up" },
                  on: { click: _vm.handleFilter },
                },
                [_vm._v("Filters")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "font-bold font-size--medium",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "medium",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("Add new")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "font-bold font-size--medium",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    size: "medium",
                  },
                  on: { click: _vm.handleImport },
                },
                [_vm._v("Import Customer")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "filter-container filter-drawer" },
        [
          _c(
            "el-checkbox",
            {
              attrs: { value: _vm.isHadBirthdayThisMonth },
              on: { change: _vm.handleFilterBirthdayThisMonth },
            },
            [_vm._v("Customers have birthdays this month")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "technician-table",
          attrs: { data: _vm.tableData, fit: "" },
          on: { "row-click": _vm.handleUpdate },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Code" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(row.code)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Phone", prop: "phone" } }),
          _c("el-table-column", {
            attrs: { label: "Group", prop: "customerGroup.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Staff", prop: "staff.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Skin status", prop: "skinStatus.name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.skinStatuses
                            .map(function (e) {
                              return e.name
                            })
                            .join(", ")
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Age" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(_vm.calcAge(row.birthday)) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Register member date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$formatDate(row.registerAt))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("createdAt") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [_vm._v(_vm._s(_vm.$formatDate(row.createdAt)))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getTableData,
        },
      }),
      _c("customer-dialog", {
        ref: "dialog",
        on: { "submit:success": _vm.getTableData },
      }),
      _c("filter-customer", { ref: "filter", on: { apply: _vm.handleQuery } }),
      _c("ImportModal", {
        ref: "importModal",
        on: { "import:success": _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var orderApi = exports.orderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/order",
      params: params
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/order/".concat(id)
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/delete"),
      method: 'post'
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/order",
      data: data,
      method: "post"
    });
  },
  createGift: function createGift(data) {
    return (0, _request.default)({
      url: "/order/gift",
      data: data,
      method: "post"
    });
  },
  createConsign: function createConsign(data) {
    return (0, _request.default)({
      url: "/order/consign",
      data: data,
      method: "post"
    });
  },
  addDetail: function addDetail(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/add/detail"),
      method: 'post',
      data: data
    });
  },
  deleteDetail: function deleteDetail(id) {
    return (0, _request.default)({
      url: "/orderDetail/".concat(id, "/delete"),
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  updateProgress: function updateProgress(id, data) {
    return (0, _request.default)({
      url: "/orderProgress/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  updateDetail: function updateDetail(id, data) {
    return (0, _request.default)({
      url: "/orderDetail/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  updateDetailQuantity: function updateDetailQuantity(id, data) {
    return (0, _request.default)({
      url: "/orderDetail/".concat(id, "/update/quantity"),
      data: data,
      method: "post"
    });
  },
  remove: function remove(id) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/delete"),
      method: "post"
    });
  },
  delivering: function delivering(id) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/delivering"),
      method: "post"
    });
  },
  complete: function complete(id) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/complete"),
      method: "post"
    });
  },
  cancel: function cancel(id) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/cancel"),
      method: "post"
    });
  },
  updateStatus: function updateStatus(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/status"),
      method: "post",
      data: data
    });
  },
  findOrdersAndGiveaway: function findOrdersAndGiveaway(params) {
    return (0, _request.default)({
      url: "/order/ordersAndGiveaway",
      params: params
    });
  },
  updateCreatedAt: function updateCreatedAt(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/update/createdAt"),
      method: "post",
      data: data
    });
  },
  createGHTK: function createGHTK(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/GHTK"),
      method: "post",
      data: data
    });
  },
  createGHN: function createGHN(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/GHN"),
      method: "post",
      data: data
    });
  },
  estimateGHTK: function estimateGHTK(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/GHTK/estimate"),
      method: "post",
      data: data
    });
  },
  estimateGHN: function estimateGHN(id, data) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/GHN/estimate"),
      method: "post",
      data: data
    });
  },
  modify: function modify(id) {
    return (0, _request.default)({
      url: "/order/".concat(id, "/modify"),
      method: "post"
    });
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Product" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "order-info-form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Staff", prop: "staffId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "200px" },
                  attrs: {
                    filterable: "",
                    disabled: _vm.form.type != _vm.ExportWarehouseType.Export,
                  },
                  model: {
                    value: _vm.form.staffId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staffId", $$v)
                    },
                    expression: "form.staffId",
                  },
                },
                _vm._l(_vm.staffs, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Date", prop: "date" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  format: "dd/MM/yyyy",
                  "value-format": "timestamp",
                  disabled: _vm.form.type != _vm.ExportWarehouseType.Export,
                },
                model: {
                  value: _vm.form.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "date", $$v)
                  },
                  expression: "form.date",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Note" } },
            [
              _c("el-input", {
                staticStyle: { width: "500px" },
                attrs: {
                  rows: 3,
                  type: "textarea",
                  disabled: _vm.form.type != _vm.ExportWarehouseType.Export,
                },
                model: {
                  value: _vm.form.note,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "note", $$v)
                  },
                  expression: "form.note",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "product-list" }, [
        _c("div", { staticClass: "header-card" }, [
          _c("label", { attrs: { for: "" } }, [_vm._v("Product")]),
          _c("label", {
            staticStyle: { "text-align": "center" },
            attrs: { for: "" },
          }),
          _c("label", { attrs: { for: "" } }, [_vm._v("Export Quantity")]),
        ]),
        _c(
          "div",
          { staticClass: "body-card" },
          [
            [
              _vm.form.products && _vm.form.products.length
                ? _c(
                    "div",
                    _vm._l(_vm.form.products, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "product-item" },
                        [
                          _c(
                            "div",
                            { staticClass: "product-name" },
                            [
                              _c("div", [
                                item.productGalleries &&
                                item.productGalleries.length
                                  ? _c("img", {
                                      attrs: {
                                        src:
                                          _vm.$baseUrlMedia +
                                          item.productGalleries[0].image,
                                        width: "50",
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                              _c("span", [_vm._v(" " + _vm._s(item.name))]),
                              _vm.form.type == _vm.ExportWarehouseType.Export
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "Remove",
                                        placement: "top",
                                        effect: "dark",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-close danger-color font-bold",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleRemoveProd(
                                              index,
                                              item
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "price" }),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c(
                                "div",
                                [
                                  _c("el-button", {
                                    attrs: {
                                      icon: "el-icon-minus",
                                      size: "mini",
                                      disabled:
                                        _vm.form.type !=
                                        _vm.ExportWarehouseType.Export,
                                    },
                                    on: {
                                      click: function ($event) {
                                        item.exportQuantity -= 1
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                attrs: {
                                  disabled:
                                    _vm.form.type !=
                                    _vm.ExportWarehouseType.Export,
                                },
                                model: {
                                  value: item.exportQuantity,
                                  callback: function ($$v) {
                                    _vm.$set(item, "exportQuantity", $$v)
                                  },
                                  expression: "item.exportQuantity",
                                },
                              }),
                              _c(
                                "div",
                                [
                                  _c("el-button", {
                                    attrs: {
                                      icon: "el-icon-plus",
                                      size: "mini",
                                      disabled:
                                        _vm.form.type !=
                                        _vm.ExportWarehouseType.Export,
                                    },
                                    on: {
                                      click: function ($event) {
                                        item.exportQuantity += 1
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.form.type == _vm.ExportWarehouseType.Export
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "16px" },
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-plus",
                      },
                      on: { click: _vm.handleAddProduct },
                    },
                    [_vm._v("Add product")]
                  )
                : _vm._e(),
            ],
            _vm.form.products && !_vm.form.products.length
              ? _c("no-data")
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("add-product-order-modal", {
        ref: "productModal",
        attrs: { productsSelected: _vm.form.products },
        on: { choose: _vm.handleChangeProduct },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
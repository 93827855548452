"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.create = create;
exports.createBatch = createBatch;
exports.customerApi = void 0;
exports.findAll = findAll;
exports.orderDelivery = orderDelivery;
exports.orderFood = orderFood;
exports.orderTransport = orderTransport;
exports.update = update;
exports.updateRank = updateRank;
var _auth = require("@/utils/auth");
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
var customerApi = exports.customerApi = {
  affiliateChildren: function affiliateChildren(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/affiliate/children")
    });
  },
  affiliateParent: function affiliateParent(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/affiliate/parent")
    });
  },
  findAll: findAll,
  findVote: function findVote(id, params) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/votes"),
      params: params
    });
  },
  findWithdraw: function findWithdraw(id, params) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/withdraw"),
      params: params
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id, "/delete"),
      method: 'post'
    });
  },
  findOne: function findOne(id) {
    return (0, _request.default)({
      url: "/customer/".concat(id)
    });
  },
  findOneByEmail: function findOneByEmail(data) {
    return (0, _request.default)({
      url: "/customer/exist/email",
      params: data
    });
  },
  findOneByEmailAndPhone: function findOneByEmailAndPhone(data) {
    return (0, _request.default)({
      url: "/customer/exist",
      params: data
    });
  },
  findOneByPhone: function findOneByPhone(data) {
    return (0, _request.default)({
      url: "/customer/exist/phone",
      params: data
    });
  },
  update: update
};
function findAll(listQuery) {
  return (0, _request.default)({
    url: "/customer",
    method: "get",
    params: listQuery
  });
}
function create(data) {
  return (0, _request.default)({
    url: "/customer",
    method: "post",
    data: data
  });
}
function update(customerId, data) {
  return (0, _request.default)({
    url: "/customer/".concat(customerId, "/update"),
    method: "post",
    data: data
  });
}
function updateRank(customerId, data) {
  return (0, _request.default)({
    url: "/customer/".concat(customerId, "/update/rank"),
    method: "post",
    data: data
  });
}
function orderFood(id, listQuery) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/orderFood"),
    params: listQuery
  });
}
function orderDelivery(id, listQuery) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/orderDelivery"),
    params: listQuery
  });
}
function orderTransport(id, listQuery) {
  return (0, _request.default)({
    url: "/customer/".concat(id, "/orderTransport"),
    params: listQuery
  });
}
function createBatch(data) {
  return _axios.default.post("/customer", data, {
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 30000,
    headers: {
      token: (0, _auth.getToken)()
    }
  });
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel-form" }, [
    _c("div", { staticClass: "header" }, [
      _c("h3", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
      ]),
      _vm.description
        ? _c("p", { staticClass: "description" }, [
            _vm._v(" " + _vm._s(_vm.description) + " "),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "body" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
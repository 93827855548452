var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Update message template",
        visible: _vm.visible,
        width: "600px",
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("h2", { staticClass: "danger-color" }, [
        _vm._v(_vm._s(_vm.MessageTemplateTypeTrans[_vm.form.type])),
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-position": "top",
            id: "product-info-form",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "description", label: "Message content" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 7,
                          placeholder: "Content",
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("el-form-item", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("label", { staticClass: "el-form-item__label" }, [
                          _vm._v("Enable ZNS"),
                        ]),
                        _c("el-switch", {
                          staticStyle: { "margin-left": "auto" },
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                            "active-text": "Enable",
                            "inactive-text": "Disable",
                          },
                          model: {
                            value: _vm.form.isEnableZNS,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isEnableZNS", $$v)
                            },
                            expression: "form.isEnableZNS",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Parameter list" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          attrs: { data: _vm.templateData, fit: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "Parameter name" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [_vm._v(" " + _vm._s(row.token) + " ")]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Description" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [_vm._v(" " + _vm._s(row.name) + " ")]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(row.isRequired ? "Yes" : "No") +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c("div"),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                attrs: { loading: _vm.loading, type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.updateData()
                  },
                },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-modal",
      attrs: {
        title: _vm.modalTitle,
        visible: _vm.visible,
        fullscreen: "",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.type == _vm.OrderType.Consign
                ? _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "40px" },
                      attrs: { span: 24, offset: 0 },
                    },
                    [
                      _c("customer-order-form", {
                        ref: "customer",
                        attrs: {
                          form: _vm.form,
                          orderStatus: _vm.orderStatus,
                          type: _vm.type,
                          checkCustomerMonthBirthday:
                            _vm.checkCustomerMonthBirthday,
                          disabled: _vm.disabled,
                          compare: _vm.compareOrder,
                          statusForm: _vm.status,
                          email: _vm.emailInput,
                        },
                        on: { checkCustomerRank: _vm.handleCheckRankCustomer },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c("product-order-form", {
                    ref: "product",
                    attrs: {
                      form: _vm.form,
                      statusForm: _vm.status,
                      orderStatus: _vm.orderStatus,
                      orderProgress: _vm.orderProgress,
                      type: _vm.type,
                      checkCustomerMonthBirthday:
                        _vm.checkCustomerMonthBirthday,
                      disabled: _vm.disabled,
                      compare: _vm.compareOrder,
                      orderDetails: _vm.orderDetails,
                      isLinkShopee: _vm.isLinkShopee,
                      shopType: _vm.shopType,
                    },
                    on: { "checkpublish:success": _vm.handleCheckPublish },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "40px" },
                  attrs: { span: _vm.status == "create" ? 24 : 12, offset: 0 },
                },
                [
                  _vm.type != _vm.OrderType.Consign
                    ? _c("customer-order-form", {
                        ref: "customer",
                        attrs: {
                          form: _vm.form,
                          orderStatus: _vm.orderStatus,
                          type: _vm.type,
                          checkCustomerMonthBirthday:
                            _vm.checkCustomerMonthBirthday,
                          disabled: _vm.disabled,
                          compare: _vm.compareOrder,
                          statusForm: _vm.status,
                          email: _vm.emailInput,
                        },
                        on: { checkCustomerRank: _vm.handleCheckRankCustomer },
                      })
                    : _vm._e(),
                  _c("package-order-form", {
                    ref: "package",
                    style:
                      _vm.type != _vm.OrderType.Consign && "margin-top:40px",
                    attrs: {
                      orderProgress: _vm.orderProgress,
                      disabled: _vm.disabled,
                      compare: _vm.compareOrder,
                    },
                  }),
                  _c("print-information-form", {
                    staticStyle: { "margin-top": "40px" },
                    attrs: {
                      disabled: _vm.disabled,
                      form: _vm.form,
                      compare: _vm.compareOrder,
                    },
                    on: {
                      "submit:ok": function ($event) {
                        return _vm.$emit("submit:ok")
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-top": "40px" },
                  attrs: { span: _vm.status == "update" ? 12 : 24, offset: 0 },
                },
                [
                  _c("order-info-form", {
                    ref: "info",
                    attrs: {
                      order: _vm.form,
                      orderProgress: _vm.orderProgress,
                      disabled: _vm.disabled,
                      compare: _vm.compareOrder,
                    },
                  }),
                ],
                1
              ),
              _vm.status == "update"
                ? _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "40px" },
                      attrs: { span: 12, offset: 0 },
                    },
                    [
                      _c("payment-order-form", {
                        attrs: {
                          form: _vm.form,
                          orderProgress: _vm.orderProgress,
                          disabled: _vm.disabled,
                          compare: _vm.compareOrder,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("receipt-print", {
        ref: "printReceipt",
        staticStyle: { height: "0", overflow: "hidden" },
        attrs: { data: _vm.form },
      }),
      !_vm.disabled
        ? _c("template", { slot: "footer" }, [
            _c(
              "div",
              [
                _vm.status == "update" && _vm.checkPermissionEdit
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn-delete",
                        attrs: { loading: _vm.loading },
                        on: { click: _vm.handleDelete },
                      },
                      [_vm._v("Delete")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              [
                _vm.status == "update"
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn-save",
                        attrs: {
                          loading: _vm.loading,
                          plain:
                            this.form.codeGHTK != "" || this.form.codeGHN != "",
                          type: "primary",
                        },
                        on: { click: _vm.handleDelivery },
                      },
                      [_vm._v(_vm._s(this.codeDelivery || "Create Delivery"))]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    staticClass: "btn-save",
                    attrs: { loading: _vm.loading, type: "primary" },
                    on: { click: _vm.handlePrint },
                  },
                  [_vm._v("Print")]
                ),
                _vm.checkPermissionEdit
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn-save",
                        attrs: { loading: _vm.loading, type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.status == "create"
                              ? _vm.createData()
                              : _vm.updateData()
                          },
                        },
                      },
                      [_vm._v("Save")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("add-pickup-address-modal", {
        ref: "pickupModal",
        attrs: { orderStatus: _vm.orderStatus },
        on: {
          "submit:success": function ($event) {
            _vm.visible = false
          },
        },
      }),
      _c("ModifyHistoryModal", { ref: "ModifyHistoryModal" }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Link form",
            visible: _vm.addCustomerDialogVisible,
            width: "30%",
            "before-close": function () {
              _vm.addCustomerDialogVisible = false
            },
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addCustomerDialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "Do you want to create a new customer with existing information ?"
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addCustomerDialogVisible = false
                    },
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCreateCustomer },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("customer-dialog", {
        ref: "customerModal",
        on: {
          "submit:success": _vm.handleFetchShopifyOrderAfterCreateCustomer,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
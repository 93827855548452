"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformDepositOrderDetails = transformDepositOrderDetails;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.to-string.js");
// ** Xu ly gom danh sách mảng theo product
function transformDepositOrderDetails(data, status, productSelect) {
  var products = [];
  var listProductWithLotNumbers = {};
  var _loop = function _loop() {
    var element = data[i];
    var find = products.find(function (e) {
      return e.id == element.id;
    });
    if (!find) {
      products.push(element);
      listProductWithLotNumbers[element.id] = [];
    }
    element.soldQuantity = 0;
    if (status == "update" && productSelect.id == element.id && productSelect.lotNumber == element.lotNumber && productSelect.expired == element.expired) {
      element.soldQuantity = productSelect.quantity || 0;
    }
    if (element.available > 0) {
      listProductWithLotNumbers[element.id].push((0, _objectSpread2.default)((0, _objectSpread2.default)({}, element), {}, {
        quantity: element.available + element.soldQuantity
      }));
    }
  };
  for (var i = 0; i < data.length; i++) {
    _loop();
  }
  return {
    products: products,
    listProductWithLotNumbers: listProductWithLotNumbers
  };
}
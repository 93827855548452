var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Pickup Address",
        visible: _vm.visible,
        width: "700px",
        "append-to-body": "",
        top: "10px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card", "tab-position": "top" },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "GHTK", name: "ghtk" } },
            [
              _c("AddAddressGHTK", {
                ref: "ghtk",
                attrs: {
                  orderStatus: _vm.orderStatus,
                  data: _vm.form,
                  type: "GHTK",
                },
                on: {
                  "submit:success": function ($event) {
                    return _vm.$emit("submit:success")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "GHN", name: "ghn" } },
            [
              _c("AddAddressGHN", {
                ref: "ghn",
                attrs: {
                  orderStatus: _vm.orderStatus,
                  data: _vm.form,
                  type: "GHN",
                },
                on: {
                  "submit:success": function ($event) {
                    return _vm.$emit("submit:success")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("div"),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.tabName == "ghn"
                    ? _vm.handleSaveGHN()
                    : _vm.handleSaveGHTK()
                },
              },
            },
            [_vm._v("Order")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.warehouseApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var warehouseApi = exports.warehouseApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/warehouse/report",
      params: params
    });
  },
  findAllExpired: function findAllExpired(params) {
    return (0, _request.default)({
      url: "/warehouse/report/expired",
      params: params
    });
  },
  findLotNumber: function findLotNumber(id) {
    return (0, _request.default)({
      url: "/warehouse/".concat(id, "/lotNumber")
    });
  },
  findGroup: function findGroup(id) {
    return (0, _request.default)({
      url: "/warehouse/".concat(id, "/group")
    });
  },
  findReportConsignByCustomer: function findReportConsignByCustomer(params) {
    return (0, _request.default)({
      url: "/warehouse/report/consign/byCustomer",
      params: params
    });
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Product",
        visible: _vm.visible,
        width: "500px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-position": "top" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Product" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    remote: "",
                    "value-key": "id",
                    "remote-method": _vm.fetchProduct,
                    loading: _vm.loading,
                  },
                  model: {
                    value: _vm.form.product,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "product", $$v)
                    },
                    expression: "form.product",
                  },
                },
                _vm._l(_vm.products, function (item) {
                  return _c(
                    "el-option",
                    { key: item.id, attrs: { label: item.name, value: item } },
                    [
                      _c(
                        "span",
                        {
                          class: {
                            "text-line-through": _vm.checkExist(item),
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("div"),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
            [_vm._v("Add product")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
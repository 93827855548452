var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-box" }, [
    _c("div", { staticClass: "img-box" }, [
      _c("img", {
        staticClass: "thumbnail",
        attrs: { src: _vm.$baseUrlMedia + _vm.product.thumbnail, alt: "" },
      }),
      _vm.visibleControl
        ? _c("div", { staticClass: "mask-control" }, [
            _c(
              "div",
              { staticClass: "control-box" },
              [
                _c("el-button", {
                  staticClass: "circle-button",
                  attrs: { icon: "el-icon-edit", size: "mini" },
                  on: {
                    click: function () {
                      return _vm.onDetail(_vm.product)
                    },
                  },
                }),
                _vm.product.isFeatured
                  ? _c("el-button", {
                      staticClass: "circle-button",
                      attrs: {
                        icon: "el-icon-star-on",
                        size: "mini",
                        loading: _vm.product.loadingStar,
                        type: "warning",
                      },
                      on: {
                        click: function () {
                          return _vm.onStar(_vm.product)
                        },
                      },
                    })
                  : _c("el-button", {
                      staticClass: "circle-button",
                      attrs: {
                        icon: "el-icon-star-off",
                        loading: _vm.product.loadingStar,
                        size: "mini",
                      },
                      on: {
                        click: function () {
                          return _vm.onStar(_vm.product)
                        },
                      },
                    }),
                _c("el-button", {
                  staticClass: "circle-button",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-delete",
                    size: "mini",
                  },
                  on: {
                    click: function () {
                      return _vm.onDelete(_vm.product)
                    },
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "info-box" }, [
      _c("div", { staticClass: "product-name" }, [
        _vm.product.isFeatured
          ? _c("i", {
              staticClass: "el-icon-star-on",
              staticStyle: { color: "#ffc833" },
            })
          : _vm._e(),
        _vm._v(" " + _vm._s(_vm.product.name) + " "),
      ]),
      _c("div", { staticStyle: { "font-size": "12px" } }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(_vm._s(_vm.$t("category")) + ": "),
        ]),
        _c("span", [
          _vm._v(_vm._s(_vm.product.category && _vm.product.category.name)),
        ]),
      ]),
      _c("div", [
        _c("span", { staticClass: "origin-price" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("toThousandFilter")(_vm.product.finalPrice)) +
              "đ "
          ),
        ]),
        _c("span", { staticClass: "final-price" }, [
          _vm._v(
            " " +
              _vm._s(_vm._f("toThousandFilter")(_vm.product.originPrice)) +
              "đ "
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticStyle: { "font-size": "12px" },
          on: {
            click: function ($event) {
              return _vm.$emit("review", _vm.product)
            },
          },
        },
        [
          _c(
            "el-link",
            { staticStyle: { color: "#DC4534" }, attrs: { type: "danger" } },
            [_vm._v(" Review's ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "priority" },
        [
          _c("span", [_vm._v(" " + _vm._s(_vm.$t("position")) + ": ")]),
          _vm.visibleControl
            ? _c("input-money", {
                staticStyle: { width: "80px", height: "30px" },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return (function () {
                      return _vm.onChangePriority(_vm.product)
                    })($event)
                  },
                  blur: function () {
                    return _vm.onChangePriority(_vm.product)
                  },
                },
                model: {
                  value: _vm.product.priority,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "priority", $$v)
                  },
                  expression: "product.priority",
                },
              })
            : _c("span", [_vm._v(_vm._s(_vm.product.priority))]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformDataByProductExport = exports.transformDataByCustomerExport = exports.getMonthRange = void 0;
require("core-js/modules/es.array.find-index.js");
require("core-js/modules/es.array.push.js");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _moment = _interopRequireDefault(require("moment"));
// Xu ly nhom du lieu thành dữ liệu theo khach hang để xuất excel
var transformDataByCustomerExport = exports.transformDataByCustomerExport = function transformDataByCustomerExport(data) {
  var group = [];
  var _loop = function _loop() {
    var element = data[i];
    var month = (0, _moment.default)(element.month, 'YYYY-MM').format('[T]MMYYYY');
    var totalRemainBeforeReturn = element.totalDeposit + element.totalDepositGift - element.totalSaleConsign - element.totalSaleGiveaway;
    var totalReturnConsignment = (element.totalReturnOrder || 0) + (element.totalReturnGift || 0);
    var totalRemain = totalRemainBeforeReturn - totalReturnConsignment;
    var monthReport = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, element), {}, {
      month: month,
      totalRemain: totalRemain,
      totalReturnConsignment: totalReturnConsignment,
      totalRemainBeforeReturn: totalRemainBeforeReturn
    });
    // check tồn tại khách hàng có trong mảng
    var findCustomerIndex = group.findIndex(function (e) {
      return e.customerId == element.customerId;
    });
    if (findCustomerIndex > -1) {
      // check tồn tại sản phẩm có trong mảng
      var findProductIndex = group[findCustomerIndex].products.findIndex(function (e) {
        return e.productId == element.productId;
      });
      if (findProductIndex > -1) {
        // nếu tồn tại sản phẩm thì push vào mảng
        group[findCustomerIndex].products[findProductIndex].months.push(monthReport);
      } else {
        // nếu chưa tồn tại sản phẩm thì thêm mới
        group[findCustomerIndex].products.push({
          productId: element.productId,
          productName: element.productName,
          productSKU: element.productSKU,
          months: [monthReport]
        });
      }
    } else {
      // nếu chưa tồn tại khách hàng trong mảng thì thêm mới
      group.push({
        customerId: element.customerId,
        customerName: element.customerName,
        customerCode: element.customerCode,
        products: [{
          productId: element.productId,
          productName: element.productName,
          productSKU: element.productSKU,
          months: [monthReport]
        }]
      });
    }
  };
  for (var i = 0; i < data.length; i++) {
    _loop();
  }
  return group;
};
// Xu ly nhom du lieu thành dữ liệu theo san pham để xuất excel
var transformDataByProductExport = exports.transformDataByProductExport = function transformDataByProductExport(data) {
  var group = [];
  var _loop2 = function _loop2() {
    var element = data[i];
    var totalRemainBeforeReturn = (element.totalDeposit || 0) + (element.totalDepositGift || 0) - (element.totalSaleConsign || 0) - (element.totalSaleGiveaway || 0);
    var totalReturnConsignment = (element.totalReturnOrder || 0) + (element.totalReturnGift || 0);
    var totalRemain = totalRemainBeforeReturn - totalReturnConsignment;
    var month = (0, _moment.default)(element.month, 'YYYY-MM').format('[T]MMYYYY');
    var monthReport = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, element), {}, {
      month: month,
      totalRemain: totalRemain
    });
    // check tồn tại san pham có trong mảng
    var findProductIndex = group.findIndex(function (e) {
      return e.productId == element.productId;
    });
    if (findProductIndex > -1) {
      // neu ton tai thang cua san pham thi cong so san pham con lai
      var findMonthIndex = group[findProductIndex].months.findIndex(function (e) {
        return e.month == month;
      });
      if (findMonthIndex > -1) {
        group[findProductIndex].months[findMonthIndex].totalRemain += totalRemain;
      } else {
        group[findProductIndex].months.push(monthReport);
      }
    } else {
      // nếu chưa tồn tại san pha,trong mảng thì thêm mới
      group.push({
        productId: element.productId,
        productName: element.productName,
        productSKU: element.productSKU,
        months: [monthReport]
      });
    }
  };
  for (var i = 0; i < data.length; i++) {
    _loop2();
  }
  return group;
};
// Get danh sach thang trong khoang thoi gian
var getMonthRange = exports.getMonthRange = function getMonthRange(fromDate, toDate) {
  // Define start and end dates
  var startDate = (0, _moment.default)(fromDate, 'DD.MM.YYYY');
  var endDate = (0, _moment.default)(toDate, 'DD.MM.YYYY');
  // Array to store the list of months
  var monthsInRange = [];
  // Loop through each month in the range
  var currentDate = startDate.clone();
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'month')) {
    monthsInRange.push(currentDate.format('[T]MM.YYYY'));
    currentDate.add(1, 'month');
  }
  return monthsInRange;
};
"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var user = _interopRequireWildcard(require("@/api/user"));
var _date = require("@/utils/date");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _UserDialog = _interopRequireDefault(require("./components/UserDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
/* @flow */
var _default = exports.default = {
  components: {
    Pagination: _Pagination.default,
    UserDialog: _UserDialog.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: []
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    handleReset: function handleReset(row) {
      var _this = this;
      this.$prompt("Nhập mật khẩu mới để khôi phục", "Khôi phục mật khẩu", {
        confirmButtonText: "OK",
        cancelButtonText: "Hủy"
      }).then(function (_ref) {
        var value = _ref.value;
        var data = {
          newPassword: value
        };
        user.reset(row.id, data).then(function (res) {
          _this.$message({
            type: "success",
            message: "Khôi phục mật khẩu thành công"
          });
        });
      });
    },
    handleEdit: function handleEdit(row) {
      this.$refs["user-dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["user-dialog"].handleAdd();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;
      this.$confirm("Confirm delete?", "Warning", {
        confirmButtonText: "Delete",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        _this2.listLoading = true;
        user.remove(row.id).then(function () {
          return _this2.getTableData();
        }).finally(function () {
          return _this2.listLoading = false;
        });
      });
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this3 = this;
      this.$confirm("Confirm unblock?", "Warning", {
        confirmButtonText: "Block",
        cancelButtonText: "Close",
        type: "warning"
      }).then(function () {
        var data = {
          staff: {
            isBlock: false
          },
          roleId: row.role && row.role.id
        };
        user.update(row.id, data).then(function () {
          row.isBlock = false;
          _this3.$message({
            type: "success",
            message: "Successfully"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this4 = this;
      this.$confirm("Tài khoản này sẽ bị khóa, bạn có muốn tiếp tục?", "Warning", {
        confirmButtonText: "Block",
        cancelButtonText: "Close",
        type: "warning"
      }).then(function () {
        var data = {
          staff: {
            isBlock: true
          },
          roleId: row.role && row.role.id
        };
        user.update(row.id, data).then(function () {
          row.isBlock = true;
          _this4.$message({
            type: "success",
            message: "Successfully"
          });
        });
      });
    },
    getTableData: function getTableData(a) {
      var _this5 = this;
      this.listLoading = true;
      user.getList(this.listQuery).then(function (res) {
        _this5.tableData = res.data.data;
        _this5.total = res.data.total;
      }).finally(function () {
        _this5.listLoading = false;
      });
    },
    formatDate: function formatDate(timestamp) {
      return (0, _date.formatDate)(timestamp);
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, fit: "" },
          on: {
            "row-click": function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleUpdate($event)
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Message" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", [
                      _vm._v(_vm._s(_vm.MessageTemplateTypeTrans[row.type])),
                    ]),
                    _c("span", { staticStyle: { display: "block" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.MessageTemplateTypeDescriptionTrans[row.type]
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Template", width: "auto", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.description) + " ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("message-template-modal", {
        ref: "modal",
        attrs: { disabled: false },
        on: { "submit:ok": _vm.fetchList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
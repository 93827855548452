"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.depositOrderApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var depositOrderApi = exports.depositOrderApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: '/depositOrder',
      params: params
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: '/depositOrder',
      data: data,
      method: 'post'
    });
  },
  estimate: function estimate(data) {
    return (0, _request.default)({
      url: '/depositOrder/estimate',
      data: data,
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/depositOrder/".concat(id),
      method: 'patch',
      data: data
    });
  },
  delete: function _delete(id, data) {
    return (0, _request.default)({
      url: "/depositOrder/".concat(id),
      method: 'delete',
      data: data
    });
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
                _c("el-input", {
                  attrs: {
                    icon: "el-icon-search",
                    placeholder: "Search order code,...",
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.query.page = 1
                      _vm.fetchOrders()
                    },
                  },
                  model: {
                    value: _vm.query.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "search", $$v)
                    },
                    expression: "query.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-dropdown",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { trigger: "click" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "font-size--medium font-bold",
                    attrs: {
                      size: "medium",
                      type: "primary",
                      icon: "el-icon-download",
                      loading: _vm.loadingExcel,
                    },
                  },
                  [_vm._v(" Export excel ")]
                ),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "menu-dropdown-custom",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleExportExcel("real")
                            },
                          },
                        },
                        [_vm._v(" Export excel real ")]
                      ),
                    ]),
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleExportExcel("accountant")
                            },
                          },
                        },
                        [_vm._v(" Export excel accountant ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                attrs: { size: "medium", icon: "el-icon-set-up" },
                on: {
                  click: function ($event) {
                    return _vm.$refs.filter.handleOpen()
                  },
                },
              },
              [_vm._v("Filters")]
            ),
            _c(
              "el-button",
              {
                staticClass: "font-bold font-size--medium",
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v(" Add new ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.transformGiveAways, fit: "" },
          on: { "row-click": _vm.handleUpdate },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Status", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.tagOptions[row.status].type,
                          effect: _vm.tagOptions[row.status].effect,
                          size: "small",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.status) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "200px", label: "Products" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.giveawayDetails, function (giveawayDetail) {
                    return _c(
                      "div",
                      { key: giveawayDetail.id, staticClass: "space-between" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(giveawayDetail.product.name)),
                        ]),
                        _c("span", { staticStyle: { "min-width": "50px" } }, [
                          _vm._v(
                            "x" +
                              _vm._s(
                                _vm.$formatNumberVN(giveawayDetail.quantity)
                              )
                          ),
                        ]),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Customer", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Name: ")]),
                      _c("span", [_vm._v(_vm._s(row.customer.name))]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Phone: ")]),
                      _c("span", [_vm._v(_vm._s(row.customer.phone))]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Group: ")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            row.customer.customerGroup &&
                              row.customer.customerGroup.name
                          )
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Receive info", width: "auto", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Name: ")]),
                      _c("span", [_vm._v(_vm._s(row.receiverName))]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Phone: ")]),
                      _c("span", [_vm._v(_vm._s(row.receiverPhone))]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Address: "),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(row.receiverAddress) +
                            ", " +
                            _vm._s(row.receiverWardName) +
                            ", " +
                            _vm._s(row.receiverDistrictName) +
                            ", " +
                            _vm._s(row.receiverCityName)
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Note", prop: "note", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "Date", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$formatDate(row.createdAt)) + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.query.page,
          limit: _vm.query.limit,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchOrders,
        },
      }),
      _c("giveaway-excel-table", { attrs: { data: _vm.giveawaysExport } }),
      _c("filter-order", { ref: "filter", on: { apply: _vm.handleFilter } }),
      _c("give-away-modal", {
        ref: "modal",
        attrs: { type: _vm.type },
        on: { "submit:ok": _vm.fetchOrders },
      }),
      _c("SelectDateModal", {
        ref: "SelectDateModal",
        on: { submitOk: _vm.handleSelectDateOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
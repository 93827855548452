var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Package" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "package-order-form",
          attrs: {
            model: _vm.orderProgress,
            rules: _vm.rules,
            "label-position": "top",
            disabled: _vm.disabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Delivery Code" } },
            [
              _c("el-input", {
                class:
                  _vm.$getValuePropObj(
                    _vm.compare,
                    "orderProgress.deliveryCode"
                  ) && "form-highlight",
                model: {
                  value: _vm.orderProgress.deliveryCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderProgress, "deliveryCode", $$v)
                  },
                  expression: "orderProgress.deliveryCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Package Date" } },
            [
              _c("el-date-picker", {
                class:
                  _vm.$getValuePropObj(
                    _vm.compare,
                    "orderProgress.packageDate"
                  ) && "form-highlight",
                staticStyle: { width: "100%" },
                attrs: { "value-format": "dd/MM/yyyy", format: "dd/MM/yyyy" },
                model: {
                  value: _vm.orderProgress.packageDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderProgress, "packageDate", $$v)
                  },
                  expression: "orderProgress.packageDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Package Staff" } },
            [
              _c("el-input", {
                class:
                  _vm.$getValuePropObj(
                    _vm.compare,
                    "orderProgress.packageStaff"
                  ) && "form-highlight",
                model: {
                  value: _vm.orderProgress.packageStaff,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderProgress, "packageStaff", $$v)
                  },
                  expression: "orderProgress.packageStaff",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Export Date" } },
            [
              _c("el-input", {
                class:
                  _vm.$getValuePropObj(
                    _vm.compare,
                    "orderProgress.exportDate"
                  ) && "form-highlight",
                model: {
                  value: _vm.orderProgress.exportDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderProgress, "exportDate", $$v)
                  },
                  expression: "orderProgress.exportDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Export Staff" } },
            [
              _c("el-input", {
                class:
                  _vm.$getValuePropObj(
                    _vm.compare,
                    "orderProgress.exportStaff"
                  ) && "form-highlight",
                model: {
                  value: _vm.orderProgress.exportStaff,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderProgress, "exportStaff", $$v)
                  },
                  expression: "orderProgress.exportStaff",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Delivery Partner" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(
                      _vm.compare,
                      "orderProgress.deliveryPartner"
                    ) && "form-highlight",
                  model: {
                    value: _vm.orderProgress.deliveryPartner,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderProgress, "deliveryPartner", $$v)
                    },
                    expression: "orderProgress.deliveryPartner",
                  },
                },
                _vm._l(_vm.DeliveryPartner, function (item, key) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: key, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Reason Cancel Delivery" } },
            [
              _c("el-input", {
                class:
                  _vm.$getValuePropObj(
                    _vm.compare,
                    "orderProgress.reasonCancelDelivery"
                  ) && "form-highlight",
                model: {
                  value: _vm.orderProgress.reasonCancelDelivery,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderProgress, "reasonCancelDelivery", $$v)
                  },
                  expression: "orderProgress.reasonCancelDelivery",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Confirm Delivery Status" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(
                      _vm.compare,
                      "orderProgress.confirmDeliveryStatus"
                    ) && "form-highlight",
                  model: {
                    value: _vm.orderProgress.confirmDeliveryStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderProgress, "confirmDeliveryStatus", $$v)
                    },
                    expression: "orderProgress.confirmDeliveryStatus",
                  },
                },
                _vm._l(_vm.ConfirmDeliveryStatus, function (item, key) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: key, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Delivery Note" } },
            [
              _c("el-input", {
                class:
                  _vm.$getValuePropObj(
                    _vm.compare,
                    "orderProgress.deliveryNote"
                  ) && "form-highlight",
                model: {
                  value: _vm.orderProgress.deliveryNote,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderProgress, "deliveryNote", $$v)
                  },
                  expression: "orderProgress.deliveryNote",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Return Delivery Fee" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: {
                      alias: "decimal",
                      groupSeparator: ".",
                      rightAlign: false,
                      autoGroup: true,
                      repeat: 20,
                      autoUnmask: true,
                      allowMinus: false,
                    },
                    expression:
                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                  },
                ],
                class:
                  _vm.$getValuePropObj(
                    _vm.compare,
                    "orderProgress.returnDeliveryFee"
                  ) && "form-highlight",
                model: {
                  value: _vm.orderProgress.returnDeliveryFee,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderProgress, "returnDeliveryFee", $$v)
                  },
                  expression: "orderProgress.returnDeliveryFee",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Delivery Fee Type" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(
                      _vm.compare,
                      "orderProgress.deliveryFeeType"
                    ) && "form-highlight",
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.orderProgress.deliveryFeeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderProgress, "deliveryFeeType", $$v)
                    },
                    expression: "orderProgress.deliveryFeeType",
                  },
                },
                _vm._l(_vm.DeliveryFeeTypeTrans, function (item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item, value: key },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sign-up-form" },
    [
      _c(
        "div",
        { staticClass: "login-container" },
        [
          !_vm.visibleForgot
            ? [
                _c("span", { staticClass: "login-for-booker" }, [
                  _vm._v("Sign up to book"),
                ]),
                _c(
                  "el-form",
                  {
                    ref: "form",
                    staticStyle: { "margin-top": "24px" },
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-position": "top",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, offset: 0 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "First name",
                                  prop: "firstName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "medium" },
                                  model: {
                                    value: _vm.form.firstName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "firstName", $$v)
                                    },
                                    expression: "form.firstName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, offset: 0 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "Last name", prop: "lastName" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "medium" },
                                  model: {
                                    value: _vm.form.lastName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "lastName", $$v)
                                    },
                                    expression: "form.lastName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                          _c(
                            "div",
                            { staticClass: "el-form-item el-form-item--small" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "el-form-item__label",
                                  attrs: { for: "" },
                                },
                                [_vm._v("Mobile number")]
                              ),
                              _c("vue-phone-number-input", {
                                attrs: { borderRadius: 2 },
                                on: { update: _vm.handlePhoneNumber },
                                model: {
                                  value: _vm.form.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "phone", $$v)
                                  },
                                  expression: "form.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-col",
                          { attrs: { span: 12, offset: 0 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Email address",
                                  prop: "email",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { size: "medium" },
                                  model: {
                                    value: _vm.form.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "email", $$v)
                                    },
                                    expression: "form.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24, offset: 0 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "8px" },
                                attrs: { label: "Password", prop: "password" },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: _vm.showPass ? "text" : "password",
                                    size: "medium",
                                  },
                                  model: {
                                    value: _vm.form.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "password", $$v)
                                    },
                                    expression: "form.password",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "show-pwd" },
                                  [
                                    !_vm.showPass
                                      ? _c("font-awesome-icon", {
                                          attrs: { icon: "eye" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showPass = true
                                            },
                                          },
                                        })
                                      : _c("font-awesome-icon", {
                                          attrs: { icon: "eye-slash" },
                                          on: {
                                            click: function ($event) {
                                              _vm.showPass = false
                                            },
                                          },
                                        }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("el-checkbox", { staticClass: "check-box" }, [
                      _vm._v(" I agree to the "),
                      _c("a", [_vm._v("privacy policy")]),
                      _vm._v(" , "),
                      _c("a", [_vm._v("website terms")]),
                      _vm._v(" and "),
                      _c("a", [_vm._v("booking terms")]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn-login",
                    attrs: { disabled: "", type: "primary" },
                    on: { click: _vm.handleSubmit },
                  },
                  [_vm._v(" Sign up ")]
                ),
                _c("div", { staticClass: "content text-center" }, [
                  _c("span", [_vm._v("Already have a booker account?")]),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "chang-status-page",
                            _vm.StatusPage.LoginEmail
                          )
                        },
                      },
                    },
                    [_vm._v("Sign in now")]
                  ),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("login-professional"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-panel" }, [
    _c("div", { staticClass: "left-wrapper" }, [
      _c(
        "div",
        { staticClass: "card-panel-icon-wrapper icon-money" },
        [
          _c("font-awesome-icon", {
            attrs: { icon: "money-bill", size: "3x" },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "card-panel-description" }, [
      _c("div", { staticClass: "card-panel-text" }, [_vm._v("Sale")]),
      _c(
        "div",
        { staticClass: "card-detail" },
        [
          _c("span", [_vm._v("Today:")]),
          _c("count-to", {
            staticClass: "card-panel-num",
            attrs: {
              "start-val": 0,
              "end-val": _vm.data.today.sumReal,
              duration: 3200,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-detail" },
        [
          _c("span", [_vm._v("This week:")]),
          _c("count-to", {
            staticClass: "card-panel-num",
            attrs: {
              "start-val": 0,
              "end-val": _vm.data.thisWeek.sumReal,
              duration: 3200,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "card-detail" },
        [
          _c("span", [_vm._v("This month:")]),
          _c("count-to", {
            staticClass: "card-panel-num",
            attrs: {
              "start-val": 0,
              "end-val": _vm.data.thisMonth.sumReal,
              duration: 3200,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tagOptions = exports.VehicleType = exports.TransactionType = exports.TYPE_CONTENT_DEFINE = exports.STORE_TYPE = exports.PARAMS = exports.OrderStatus = exports.ORDER_TYPE = exports.ORDER_TRANSPORT_STATUS = exports.ORDER_FOOD_STATUS = exports.ORDER_DELIVERY_STATUS = exports.NewsStoreType = exports.ExportWarehouseType = exports.CustomerCategory = exports.CoverType = exports.ConfigurationParamTrans = exports.ConfigurationParam = exports.CategoryFood = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/defineProperty.js"));
var STORE_TYPE = exports.STORE_TYPE = {
  FOOD: "Thức ăn",
  DRINK: "Thức uống"
};
var ORDER_FOOD_STATUS = exports.ORDER_FOOD_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  CUSTOMER_CANCEL: "Khách huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế"
};
var ORDER_DELIVERY_STATUS = exports.ORDER_DELIVERY_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  CUSTOMER_CANCEL: "Khách huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế"
};
var ORDER_TRANSPORT_STATUS = exports.ORDER_TRANSPORT_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế",
  CUSTOMER_CANCEL: "Khách huỷ"
};
var TYPE_CONTENT_DEFINE = exports.TYPE_CONTENT_DEFINE = {
  ABOUT: "Thông tin chúng tôi",
  FAQ: "Câu hỏi thường gặp",
  HOW_TO_USE: "Cách sử dụng",
  SECURITY: "Bảo mật",
  TERM_CONDITION: "Điều khoản",
  HOW_TO_DEPOSIT: "Hướng dẫn nạp điểm",
  HOW_TO_USE_DRIVER: "Cách sử dụng (Tài xế)",
  FAQ_DRIVER: "Câu hỏi thường gặp (Tài xế)",
  BADGE_POINT: "Cách tính điểm"
};
var ORDER_TYPE = exports.ORDER_TYPE = {
  FOOD: "Thức ăn",
  TRANSPORT: "Di chuyển xe máy",
  DELIVERY: "Giao hàng",
  TRANSPORT_CAR_7: "Di chuyển ô tô 7 chỗ",
  TRANSPORT_CAR_4: "Di chuyển ô tô 4 chỗ"
};
var PARAMS = exports.PARAMS = {
  COMMISSION_FOOD: "Thức ăn",
  COMMISSION_DELIVERY: "Giao hàng",
  COMMISSION_TRANSPORT: "Di chuyển ",
  MIN_DRIVER_BALANCE: "Tài khoản tối thiểu",
  DISTANCE_FIND_DRIVER: "Khoảng cách tìm tài xế",
  RATE_POINT: "Tỷ lệ quy đổi",
  REWARD_RATE_STORE: "Thưởng đánh giá cửa hàng",
  REWARD_RATE_ORDER: "Thưởng đánh giá đơn hàng"
};
var tagOptions = exports.tagOptions = {
  PENDING: {
    type: "warning",
    effect: "dark"
  },
  PROCESSING: {
    type: "",
    effect: "dark"
  },
  COMPLETE: {
    type: "success",
    effect: "dark"
  },
  CANCEL: {
    type: "danger",
    effect: "dark"
  },
  Cancel: {
    type: "danger",
    effect: "dark"
  },
  RETAIL: {
    type: "success",
    effect: "dark"
  },
  WHOLESALE: {
    type: "",
    effect: "dark"
  },
  SPA: {
    type: "warning",
    effect: "dark"
  },
  CANCELLED: {
    type: "danger",
    effect: "dark"
  }
};
var VehicleType = exports.VehicleType = {
  BIKE: "Môtô 2 bánh",
  CAR_4: "Ôtô 4 bánh",
  CAR_7: "Ôtô 7 bánh"
};
var NewsStoreType = exports.NewsStoreType = {
  Plus: "PLUS",
  Coffee: "COFFEE"
};
var TransactionType = exports.TransactionType = {
  Deposit: "DEPOSIT",
  Withdraw: "WITHDRAW",
  Income: "INCOME"
};
var CategoryFood = exports.CategoryFood = {
  VEGETARIAN: "Món chay",
  SNACK: "Ăn vặt",
  RICE: "Cơm",
  MILK_TEA: "Trà sữa"
};
var CustomerCategory = exports.CustomerCategory = {
  ALL: "Tất cả",
  BIRTHDAY_IN_MONTH: "Sinh nhật trong tháng",
  OFFLINE: "Không hoạt động",
  FEMALE: "Nữ",
  BADGE: "Cấp bậc"
};
var CoverType = exports.CoverType = {
  HARD: "Bìa cứng",
  SOFT: "Bìa mềm"
};
var OrderStatus = exports.OrderStatus = {
  PENDING: "Đang xác nhận",
  COMPLETE: "Đã giao",
  CANCEL: "Huỷ",
  DELIVERING: "Đang vận chuyển"
};
var ExportWarehouseType;
(function (ExportWarehouseType) {
  ExportWarehouseType["Export"] = "EXPORT";
  ExportWarehouseType["Order"] = "ORDER";
  ExportWarehouseType["DepositOrder"] = "DEPOSIT_ORDER";
  ExportWarehouseType["DepositGift"] = "DEPOSIT_GIFT";
  ExportWarehouseType["ConsignOrder"] = "CONSIGN_ORDER";
  ExportWarehouseType["ConsignGiveaway"] = "CONSIGN_GIVEAWAY";
  ExportWarehouseType["Giveaway"] = "GIVEAWAY";
})(ExportWarehouseType || (exports.ExportWarehouseType = ExportWarehouseType = {}));
var ConfigurationParam;
(function (ConfigurationParam) {
  ConfigurationParam["VatFactor"] = "VAT_FACTOR";
  ConfigurationParam["AccountantName"] = "ACCOUNTANT_NAME";
  ConfigurationParam["Treasurer"] = "TREASURER";
  ConfigurationParam["BillCreator"] = "BILL_CREATOR";
  ConfigurationParam["BillReceiver"] = "BILL_RECEIVER";
  ConfigurationParam["Stocker"] = "STOCKER"; //Thủ kho
})(ConfigurationParam || (exports.ConfigurationParam = ConfigurationParam = {}));
var ConfigurationParamTrans = exports.ConfigurationParamTrans = (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({}, ConfigurationParam.VatFactor, 'VAT (%)'), ConfigurationParam.AccountantName, 'Chief accountant'), ConfigurationParam.Treasurer, 'Treasurer'), ConfigurationParam.BillCreator, 'Bill creator'), ConfigurationParam.BillReceiver, 'Bill receiver'), ConfigurationParam.Stocker, 'Stocker');
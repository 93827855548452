var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
                _c("el-input", {
                  attrs: {
                    icon: "el-icon-search",
                    placeholder: "Search order code,...",
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.query.page = 1
                      _vm.fetchData()
                    },
                  },
                  model: {
                    value: _vm.query.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "search", $$v)
                    },
                    expression: "query.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                attrs: {
                  size: "medium",
                  type: "primary",
                  icon: "el-icon-download",
                  loading: _vm.loadingExcel,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleExport()
                  },
                },
              },
              [_vm._v(" Export excel ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                attrs: { size: "medium", icon: "el-icon-set-up" },
                on: {
                  click: function ($event) {
                    return _vm.$refs.filter.handleOpen()
                  },
                },
              },
              [_vm._v("Filters")]
            ),
            _c(
              "el-button",
              {
                staticClass: "font-bold font-size--medium",
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v(" Add new ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, fit: "" },
          on: { "row-click": _vm.handleUpdate },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Code", width: "100", prop: "code" },
          }),
          _c("el-table-column", {
            attrs: { label: "Staff", prop: "staff.name", width: "160" },
          }),
          _c("el-table-column", {
            attrs: { "min-width": "200px", label: "Products" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(
                    row.importWarehouseDetails,
                    function (importWarehouseDetail) {
                      return _c(
                        "div",
                        {
                          key: importWarehouseDetail.id,
                          staticClass: "space-between",
                        },
                        [
                          _c("label", [
                            _vm._v(_vm._s(importWarehouseDetail.product.name)),
                          ]),
                          _c("span", { staticStyle: { "min-width": "50px" } }, [
                            _vm._v(
                              "x" +
                                _vm._s(
                                  _vm.$formatNumberVN(
                                    importWarehouseDetail.quantity
                                  )
                                )
                            ),
                          ]),
                        ]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.$formatDate(row.date)) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Note", prop: "note" } }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.query.page,
          limit: _vm.query.limit,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("filter-order", { ref: "filter", on: { apply: _vm.handleFilter } }),
      _c("warehouse-modal", {
        ref: "modal",
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "expired-table" },
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
                _c("el-input", {
                  attrs: {
                    icon: "el-icon-search",
                    placeholder: "Search order code,...",
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.query.page = 1
                      _vm.fetchData()
                    },
                  },
                  model: {
                    value: _vm.query.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "search", $$v)
                    },
                    expression: "query.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Expires in")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { size: "medium" },
                    on: {
                      change: function ($event) {
                        _vm.query.page = 1
                        _vm.fetchData()
                      },
                    },
                    model: {
                      value: _vm.query.year,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "year", $$v)
                      },
                      expression: "query.year",
                    },
                  },
                  _vm._l(Array.from(Array(10).keys()), function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { value: item + 1, label: item + 1 + " year(s)" },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Expires percent"),
                ]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { size: "medium" },
                    on: {
                      change: function ($event) {
                        _vm.query.page = 1
                        _vm.fetchData()
                      },
                    },
                    model: {
                      value: _vm.query.expiredPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "expiredPercent", $$v)
                      },
                      expression: "query.expiredPercent",
                    },
                  },
                  _vm._l(Array.from(Array(4).keys()), function (item) {
                    return _c("el-option", {
                      key: item,
                      attrs: { value: item * 25, label: item * 25 + "%" },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                attrs: {
                  type: "primary",
                  icon: "el-icon-refresh",
                  loading: _vm.loadingExcel,
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v(" Refresh ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  size: "medium",
                  type: "primary",
                  icon: "el-icon-download",
                  loading: _vm.loadingExcel,
                },
                on: { click: _vm.fetchDataExport },
              },
              [_vm._v(" Export excel ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.transformData,
            fit: "",
            border: "",
            "span-method": _vm.objectSpanMethod,
            "row-class-name": _vm.tableRowClassName,
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Product" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.productName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "SKU" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.productSKU)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "150", label: "Lot" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.lotNumber || "--")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "90", label: "Manufacture date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$formatDate(row.dateOfManufacture))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Expired date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$formatDate(row.expired))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Expired" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.getExpired(row.expired)) + " months"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Expired Percent", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.getPercentExpired(row)) + "%"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Total import", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.totalIn)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Total export", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.totalOut)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colsOrderExcelTable = void 0;
var colsOrderExcelTable = exports.colsOrderExcelTable = [{
  "label": "Order Code",
  "rowspan": true,
  "prop": "code"
}, {
  "label": "Date Created",
  "rowspan": true,
  "prop": "createdAt",
  "format": true
}, {
  "label": "Customer Name",
  "rowspan": true,
  "prop": "customer.name"
}, {
  "label": "Product Name",
  "rowspan": false,
  "prop": "product.name"
}, {
  "label": "Lot number",
  "rowspan": false,
  "prop": "lotNumber"
}, {
  "label": "Published",
  "rowspan": false,
  "prop": "isPublished"
}, {
  "label": "Product Retail Price",
  "rowspan": false,
  "prop": "productRetailPrice"
}, {
  "label": "Product Quantity",
  "rowspan": false,
  "prop": "productQuantity"
}, {
  "label": "Product Discount Percent",
  "rowspan": false,
  "prop": "productDiscountPercent"
}, {
  "label": "Product Discount Amount",
  "rowspan": false,
  "prop": "productDiscountAmount"
}, {
  "label": "Product Real Price",
  "rowspan": false,
  "prop": "productRealPrice"
}, {
  "label": "Product Total Price",
  "rowspan": false,
  "prop": "productTotalPrice"
}, {
  "label": "Product Vat Price",
  "rowspan": false,
  "prop": "productVatPrice"
}, {
  "label": "Order Note",
  "rowspan": false,
  "prop": "orderProgress.orderNote"
}, {
  "label": "Product Before Vat Price",
  "rowspan": false,
  "prop": "productBeforeVatPrice"
}, {
  "label": "Product Origin Price",
  "rowspan": false,
  "prop": "productOriginPrice"
}, {
  "label": "Delivery Fee",
  "rowspan": false,
  "prop": "orderProgress.deliveryFee"
}, {
  "label": "Free Ship",
  "rowspan": false,
  "prop": "orderProgress.isFreeShip"
}, {
  "label": "Product Profit",
  "rowspan": false,
  "prop": "productProfit"
}, {
  "label": "Customer City",
  "rowspan": true,
  "prop": "customer.addressCity.nameWithType"
}, {
  "label": "Customer Group",
  "rowspan": true,
  "prop": "customer.customerGroup.name"
}, {
  "label": "Customer Source",
  "rowspan": true,
  "prop": "customer.customerSource.name"
}, {
  "label": "Order Sale Channel",
  "rowspan": true,
  "prop": "saleChannel.name"
}, {
  "label": "Payment Type",
  "rowspan": true,
  "prop": "orderProgress.paymentType"
}, {
  "label": "Payment Amount",
  "rowspan": true,
  "prop": "orderProgress.paymentAmount"
}, {
  "label": "Payment Dept Amount",
  "rowspan": true,
  "prop": "paymentDeptAmount"
}, {
  "label": "Delivery Fee Type",
  "rowspan": true,
  "prop": "orderProgress.deliveryFeeType"
}, {
  "label": "Invoice",
  "rowspan": true,
  "prop": "invoice"
}];
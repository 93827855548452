var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "create" ? "New product" : "Update product",
        visible: _vm.visible,
        fullscreen: "",
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "info", label: "Info product" } },
            [
              _c("product-info-form", {
                ref: "productInfoForm",
                attrs: {
                  categories: _vm.categories,
                  form: _vm.form,
                  rules: _vm.rules,
                  stores: _vm.stores,
                  handleBlurOriginPrice: _vm.handleBlurOriginPrice,
                  status: _vm.status,
                },
                on: {
                  "upload:ok": _vm.handleUploadOk,
                  delete: _vm.handleDeleteImage,
                },
              }),
            ],
            1
          ),
          _vm.status == "update"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    lazy: "",
                    name: "order-history",
                    label: "Order history",
                  },
                },
                [
                  _c("order-product-history", {
                    attrs: { productId: _vm.form.id },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            [
              _vm.status == "update"
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn-delete",
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v(" Delete ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "btn-save",
              attrs: { type: "primary", loading: _vm.loadingSubmit },
              on: {
                click: function ($event) {
                  _vm.status == "create" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
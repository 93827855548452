var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "Filters",
        visible: _vm.visible,
        direction: "rtl",
        size: "400px",
        "show-close": true,
        wrapperClosable: true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "filter-drawer" },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form, "label-position": "top" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Date" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "To",
                      format: "dd/MM/yyyy",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "Start date",
                      "end-placeholder": "End date",
                      clearable: false,
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Products" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        remote: "",
                        "remote-method": _vm.fetchProduct,
                      },
                      model: {
                        value: _vm.form.productId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "productId", $$v)
                        },
                        expression: "form.productId",
                      },
                    },
                    _vm._l(_vm.products, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: "" + item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Staff" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", clearable: "" },
                      model: {
                        value: _vm.form.staffId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "staffId", $$v)
                        },
                        expression: "form.staffId",
                      },
                    },
                    _vm._l(_vm.staffs, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn-group" },
            [
              _c(
                "el-button",
                {
                  staticClass: "font-bold font-size--medium",
                  attrs: { size: "medium" },
                  on: { click: _vm.handleClearAll },
                },
                [_vm._v(" Clear all ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "font-bold font-size--medium",
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.handleApply },
                },
                [_vm._v(" Apply ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "print-export-warehouse" } }, [
    _c("div", { staticClass: "import-warehouse-print" }, [
      _c("div", [
        _vm._m(0),
        _c("div", { staticClass: "text-center" }, [
          _c(
            "h3",
            {
              staticClass: "font-bold m-4",
              staticStyle: { "margin-bottom": "8px" },
            },
            [
              _vm._v(
                " PHIẾU XUẤT KHO" +
                  _vm._s(_vm.titleExportBill) +
                  "/EXPORT BILL" +
                  _vm._s(
                    _vm.data.type == _vm.ExportWarehouseType.Giveaway
                      ? " OTHER"
                      : ""
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "font-12 m-4",
              staticStyle: { "padding-bottom": "8px", "font-style": "italic" },
            },
            [_vm._v(" " + _vm._s(_vm.transformDate) + " ")]
          ),
          _c("p", { staticClass: "font-12 m-4" }, [
            _vm._v(
              " Số: " +
                _vm._s(_vm.data.printCode) +
                "(" +
                _vm._s(_vm.data.printNo) +
                ") "
            ),
          ]),
          _vm.data.privateCode
            ? _c("p", { staticClass: "font-12 m-4" }, [
                _vm._v(" Mã: " + _vm._s(_vm.data.privateCode) + " "),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("div", { staticClass: "info-item info-item-customer" }, [
          _c("div", { staticClass: "prefix font-16" }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(
                "- Họ và tên người giao/Customer's name: " +
                  _vm._s(_vm.data.printCustomerName)
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "info-item info-item-customer" }, [
          _c("div", { staticClass: "prefix font-16" }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(
                "- " +
                  _vm._s(_vm.labelReasonExport) +
                  ": " +
                  _vm._s(_vm.data.printNote)
              ),
            ]),
          ]),
        ]),
        _vm._m(1),
        _vm._m(2),
      ]),
      _c(
        "div",
        {
          staticClass: "content-block",
          staticStyle: { "margin-right": "10px" },
        },
        [
          _c("table", {}, [
            _vm._m(3),
            _c(
              "tbody",
              [
                _vm._l(_vm.table, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(item.stt))]),
                    _c("td", [_vm._v(_vm._s(item.name))]),
                    _c("td", [_vm._v(_vm._s(item.product.sku))]),
                    _c("td", [
                      _vm._v(_vm._s(_vm.UnitTrans[item.product.unit])),
                    ]),
                    _c("td", { staticStyle: { "text-align": "right" } }, [
                      _vm._v(_vm._s(item.exportQuantity)),
                    ]),
                    _c("td", { staticStyle: { "text-align": "right" } }, [
                      _vm._v(_vm._s(item.exportQuantity)),
                    ]),
                    _c("td", { staticStyle: { "text-align": "right" } }, [
                      _vm._v(_vm._s(_vm.$formatNumberVN(item.realPrice))),
                    ]),
                    _c("td", { staticStyle: { "text-align": "right" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$formatNumberVN(
                            item.exportQuantity * item.realPrice
                          )
                        )
                      ),
                    ]),
                  ])
                }),
                _c("tr", [
                  _c("td"),
                  _c("td", [_vm._v("Tổng cộng")]),
                  _c("td"),
                  _c("td"),
                  _c("td", { staticStyle: { "text-align": "right" } }, [
                    _vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalQuantity))),
                  ]),
                  _c("td", { staticStyle: { "text-align": "right" } }, [
                    _vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalQuantity))),
                  ]),
                  _c("td"),
                  _c("td", { staticStyle: { "text-align": "right" } }, [
                    _vm._v(_vm._s(_vm.$formatNumberVN(_vm.totalPrice))),
                  ]),
                ]),
              ],
              2
            ),
          ]),
          _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-top": "4px" } },
            [
              _c("span", [
                _vm._v("(Đã bao gồm VAT " + _vm._s(_vm.taxPercent) + "%)"),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "info", staticStyle: { "margin-top": "12px" } },
            [
              _c("div", [
                _c("div", { staticClass: "info-item info-item-customer" }, [
                  _c("div", { staticClass: "prefix font-16" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(
                        "- Tổng số tiền (viết bằng chữ): " +
                          _vm._s(
                            _vm
                              .$DocTienBangChu(Math.floor(_vm.totalPrice))
                              .replace(",", "")
                          )
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "info-item info-item-customer" }, [
                  _c("div", { staticClass: "prefix font-16" }, [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(
                        "- Số chứng từ gốc kèm theo: " +
                          _vm._s(_vm.data.printProof)
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c("footer", [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "flex-end",
              "margin-top": "16px",
            },
          },
          [
            _c("div", { staticClass: "text-center" }, [
              _c(
                "span",
                {
                  staticClass: "font-12",
                  staticStyle: { "font-weight": "300", "font-style": "italic" },
                },
                [_vm._v(_vm._s(_vm.transformDate))]
              ),
            ]),
          ]
        ),
        _vm._m(4),
        _c(
          "div",
          { staticClass: "sign", staticStyle: { "margin-top": "150px" } },
          [
            _c("div", { staticClass: "text-center" }, [
              _c(
                "span",
                {
                  staticClass: "font-12",
                  staticStyle: { "font-weight": "300" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.configurationList[_vm.ConfigurationParam.BillCreator]
                        .value
                    )
                  ),
                ]
              ),
            ]),
            _c("div"),
            _c("div", { staticClass: "text-center" }, [
              _c(
                "span",
                {
                  staticClass: "font-12",
                  staticStyle: { "font-weight": "300" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.configurationList[_vm.ConfigurationParam.Stocker]
                        .value
                    )
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "text-center" }, [
              _c(
                "span",
                {
                  staticClass: "font-12",
                  staticStyle: { "font-weight": "300" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.configurationList[
                        _vm.ConfigurationParam.AccountantName
                      ].value
                    )
                  ),
                ]
              ),
            ]),
            _vm._m(5),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "info-item" }, [
        _c("div", { staticClass: "prefix" }, [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("Đơn vị/Unit: CÔNG TY TNHH SHIRATORI VIỆT NAM"),
          ]),
        ]),
        _c(
          "h4",
          {
            staticClass: "font-12 font-bold m-0 text-center",
            staticStyle: { margin: "0" },
          },
          [_vm._v(" Mẫu số 01 - VT ")]
        ),
      ]),
      _c("div", { staticClass: "info-item" }, [
        _c("div", { staticClass: "prefix" }, [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("Bộ phận/Address: Quản lý kho"),
          ]),
        ]),
        _c("p", { staticClass: "font-12 font-bold m-0 text-center" }, [
          _vm._v(
            " (Ban hành theo Thông tư số 200/2014/TT-BTC Ngày 22/12/2014 của Bộ Tài chính) "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item info-item-customer" }, [
      _c("div", { staticClass: "prefix font-16" }, [
        _c("label", { attrs: { for: "" } }, [_vm._v("- Xuất tại kho: Vật tư")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item info-item-customer" }, [
      _c("div", { staticClass: "prefix font-16" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(
            "- Địa điểm/Address: Tầng 4, Số 169/1 Võ Thị Sáu, Phường Võ Thị Sáu, Quận 3, Thành phố Hồ Chí Minh, Việt Nam"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { rowspan: "2" } }, [_vm._v("STT")]),
        _c("th", { staticStyle: { width: "20%" }, attrs: { rowspan: "2" } }, [
          _vm._v(
            "Tên, nhãn hiệu, quy cách, phẩm chất vật tư, dụng cụ, sản phẩm, hàng hoá/Names,trademarks, qualityspecifications,materials, tools,products and goods"
          ),
        ]),
        _c("th", { staticStyle: { width: "10%" }, attrs: { rowspan: "2" } }, [
          _vm._v("Mã/Code"),
        ]),
        _c("th", { staticStyle: { width: "8%" }, attrs: { rowspan: "2" } }, [
          _vm._v("Đơn vị tính/Unit"),
        ]),
        _c("th", { attrs: { colspan: "2" } }, [_vm._v("Số lượng/Quantity")]),
        _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Đơn giá/Price")]),
        _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Thành tiền/Amount")]),
      ]),
      _c("tr", [
        _c("th", [_vm._v("Theo hóa đơn")]),
        _c("th", [_vm._v("Thực tế")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sign" }, [
      _c("div", { staticClass: "text-center column" }, [
        _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
          _vm._v(" Người lập phiếu"),
        ]),
        _c("span", { staticClass: "font-italic" }, [_vm._v("(Ký, họ tên)")]),
      ]),
      _c("div", { staticClass: "text-center column" }, [
        _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
          _vm._v("Người nhận hàng"),
        ]),
        _c("span", { staticClass: "font-italic" }, [_vm._v("(Ký, họ tên)")]),
      ]),
      _c("div", { staticClass: "text-center column" }, [
        _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
          _vm._v("Thủ kho"),
        ]),
        _c("span", { staticClass: "font-italic" }, [_vm._v("(Ký, họ tên)")]),
      ]),
      _c("div", { staticClass: "text-center column" }, [
        _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
          _vm._v("Kế toán trưởng"),
        ]),
        _c("span", { staticClass: "font-italic" }, [_vm._v("(Ký, họ tên)")]),
      ]),
      _c("div", { staticClass: "text-center column" }, [
        _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
          _vm._v("Giám đốc"),
        ]),
        _c("span", { staticClass: "font-italic" }, [_vm._v("(Ký, họ tên)")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c(
        "span",
        { staticClass: "font-12", staticStyle: { "font-weight": "300" } },
        [_vm._v("HATANO KIYOMI")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
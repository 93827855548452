var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Print Information" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "package-order-form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Customer name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.printCustomerName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "printCustomerName", $$v)
                  },
                  expression: "form.printCustomerName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "No." } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.printNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "printNo", $$v)
                  },
                  expression: "form.printNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Original document number" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.printProof,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "printProof", $$v)
                  },
                  expression: "form.printProof",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "btn-save",
          attrs: { loading: _vm.loading, type: "primary" },
          on: {
            click: function ($event) {
              return _vm.updateData()
            },
          },
        },
        [_vm._v("Save")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
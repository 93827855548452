var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Product" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "order-info-form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Staff", prop: "staffId" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(_vm.compare, "staffId") &&
                    "form-highlight",
                  staticStyle: { width: "200px" },
                  attrs: {
                    filterable: "",
                    disabled:
                      _vm.orderStatus == _vm.OrderStatus.Complete ||
                      _vm.disabled,
                  },
                  model: {
                    value: _vm.form.staffId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staffId", $$v)
                    },
                    expression: "form.staffId",
                  },
                },
                _vm._l(_vm.staffs, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Shipping charge by", prop: "shippingCharge" } },
            [
              _c(
                "div",
                { staticStyle: { "align-items": "center", display: "flex" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      class:
                        _vm.$getValuePropObj(
                          _vm.compare,
                          "orderProgress.shippingChargeBy"
                        ) && "form-highlight",
                      attrs: { size: "medium", disabled: _vm.disabled },
                      on: {
                        change: function ($event) {
                          return _vm.handleChangeShippingCharge()
                        },
                      },
                      model: {
                        value: _vm.orderProgress.shippingChargeBy,
                        callback: function ($$v) {
                          _vm.$set(_vm.orderProgress, "shippingChargeBy", $$v)
                        },
                        expression: "orderProgress.shippingChargeBy",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: _vm.ShippingChargeBy.Shiratori } },
                        [_vm._v("Shiratori")]
                      ),
                      _c(
                        "el-radio-button",
                        { attrs: { label: _vm.ShippingChargeBy.Customer } },
                        [_vm._v("Customer")]
                      ),
                    ],
                    1
                  ),
                  _c("el-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: {
                          alias: "decimal",
                          groupSeparator: ".",
                          rightAlign: true,
                          autoGroup: true,
                          repeat: 20,
                          autoUnmask: true,
                          allowMinus: false,
                        },
                        expression:
                          "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false, }",
                      },
                    ],
                    class:
                      _vm.$getValuePropObj(_vm.compare, "shippingCharge") &&
                      "form-highlight",
                    staticStyle: { flex: "1", "margin-left": "20px" },
                    attrs: {
                      placeholder: "-",
                      disabled:
                        _vm.orderStatus == _vm.OrderStatus.Complete ||
                        _vm.disabled,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.handleShippingCharge()
                      },
                      change: function (value) {
                        return _vm.changeShippingCharge(value)
                      },
                    },
                    model: {
                      value: _vm.form.shippingCharge,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "shippingCharge", $$v)
                      },
                      expression: "form.shippingCharge",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.shopType == "shopee"
            ? _c(
                "el-form-item",
                {
                  attrs: { label: "Total Shopee Fee", prop: "totalShopeeFee" },
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: {
                          alias: "decimal",
                          groupSeparator: ".",
                          rightAlign: true,
                          autoGroup: true,
                          repeat: 20,
                          autoUnmask: true,
                          allowMinus: false,
                        },
                        expression:
                          "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                      },
                    ],
                    staticStyle: { flex: "1" },
                    attrs: {
                      disabled:
                        _vm.orderStatus == _vm.OrderStatus.Complete ||
                        _vm.disabled,
                    },
                    model: {
                      value: _vm.form.totalShopeeFee,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "totalShopeeFee", $$v)
                      },
                      expression: "form.totalShopeeFee",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "Created Date", prop: "createdAt" } },
            [
              _c("el-date-picker", {
                class:
                  _vm.$getValuePropObj(_vm.compare, "createdAt") &&
                  "form-highlight",
                staticStyle: { width: "100%" },
                attrs: {
                  disabled:
                    _vm.orderStatus == _vm.OrderStatus.Complete || _vm.disabled,
                  "value-format": "timestamp",
                  format: "dd/MM/yyyy",
                },
                model: {
                  value: _vm.form.createdAt,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "createdAt", $$v)
                  },
                  expression: "form.createdAt",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "product-list",
          class: _vm.$getValuePropObj(_vm.compare, "productsLength")
            ? "form-highlight"
            : "",
        },
        [
          _c("div", { staticClass: "header-card" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("Product")]),
            _c("label", { attrs: { for: "" } }, [_vm._v("Published")]),
            _c("label", { attrs: { for: "" } }, [_vm._v("Lotnumber")]),
            _c("label", { attrs: { for: "" } }, [_vm._v("Price")]),
            _c("label", { attrs: { for: "" } }, [_vm._v("Quantity")]),
            _c("label", { attrs: { for: "" } }, [_vm._v("Amount")]),
          ]),
          _c(
            "div",
            { staticClass: "body-card" },
            [
              [
                _vm._l(_vm.dataTable, function (item, index) {
                  return _vm.dataTable && _vm.dataTable.length
                    ? _c(
                        "div",
                        {
                          key: index,
                          staticClass: "product-item",
                          class:
                            _vm.compare.products &&
                            _vm.$getValuePropObj(
                              _vm.compare.products[index],
                              "id"
                            )
                              ? "form-highlight product-highlight"
                              : "",
                        },
                        [
                          _c("div", { staticClass: "product-name" }, [
                            _c("div", [
                              item.productGalleries &&
                              item.productGalleries.length
                                ? _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$baseUrlMedia +
                                        item.productGalleries[0].image,
                                      width: "50",
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _c("div", [
                              _c("span", [_vm._v(" " + _vm._s(item.name))]),
                              !_vm.disabled
                                ? _c(
                                    "div",
                                    [
                                      _vm.orderStatus !=
                                      _vm.OrderStatus.Complete
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "Edit",
                                                placement: "top",
                                                effect: "dark",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-edit primary-color font-bold",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleEditProd(
                                                      index,
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.orderStatus !=
                                      _vm.OrderStatus.Complete
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "Remove",
                                                placement: "top",
                                                effect: "dark",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-close danger-color font-bold",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRemoveProd(
                                                      index,
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "price" }, [
                            item.isPublished
                              ? _c("label", [_vm._v("〇")])
                              : _c("label", [_vm._v("X")]),
                          ]),
                          _c("div", { staticClass: "price" }, [
                            _c(
                              "div",
                              { staticClass: "space-between" },
                              [
                                !_vm.isLinkShopee
                                  ? _c("label", [
                                      _vm._v(_vm._s(item.lotNumber)),
                                    ])
                                  : _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          value: item.lotNumber,
                                          filterable: "",
                                          "value-key": "id",
                                          loading: _vm.loading,
                                        },
                                        on: {
                                          change: function (val) {
                                            return _vm.handleSelectLotNumber(
                                              val,
                                              item
                                            )
                                          },
                                          focus: function ($event) {
                                            return _vm.fetchOrderLotNumber(item)
                                          },
                                        },
                                      },
                                      _vm._l(
                                        item.lotnumbers,
                                        function (lot, index) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: index,
                                              attrs: {
                                                label:
                                                  lot.lotNumber +
                                                  " - " +
                                                  _vm.$formatDate(lot.expired) +
                                                  " - " +
                                                  _vm.$formatNumberVN(
                                                    lot.quantity
                                                  ),
                                                value: index,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(lot.lotNumber) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm.$formatDate(
                                                        lot.expired
                                                      )
                                                    ) +
                                                    " - " +
                                                    _vm._s(
                                                      _vm.$formatNumberVN(
                                                        lot.quantity
                                                      )
                                                    )
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Expired: "),
                              ]),
                              _c("span", { staticClass: "origin-price" }, [
                                _vm._v(_vm._s(_vm.$formatDate(item.expired))),
                              ]),
                            ]),
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Manufacture date: "),
                              ]),
                              _c("span", { staticClass: "origin-price" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatDate(item.dateOfManufacture)
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.type == _vm.OrderType.Consign
                                      ? "Remain consign quantity"
                                      : "Quantity"
                                  ) + ": "
                                ),
                              ]),
                              _c("span", { staticClass: "origin-price" }, [
                                _vm._v(_vm._s(item.productQuantity)),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "price" }, [
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", [_vm._v("Origin price: ")]),
                              _c("span", { staticClass: "origin-price" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatNumberVN(item.originPrice)
                                  ) + "đ"
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", [_vm._v("Retail price: ")]),
                              _c("span", { staticClass: "retail-price" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatNumberVN(item.retailPrice)
                                  ) + "đ"
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", [_vm._v("Discount: ")]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "space-between",
                                staticStyle: { "align-items": "center" },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    class:
                                      _vm.compare.products &&
                                      !_vm.$getValuePropObj(
                                        _vm.compare.products[index],
                                        "id"
                                      ) &&
                                      _vm.$getValuePropObj(
                                        _vm.compare.products[index],
                                        "tabDiscount"
                                      ) &&
                                      "form-highlight",
                                    attrs: {
                                      size: "mini",
                                      disabled: _vm.disabled,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleChangeType(item)
                                      },
                                    },
                                    model: {
                                      value: item.tabDiscount,
                                      callback: function ($$v) {
                                        _vm.$set(item, "tabDiscount", $$v)
                                      },
                                      expression: "item.tabDiscount",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "price" } },
                                      [_vm._v("đ")]
                                    ),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "percent" } },
                                      [_vm._v("%")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: {
                                        alias: "decimal",
                                        groupSeparator: ".",
                                        rightAlign: true,
                                        autoGroup: true,
                                        repeat: 20,
                                        autoUnmask: true,
                                        allowMinus: false,
                                      },
                                      expression:
                                        "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                    },
                                  ],
                                  staticClass: "input-price",
                                  class:
                                    _vm.compare.products &&
                                    !_vm.$getValuePropObj(
                                      _vm.compare.products[index],
                                      "id"
                                    ) &&
                                    _vm.$getValuePropObj(
                                      _vm.compare.products[index],
                                      "discountInput"
                                    ) &&
                                    "form-highlight",
                                  staticStyle: { width: "125px" },
                                  attrs: {
                                    disabled:
                                      _vm.orderStatus ==
                                        _vm.OrderStatus.Complete ||
                                      _vm.disabled,
                                  },
                                  model: {
                                    value: item.discountInput,
                                    callback: function ($$v) {
                                      _vm.$set(item, "discountInput", $$v)
                                    },
                                    expression: "item.discountInput",
                                  },
                                }),
                              ],
                              1
                            ),
                            !_vm.disabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "space-between",
                                    staticStyle: { "align-items": "center" },
                                  },
                                  [
                                    _vm.showBirthdayCheckDiscount
                                      ? _c(
                                          "el-checkbox",
                                          {
                                            class:
                                              _vm.compare.products &&
                                              !_vm.$getValuePropObj(
                                                _vm.compare.products[index],
                                                "id"
                                              ) &&
                                              _vm.$getValuePropObj(
                                                _vm.compare.products[index],
                                                "isUseBirthdayDiscount"
                                              ) &&
                                              "form-highlight",
                                            attrs: {
                                              label: "",
                                              indeterminate: false,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.handleCheckDiscount(
                                                  item
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.isUseBirthdayDiscount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "isUseBirthdayDiscount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.isUseBirthdayDiscount",
                                            },
                                          },
                                          [_vm._v("Use Birthday Discount")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "space-between",
                                staticStyle: { "align-items": "center" },
                              },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Price"),
                                ]),
                                _c("span", { staticClass: "origin-price" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$formatNumberVN(item.realPrice)
                                    ) + "đ"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          item.maxQuantity ||
                          item.detailId ||
                          _vm.type != _vm.OrderType.Consign
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "flex-direction": "column",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: {
                                          alias: "decimal",
                                          groupSeparator: ".",
                                          rightAlign: false,
                                          autoGroup: true,
                                          repeat: 20,
                                          autoUnmask: true,
                                          allowMinus: false,
                                          max: item.maxQuantity,
                                        },
                                        expression:
                                          "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false, max: item.maxQuantity}",
                                      },
                                    ],
                                    class:
                                      _vm.compare.products &&
                                      !_vm.$getValuePropObj(
                                        _vm.compare.products[index],
                                        "id"
                                      ) &&
                                      _vm.$getValuePropObj(
                                        _vm.compare.products[index],
                                        "quantity"
                                      ) &&
                                      "form-highlight",
                                    attrs: {
                                      disabled:
                                        _vm.orderStatus ==
                                          _vm.OrderStatus.Complete ||
                                        _vm.disabled,
                                    },
                                    model: {
                                      value: item.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(item, "quantity", $$v)
                                      },
                                      expression: "item.quantity",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                        "margin-top": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-minus",
                                              size: "mini",
                                              disabled:
                                                _vm.orderStatus ==
                                                  _vm.OrderStatus.Complete ||
                                                _vm.disabled ||
                                                item.quantity == 0,
                                            },
                                            on: {
                                              click: function ($event) {
                                                item.quantity -= 1
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              icon: "el-icon-plus",
                                              size: "mini",
                                              disabled:
                                                _vm.orderStatus ==
                                                  _vm.OrderStatus.Complete ||
                                                _vm.disabled ||
                                                item.quantity ==
                                                  item.maxQuantity,
                                            },
                                            on: {
                                              click: function ($event) {
                                                item.quantity++
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c("div", [_c("span", [_vm._v("Out of stock")])]),
                          _c("div", [
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", [_vm._v("Total price: ")]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "retail-price font-bold color-green",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$formatNumberVN(item.totalPrice)
                                    ) + "đ"
                                  ),
                                ]
                              ),
                            ]),
                            item.shippingCharge
                              ? _c("div", { staticClass: "space-between" }, [
                                  _c("label", [_vm._v("Shipping Charge: ")]),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "retail-price font-bold color-red",
                                    },
                                    [
                                      _vm._v(
                                        "-" +
                                          _vm._s(
                                            _vm.$formatNumberVN(
                                              item.shippingCharge
                                            )
                                          ) +
                                          "đ"
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", [_vm._v("Profit: ")]),
                              _c(
                                "span",
                                { staticClass: "retail-price color-yellow" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$formatNumberVN(item.profit)) +
                                      "đ"
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      )
                    : _vm._e()
                }),
                _vm.form.customerId || _vm.type != _vm.OrderType.Consign
                  ? _c(
                      "div",
                      [
                        _vm.orderStatus != _vm.OrderStatus.Complete &&
                        !_vm.disabled
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-top": "16px" },
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  icon: "el-icon-plus",
                                },
                                on: { click: _vm.handleAddProduct },
                              },
                              [_vm._v("Add product")]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.products && _vm.form.products.length
                  ? _c("div", { staticClass: "summary" }, [
                      _c("div", [
                        _c("h3", { staticStyle: { color: "black" } }, [
                          _vm._v("Summary"),
                        ]),
                      ]),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div"),
                      _c("div", [
                        _c("div", { staticClass: "space-between" }, [
                          _c("label", [_vm._v("Total price: ")]),
                          _c(
                            "span",
                            {
                              staticClass: "retail-price font-bold color-green",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatNumberVN(_vm.form.totalPrice)
                                ) + "đ"
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "space-between" }, [
                          _c("label", [_vm._v("Profit: ")]),
                          _c(
                            "span",
                            { staticClass: "retail-price color-yellow" },
                            [
                              _vm._v(
                                _vm._s(_vm.$formatNumberVN(_vm.form.profit)) +
                                  "đ"
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "space-between" }, [
                          _c("label", [_vm._v("% VAT: ")]),
                          _c("span", { staticClass: "retail-price" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$formatNumberVN(
                                  (_vm.form.vatFactor || _vm.configVAT.value) *
                                    100
                                )
                              ) + "%"
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              _vm.form.products && !_vm.form.products.length
                ? _c("no-data")
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c("add-product-order-modal", {
        ref: "productModal",
        attrs: {
          productsSelected: _vm.form.products,
          type: _vm.type,
          customerId: _vm.form.customerId,
          oldOrderDetails: _vm.orderDetails,
        },
        on: { choose: _vm.handleChangeProduct },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "", visible: _vm.visible, width: "600px", top: "30px" },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
            },
            [
              _vm.data.length
                ? _vm._l(_vm.data, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "rate-box" },
                      [
                        _c("div", { staticClass: "user-box" }, [
                          _c("div", [
                            _c("img", {
                              staticClass: "avatar",
                              attrs: {
                                src: _vm.$baseUrlMedia + item.customer.avatar,
                                alt: "",
                              },
                            }),
                            _c("span", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.customer.name)),
                            ]),
                          ]),
                          _c("div", { staticClass: "rate" }, [
                            _c("span", [
                              _vm._v(" " + _vm._s(item.rateStar) + " "),
                              _c("img", {
                                attrs: {
                                  width: "12",
                                  src: _vm.imageStar,
                                  alt: "",
                                },
                              }),
                            ]),
                          ]),
                        ]),
                        _c("p", { staticClass: "content" }, [
                          _vm._v(" " + _vm._s(item.rateComment) + " "),
                        ]),
                      ]
                    )
                  })
                : _c("no-data"),
            ],
            2
          ),
          _c("pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.query.page,
              limit: _vm.query.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.query, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.query, "limit", $event)
              },
              pagination: _vm.fetchRates,
            },
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("close")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
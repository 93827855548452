var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.$devMode
        ? _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.importRoutesPath } },
            [_vm._v("Import")]
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          staticClass: "font-bold",
          attrs: { type: "primary", size: "medium", icon: "el-icon-plus" },
          on: {
            click: function ($event) {
              return _vm.$refs["add-role"].handleAdd()
            },
          },
        },
        [_vm._v("Add new ")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.rolesList, fit: "" },
          on: { "row-click": _vm.handleEdit },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: _vm.$t("role"), width: "220" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.name))]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "header-center", label: _vm.$t("description") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(_vm._s(scope.row.description))]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "Update role",
            top: "15px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              attrs: {
                model: _vm.role,
                "label-width": "80px",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Role" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.role.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.role, "name", $$v)
                      },
                      expression: "role.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Description" } },
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 2, maxRows: 4 },
                      type: "textarea",
                      placeholder: "Mô tả",
                    },
                    model: {
                      value: _vm.role.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.role, "description", $$v)
                      },
                      expression: "role.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Menus" } },
                [
                  _vm.checkStrictly
                    ? _c("el-tree", {
                        ref: "tree",
                        staticClass: "permission-tree",
                        attrs: {
                          "default-expand-all": true,
                          data: _vm.routes,
                          props: _vm.defaultProps,
                          "default-checked-keys": _vm.defaultCheck,
                          "show-checkbox": "",
                          "node-key": "path",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-save",
                  attrs: { type: "primary", loading: _vm.loadingUpdate },
                  on: { click: _vm.confirmRole },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-role", { ref: "add-role", on: { "add:success": _vm.getRoles } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Year")]),
            _c("el-date-picker", {
              staticStyle: { width: "100%" },
              attrs: { format: "yyyy", "value-format": "yyyy", type: "year" },
              on: {
                change: function ($event) {
                  _vm.query.page = 1
                  _vm.fetchData()
                },
              },
              model: {
                value: _vm.query.year,
                callback: function ($$v) {
                  _vm.$set(_vm.query, "year", $$v)
                },
                expression: "query.year",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Order Detail" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Code: ")]),
                      _c("span", [_vm._v(_vm._s(row.orderDetail.order.code))]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Product: "),
                      ]),
                      _c("span", [_vm._v(_vm._s(row.product.name))]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Lot: ")]),
                      _c("span", [_vm._v(_vm._s(row.orderDetail.lotNumber))]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Expired: "),
                      ]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$formatDate(row.orderDetail.expired))
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$formatDate(row.createdAt)) + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
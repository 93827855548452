"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportWarehouseApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var exportWarehouseApi = exports.exportWarehouseApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/exportWarehouse",
      params: params
    });
  },
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/exportWarehouse/".concat(id, "/delete"),
      method: 'post'
    });
  },
  create: function create(data) {
    return (0, _request.default)({
      url: "/exportWarehouse",
      data: data,
      method: "post"
    });
  },
  addDetail: function addDetail(id, data) {
    return (0, _request.default)({
      url: "/exportWarehouse/".concat(id, "/add/detail"),
      method: 'post',
      data: data
    });
  },
  deleteDetail: function deleteDetail(id) {
    return (0, _request.default)({
      url: "/exportWarehouseDetail/".concat(id, "/delete"),
      method: 'post'
    });
  },
  update: function update(id, data) {
    return (0, _request.default)({
      url: "/exportWarehouse/".concat(id, "/update"),
      data: data,
      method: "post"
    });
  },
  updateCode: function updateCode(id, data) {
    return (0, _request.default)({
      url: "/exportWarehouse/".concat(id, "/code"),
      data: data,
      method: "patch"
    });
  },
  updateDetailQuantity: function updateDetailQuantity(id, data) {
    return (0, _request.default)({
      url: "/exportWarehouseDetail/".concat(id, "/update/quantity"),
      data: data,
      method: "post"
    });
  }
};
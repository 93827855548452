var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Product" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "order-info-form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Staff", prop: "staffId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    disabled: _vm.giveawayStatus == _vm.OrderStatus.Complete,
                  },
                  model: {
                    value: _vm.form.staffId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staffId", $$v)
                    },
                    expression: "form.staffId",
                  },
                },
                _vm._l(_vm.staffs, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Created Date", prop: "createdAt" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  disabled: _vm.giveawayStatus == _vm.OrderStatus.Complete,
                  "value-format": "timestamp",
                  format: "dd/MM/yyyy",
                },
                model: {
                  value: _vm.form.createdAt,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "createdAt", $$v)
                  },
                  expression: "form.createdAt",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Giveaway Status", prop: "status" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.GiveawayStatus, function (item, key) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: key, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Invoice No" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.invoice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "invoice", $$v)
                  },
                  expression: "form.invoice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Purpose" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { rows: 3, type: "textarea" },
                model: {
                  value: _vm.form.purpose,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "purpose", $$v)
                  },
                  expression: "form.purpose",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Note" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { rows: 3, type: "textarea" },
                model: {
                  value: _vm.form.note,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "note", $$v)
                  },
                  expression: "form.note",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Invoice Link" } },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { rows: 3, type: "textarea" },
                model: {
                  value: _vm.form.invoiceLink,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "invoiceLink", $$v)
                  },
                  expression: "form.invoiceLink",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "product-list" }, [
        _c("div", { staticClass: "header-card" }, [
          _c("label", { attrs: { for: "" } }, [_vm._v("Product")]),
          _c("label", { attrs: { for: "" } }, [_vm._v("Published")]),
          _c("label", { attrs: { for: "" } }, [_vm._v("Lotnumber")]),
          _c("label", { attrs: { for: "" } }, [_vm._v("Quantity")]),
        ]),
        _c(
          "div",
          { staticClass: "body-card" },
          [
            [
              _vm._l(_vm.transformData, function (item, index) {
                return _vm.transformData && _vm.transformData.length
                  ? _c("div", { key: index, staticClass: "product-item" }, [
                      _c("div", { staticClass: "product-name" }, [
                        _c("div", [
                          item.productGalleries && item.productGalleries.length
                            ? _c("img", {
                                attrs: {
                                  src:
                                    _vm.$baseUrlMedia +
                                    item.productGalleries[0].image,
                                  width: "50",
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(item.name))]),
                        _c(
                          "div",
                          [
                            _vm.giveawayStatus != _vm.OrderStatus.Complete
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Edit",
                                      placement: "top",
                                      effect: "dark",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-edit primary-color font-bold",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEditProd(index, item)
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm.giveawayStatus != _vm.OrderStatus.Complete
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "Remove",
                                      placement: "top",
                                      effect: "dark",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-icon-close danger-color font-bold",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRemoveProd(
                                            index,
                                            item
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "price" }, [
                        item.isPublished
                          ? _c("label", [_vm._v("〇")])
                          : _c("label", [_vm._v("X")]),
                      ]),
                      _c("div", { staticClass: "price" }, [
                        _c(
                          "div",
                          { staticClass: "space-between" },
                          [
                            !_vm.isLinkShopee
                              ? _c("label", [_vm._v(_vm._s(item.lotNumber))])
                              : _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      value: item.lotNumber,
                                      filterable: "",
                                      "value-key": "id",
                                      loading: _vm.loading,
                                    },
                                    on: {
                                      change: function (val) {
                                        return _vm.handleSelectLotNumber(
                                          val,
                                          item
                                        )
                                      },
                                      focus: function ($event) {
                                        return _vm.fetchOrderLotNumber(item)
                                      },
                                    },
                                  },
                                  _vm._l(
                                    item.lotnumbers,
                                    function (lot, index) {
                                      return _c(
                                        "el-option",
                                        {
                                          key: index,
                                          attrs: {
                                            label:
                                              lot.lotNumber +
                                              " - " +
                                              _vm.$formatDate(lot.expired) +
                                              " - " +
                                              _vm.$formatNumberVN(lot.quantity),
                                            value: index,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(lot.lotNumber) +
                                                " - " +
                                                _vm._s(
                                                  _vm.$formatDate(lot.expired)
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  _vm.$formatNumberVN(
                                                    lot.quantity
                                                  )
                                                )
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "space-between" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Expired: "),
                          ]),
                          _c("span", { staticClass: "origin-price" }, [
                            _vm._v(_vm._s(_vm.$formatDate(item.expired))),
                          ]),
                        ]),
                        _c("div", { staticClass: "space-between" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Manufacture date: "),
                          ]),
                          _c("span", { staticClass: "origin-price" }, [
                            _vm._v(
                              _vm._s(_vm.$formatDate(item.dateOfManufacture))
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "space-between" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Quantity: "),
                          ]),
                          _c("span", { staticClass: "origin-price" }, [
                            _vm._v(_vm._s(item.productQuantity)),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "div",
                            [
                              _c("el-button", {
                                attrs: {
                                  disabled:
                                    _vm.giveawayStatus ==
                                    _vm.OrderStatus.Complete,
                                  icon: "el-icon-minus",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    item.quantity -= 1
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c("el-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: {
                                  alias: "decimal",
                                  groupSeparator: ".",
                                  rightAlign: false,
                                  autoGroup: true,
                                  repeat: 20,
                                  autoUnmask: true,
                                  allowMinus: false,
                                },
                                expression:
                                  "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                              },
                            ],
                            attrs: {
                              disabled:
                                _vm.giveawayStatus == _vm.OrderStatus.Complete,
                            },
                            model: {
                              value: item.quantity,
                              callback: function ($$v) {
                                _vm.$set(item, "quantity", $$v)
                              },
                              expression: "item.quantity",
                            },
                          }),
                          _c(
                            "div",
                            [
                              _c("el-button", {
                                attrs: {
                                  disabled:
                                    _vm.giveawayStatus ==
                                    _vm.OrderStatus.Complete,
                                  icon: "el-icon-plus",
                                  size: "mini",
                                },
                                on: {
                                  click: function ($event) {
                                    item.quantity += 1
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e()
              }),
              _vm.form.customerId || _vm.form.type != _vm.GiveawayType.Consign
                ? _c(
                    "div",
                    [
                      _vm.giveawayStatus != _vm.OrderStatus.Complete
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { "margin-top": "16px" },
                              attrs: {
                                type: "primary",
                                size: "mini",
                                icon: "el-icon-plus",
                              },
                              on: { click: _vm.handleAddProduct },
                            },
                            [_vm._v("Add product")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.transformData && _vm.transformData.length
                ? _c("div", { staticClass: "summary" }, [
                    _c("div", [
                      _c("h3", { staticStyle: { color: "black" } }, [
                        _vm._v("Summary"),
                      ]),
                    ]),
                    _c("div"),
                    _c("div"),
                    _c("div", [
                      _c("div", { staticClass: "space-between" }, [
                        _c("label", [_vm._v("Quantity: ")]),
                        _c(
                          "span",
                          { staticClass: "retail-price font-bold color-green" },
                          [
                            _vm._v(
                              _vm._s(_vm.$formatNumberVN(_vm.summary.quantity))
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            _vm.transformData && !_vm.transformData.length
              ? _c("no-data")
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("add-product-order-modal", {
        ref: "productModal",
        attrs: {
          productsSelected: _vm.transformData,
          type: _vm.form.type,
          customerId: _vm.form.customerId,
        },
        on: { choose: _vm.handleChangeProduct },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
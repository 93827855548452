var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        title: "Nhập khách hàng hàng loạt(Excel)",
        visible: _vm.visible,
        width: _vm.modalWidth,
        top: "30px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-download" },
              on: { click: _vm.handleDownloadSample },
            },
            [_vm._v("Tải file mẫu")]
          ),
          _c("UploadExcel", {
            staticStyle: { "margin-top": "16px" },
            attrs: { "on-success": _vm.handleUploadSuccess },
          }),
          _c("br"),
          _c("label", { attrs: { for: "" } }, [_vm._v("*Lưu ý:")]),
          _c("ul", [
            _c("li", [
              _vm._v(" Những key của cột bắt buộc "),
              _c("p", [_vm._v("- Name")]),
              _c("p", [_vm._v("- Phone")]),
            ]),
            _c("li", [
              _vm._v(" Thêm dữ liệu "),
              _c("p", [
                _vm._v("- Gender truyền 1 trong 2 giá trị (MALE - FEMALE)"),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c("div"),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                on: {
                  click: function ($event) {
                    _vm.visible = false
                  },
                },
              },
              [_vm._v("Đóng")]
            ),
            _vm.data.length
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn-save",
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.createData },
                  },
                  [_vm._v("Submit")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: _vm.numCol } },
            [_c("ProfitSummary")],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: _vm.numCol } },
            [_c("CommonSummary")],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: _vm.numCol } },
            [_c("OrderSummary")],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: _vm.numCol } },
            [_c("CustomerSummary")],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: _vm.numCol } },
            [_c("SaleSummary")],
            1
          ),
          _c(
            "el-col",
            { staticClass: "card-panel-col", attrs: { span: _vm.numCol } },
            [_c("DetailOrderSummary")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PaymentType = exports.PaymentStatus = void 0;
var PaymentType;
(function (PaymentType) {
  PaymentType["Cash"] = "CASH";
  PaymentType["COD"] = "COD";
  PaymentType["Transfer"] = "TRANSFER";
})(PaymentType || (exports.PaymentType = PaymentType = {}));
var PaymentStatus;
(function (PaymentStatus) {
  PaymentStatus["Pending"] = "PENDING";
  PaymentStatus["Complete"] = "COMPLETE"; // Da thanh toan
})(PaymentStatus || (exports.PaymentStatus = PaymentStatus = {}));
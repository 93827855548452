var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title: _vm.status == "create" ? "New customer" : "Update customer",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "append-to-body": "",
        fullscreen: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "top" },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { name: "info", label: "Info customer" } },
            [
              _c("customer-info-form", {
                ref: "customerInfoForm",
                attrs: {
                  form: _vm.form,
                  rules: _vm.rules,
                  visible: _vm.visible,
                  status: _vm.status,
                },
              }),
            ],
            1
          ),
          _vm.status == "update"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    lazy: "",
                    name: "order-history",
                    label: "Order history",
                  },
                },
                [
                  _c("order-customer-history", {
                    attrs: { customerId: _vm.form.id },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.status == "update"
            ? _c(
                "el-tab-pane",
                {
                  attrs: {
                    lazy: "",
                    name: "tracking-birthday-discount",
                    label: "Tracking Use Birthday discount",
                  },
                },
                [
                  _c("tracking-birthday-discount", {
                    attrs: { customerId: _vm.form.id },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            [
              _vm.status == "update"
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn-delete",
                      attrs: { loading: _vm.loadingButton },
                      on: { click: _vm.handleDelete },
                    },
                    [_vm._v("Delete")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.tabName == "info"
            ? _c(
                "el-button",
                {
                  staticClass: "btn-save",
                  attrs: { type: "primary", loading: _vm.loadingButton },
                  on: {
                    click: function ($event) {
                      _vm.status == "update"
                        ? _vm.updateData()
                        : _vm.createData()
                    },
                  },
                },
                [_vm._v("Submit")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
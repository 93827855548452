var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "print-receipt" } }, [
    _c("div", { staticClass: "import-warehouse-print" }, [
      _c("div", [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "info-export", staticStyle: { right: "30px" } },
          [
            _vm._m(1),
            _c("div", { staticClass: "info-item" }, [
              _c("div", { staticClass: "font-12 text-right" }, [
                _vm._v("Số/号"),
              ]),
              _c("div", [_vm._v(":")]),
              _c("div", { staticClass: "font-12" }, [
                _vm._v(_vm._s(_vm.data.printNo)),
              ]),
            ]),
            _vm._m(2),
            _vm._m(3),
          ]
        ),
        _c("div", { staticClass: "text-center" }, [
          _vm._m(4),
          _c(
            "p",
            {
              staticClass: "font-12 m-4",
              staticStyle: { "padding-bottom": "8px", "font-style": "italic" },
            },
            [
              _vm._v(" " + _vm._s(_vm.transformDate)),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.transformDateJp) + " "),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "info" }, [
        _c("div", [
          _c("div", { staticClass: "info-item info-item-customer" }, [
            _c("div", { staticClass: "prefix font-16" }, [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(
                  "Họ và tên người nộp tiền/収入者の氏名: " +
                    _vm._s(_vm.data.customer && _vm.data.customer.name)
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "info-item info-item-customer" }, [
            _c("div", { staticClass: "prefix font-16" }, [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(
                  "Địa chỉ/住所: " +
                    _vm._s(
                      _vm.data.customer &&
                        _vm.data.customer.receiverAddress +
                          ", " +
                          _vm.data.customer.receiverWardName +
                          " " +
                          _vm.data.customer.receiverDistrictName +
                          " " +
                          _vm.data.customer.receiverCityName
                    )
                ),
              ]),
            ]),
          ]),
          _vm._m(5),
          _c("div", { staticClass: "info-item info-item-customer" }, [
            _c("div", { staticClass: "prefix font-16" }, [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(
                  "Số tiền nộp/支払金額: " +
                    _vm._s(_vm.$formatNumberVN(_vm.data.totalPrice)) +
                    " VND"
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "info-item info-item-customer" }, [
            _c(
              "div",
              {
                staticClass: "prefix font-16",
                staticStyle: { "font-style": "italic" },
              },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(
                    "(Viết bằng chữ/(文字で記載) " +
                      _vm._s(_vm.$DocTienBangChu(_vm.data.totalPrice)) +
                      " đồng"
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _c("footer", [
        _vm._m(6),
        _vm._m(7),
        _c(
          "div",
          { staticClass: "sign", staticStyle: { "margin-top": "150px" } },
          [
            _vm._m(8),
            _c("div", { staticClass: "text-center" }, [
              _c(
                "span",
                {
                  staticClass: "font-12",
                  staticStyle: { "font-weight": "300" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.configurationList[
                        _vm.ConfigurationParam.AccountantName
                      ]
                        ? _vm.configurationList[
                            _vm.ConfigurationParam.AccountantName
                          ].value
                        : ""
                    )
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "text-center" }, [
              _c(
                "span",
                {
                  staticClass: "font-12",
                  staticStyle: { "font-weight": "300" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.configurationList[_vm.ConfigurationParam.Treasurer]
                        ? _vm.configurationList[
                            _vm.ConfigurationParam.Treasurer
                          ].value
                        : ""
                    )
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "text-center" }, [
              _c(
                "span",
                {
                  staticClass: "font-12",
                  staticStyle: { "font-weight": "300" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.configurationList[_vm.ConfigurationParam.BillCreator]
                        ? _vm.configurationList[
                            _vm.ConfigurationParam.BillCreator
                          ].value
                        : ""
                    )
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "text-center" }, [
              _c(
                "span",
                {
                  staticClass: "font-12",
                  staticStyle: { "font-weight": "300" },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.configurationList[_vm.ConfigurationParam.BillReceiver]
                        ? _vm.configurationList[
                            _vm.ConfigurationParam.BillReceiver
                          ].value
                        : ""
                    )
                  ),
                ]
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "info-item" }, [
            _c("div", { staticClass: "prefix" }, [
              _c("label", { attrs: { for: "" } }, [
                _vm._v(
                  "Đã nhận đủ số tiền/十分に受け取る: " +
                    _vm._s(_vm.$DocTienBangChu(_vm.data.totalPrice))
                ),
              ]),
            ]),
          ]),
          _vm._m(9),
          _vm._m(10),
          _vm._m(11),
          _vm._m(12),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "info-item" }, [
        _c("div", { staticClass: "prefix" }, [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("Đơn vị/会社: CÔNG TY TNHH SHIRATORI VIỆT NAM"),
          ]),
        ]),
        _c(
          "h4",
          {
            staticClass: "font-12 font-bold m-0 text-center",
            staticStyle: { margin: "0" },
          },
          [_vm._v(" Mẫu số/フォーム: 02 - TT ")]
        ),
      ]),
      _c("div", { staticClass: "info-item" }, [
        _c("div", { staticClass: "prefix" }, [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(
              "Địa chỉ/住所: Tầng 4, Số 169/1 Võ Thị Sáu, Phường Võ Thị Sáu, Quận 3, Thành phố Hồ Chí Minh, Việt Nam"
            ),
          ]),
        ]),
        _c("p", { staticClass: "font-12 font-bold m-0 text-center" }, [
          _vm._v(
            " (Ban hành theo Thông tư số 200/2014/TT-BTC Ngày 22/12/2014 của Bộ Tài chính) "
          ),
        ]),
      ]),
      _c("div", { staticClass: "info-item" }, [
        _c("div", { staticClass: "prefix" }),
        _c("p", { staticClass: "font-bold m-0 font-12 text-center" }, [
          _vm._v(
            " (財務省より公布された 2014年12月22日付での決定200/2014/TT-BTC号) "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item" }, [
      _c("div", { staticClass: "font-12 text-right" }, [
        _vm._v("Quyển số/書籍番号"),
      ]),
      _c("div", [_vm._v(":")]),
      _c("div", { staticClass: "font-12" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item" }, [
      _c("div", { staticClass: "font-12 text-right" }, [_vm._v("Nợ/債務")]),
      _c("div", [_vm._v(":")]),
      _c("div", { staticClass: "font-12" }, [_vm._v("...")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item" }, [
      _c("div", { staticClass: "font-12 text-right" }, [_vm._v("Có/入金")]),
      _c("div", [_vm._v(":")]),
      _c("div", { staticClass: "font-12" }, [_vm._v("...")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      { staticClass: "font-bold m-4", staticStyle: { "margin-bottom": "8px" } },
      [_vm._v(" PHIẾU THU"), _c("br"), _vm._v(" 入金伝票 ")]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item info-item-customer" }, [
      _c("div", { staticClass: "prefix font-16" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v("Lý do thu/理由: Bán hàng"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "grid", "grid-template-columns": "1fr 1fr" } },
      [
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "info-item" }, [
            _c("div", { staticClass: "prefix font-16" }, [
              _c("label", { attrs: { for: "" } }, [_vm._v("Kèm theo/添付: ")]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "info-item" }, [
            _c("div", { staticClass: "prefix font-16" }, [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Chứng từ gốc/原本書類: "),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "sign", staticStyle: { "margin-top": "-10px" } },
      [
        _c("div", { staticClass: "text-center column" }, [
          _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
            _vm._v("Giám đốc"),
            _c("br"),
            _vm._v("社長"),
          ]),
          _c("span", { staticClass: "font-italic" }, [
            _vm._v("(Ký, họ tên/署名、氏名の明記)"),
          ]),
        ]),
        _c("div", { staticClass: "text-center column" }, [
          _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
            _vm._v("Kế toán trưởng"),
            _c("br"),
            _vm._v("チーフアカウンタント"),
          ]),
          _c("span", { staticClass: "font-italic" }, [
            _vm._v("(Ký, họ tên/署名、氏名の明記)"),
          ]),
        ]),
        _c("div", { staticClass: "text-center column" }, [
          _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
            _vm._v("Thủ quỹ"),
            _c("br"),
            _vm._v("勘定係"),
          ]),
          _c("span", { staticClass: "font-italic" }, [
            _vm._v("(Ký, họ tên/署名、氏名の明記)"),
          ]),
        ]),
        _c("div", { staticClass: "text-center column" }, [
          _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
            _vm._v("Người lập phiếu"),
            _c("br"),
            _vm._v("作成者"),
          ]),
          _c("span", { staticClass: "font-italic" }, [
            _vm._v("(Ký, họ tên/署名、氏名の明記)"),
          ]),
        ]),
        _c("div", { staticClass: "text-center column" }, [
          _c("label", { staticClass: "font-12", attrs: { for: "" } }, [
            _vm._v("Người nhận tiền"),
            _c("br"),
            _vm._v("支出者"),
          ]),
          _c("span", { staticClass: "font-italic" }, [
            _vm._v("(Ký, họ tên/署名、氏名の明記)"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c(
        "span",
        { staticClass: "font-12", staticStyle: { "font-weight": "300" } },
        [_vm._v("HATANO KIYOMI")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item" }, [
      _c(
        "div",
        { staticClass: "prefix", staticStyle: { "font-style": "italic" } },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("(Viết bằng chữ/(文字で記載)"),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item" }, [
      _c(
        "div",
        { staticClass: "prefix", staticStyle: { "font-style": "italic" } },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v(
              "+ Tỷ giá ngoại tệ (vàng bạc, đá quý)/為替レート（金、銀、宝石）:"
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item" }, [
      _c(
        "div",
        { staticClass: "prefix", staticStyle: { "font-style": "italic" } },
        [
          _c("label", { attrs: { for: "" } }, [
            _vm._v("+ Số tiền quy đổi/変換金額："),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "info-item" }, [
      _c("div", { staticClass: "prefix" }, [
        _c("label", { attrs: { for: "" } }, [
          _vm._v(
            "(Liên gửi ra ngoài phải đóng dấu/顧客に渡る通は法人押印必要だ)"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { rules: _vm.rules, model: _vm.form, "label-position": "top" },
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 40 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Name", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Phone", prop: "phone" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.form.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Email", prop: "email" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.form.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.status != "create"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Code", prop: "code" } },
                            [
                              _c("el-input", {
                                attrs: { size: "medium", disabled: "" },
                                model: {
                                  value: _vm.form.code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "code", $$v)
                                  },
                                  expression: "form.code",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Birthday", prop: "birthday" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "medium",
                              format: "dd/MM/yyyy",
                              "value-format": "dd/MM/yyyy",
                            },
                            model: {
                              value: _vm.form.birthday,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "birthday", $$v)
                              },
                              expression: "form.birthday",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Gender", prop: "Gender" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "medium" },
                              model: {
                                value: _vm.form.gender,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "gender", $$v)
                                },
                                expression: "form.gender",
                              },
                            },
                            _vm._l(_vm.GenderType, function (item) {
                              return _c("el-option", {
                                key: item,
                                attrs: { label: item, value: item },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Age" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium", disabled: "" },
                            model: {
                              value: _vm.age,
                              callback: function ($$v) {
                                _vm.age = $$v
                              },
                              expression: "age",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Skin status", prop: "skinStatuses" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                size: "medium",
                                filterable: "",
                                multiple: "",
                              },
                              model: {
                                value: _vm.form.skinStatusIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "skinStatusIds", $$v)
                                },
                                expression: "form.skinStatusIds",
                              },
                            },
                            _vm._l(
                              _vm.$store.state.skinStatus.list,
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Group", prop: "customerGroupId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: _vm.form.customerGroupId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "customerGroupId", $$v)
                                },
                                expression: "form.customerGroupId",
                              },
                            },
                            _vm._l(
                              _vm.$store.state.customerGroup.list,
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Source", prop: "customerSourceId" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "medium", clearable: "" },
                              model: {
                                value: _vm.form.customerSourceId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "customerSourceId", $$v)
                                },
                                expression: "form.customerSourceId",
                              },
                            },
                            _vm._l(
                              _vm.$store.state.customerSource.list,
                              function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Staff", prop: "staffId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                size: "medium",
                              },
                              model: {
                                value: _vm.form.staffId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "staffId", $$v)
                                },
                                expression: "form.staffId",
                              },
                            },
                            _vm._l(_vm.staffs, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.checkGroupRetail
                    ? _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Rank" } },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.form.customerRankId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "customerRankId", $$v)
                                    },
                                    expression: "form.customerRankId",
                                  },
                                },
                                _vm._l(
                                  _vm.$store.state.customerRank.list,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.checkGroupRetail && _vm.form.nextRank
                ? _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 8, xs: 24, offset: 0 } }, [
                        _c(
                          "div",
                          { staticClass: "progress-rank" },
                          [
                            _c(
                              "vue-ellipse-progress",
                              {
                                attrs: {
                                  progress: _vm.progress,
                                  angle: -90,
                                  color: "#003586",
                                  size: 100,
                                  thickness: 5,
                                  legend: true,
                                  loading: false,
                                  fontColor: "black",
                                  gap: 10,
                                  dot: "5 #003586",
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    display: "block",
                                    margin: "0 auto",
                                  },
                                  attrs: { src: _vm.logo, width: "40" },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "margin-top": "8px" } },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "legend-value" },
                                    slot: "legend-value",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatNumberVN(_vm.form.totalSale)
                                      ) +
                                        " > " +
                                        _vm._s(
                                          _vm.$formatNumberVN(
                                            _vm.form.nextRank.amount
                                          )
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 16, offset: 0, xs: 24 } }, [
                        _c("div", { staticStyle: { margin: "10px 0" } }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v(
                              "- Your current rank is: " +
                                _vm._s(
                                  _vm.form.currentRank
                                    ? _vm.form.currentRank.name
                                    : "No"
                                )
                            ),
                          ]),
                        ]),
                        _c("div", { staticStyle: { margin: "10px 0" } }, [
                          _vm.remainAmount > 0
                            ? _c("label", { attrs: { for: "" } }, [
                                _vm._v(
                                  "- You have " +
                                    _vm._s(
                                      _vm.$formatNumberVN(_vm.remainAmount)
                                    ) +
                                    " points left to next rank"
                                ),
                              ])
                            : _c("label", [
                                _vm._v(
                                  "- You have enough point to rank " +
                                    _vm._s(_vm.form.nextRank.name)
                                ),
                              ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12, offset: 0 } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "City" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "" },
                              on: { change: _vm.handleChangeCity },
                              model: {
                                value: _vm.form.receiverCityId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "receiverCityId", $$v)
                                },
                                expression: "form.receiverCityId",
                              },
                            },
                            _vm._l(_vm.cities, function (item) {
                              return _c("el-option", {
                                key: item.ProvinceID,
                                attrs: {
                                  label: item.ProvinceName,
                                  value: item.ProvinceID,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "District" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "" },
                              on: { change: _vm.handleChangeDistrict },
                              model: {
                                value: _vm.form.receiverDistrictId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "receiverDistrictId", $$v)
                                },
                                expression: "form.receiverDistrictId",
                              },
                            },
                            _vm._l(_vm.districts, function (item) {
                              return _c("el-option", {
                                key: item.DistrictID,
                                attrs: {
                                  label: item.DistrictName,
                                  value: item.DistrictID,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Ward" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { filterable: "" },
                              on: { change: _vm.handleChangeWard },
                              model: {
                                value: _vm.form.receiverWardCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "receiverWardCode", $$v)
                                },
                                expression: "form.receiverWardCode",
                              },
                            },
                            _vm._l(_vm.wards, function (item) {
                              return _c("el-option", {
                                key: item.WardCode,
                                attrs: {
                                  label: item.WardName,
                                  value: item.WardCode,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Address" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium" },
                    model: {
                      value: _vm.form.receiverAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "receiverAddress", $$v)
                      },
                      expression: "form.receiverAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Notes" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 7, maxRows: 10 },
                    },
                    model: {
                      value: _vm.form.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "note", $$v)
                      },
                      expression: "form.note",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Company name" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.form.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "companyName", $$v)
                              },
                              expression: "form.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tax code" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.form.taxCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "taxCode", $$v)
                              },
                              expression: "form.taxCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Website" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.form.website,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "website", $$v)
                              },
                              expression: "form.website",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12, offset: 0 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "Facebook" } },
                        [
                          _c("el-input", {
                            attrs: { size: "medium" },
                            model: {
                              value: _vm.form.facebook,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "facebook", $$v)
                              },
                              expression: "form.facebook",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
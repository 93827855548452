var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, fit: "" },
          on: {
            "row-click": function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.onClickRow($event)
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Code", width: "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [_vm._v(_vm._s(row.code))]),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.tagOptions[row.orderStatus].type,
                          effect: _vm.tagOptions[row.orderStatus].effect,
                          size: "small",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.orderStatus) + " ")]
                    ),
                    row.type == _vm.OrderType.Consign
                      ? _c(
                          "el-tag",
                          {
                            staticStyle: { "margin-top": "8px" },
                            attrs: {
                              type: "success",
                              effect: "dark",
                              size: "small",
                            },
                          },
                          [_vm._v(" CONSIGN ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Customer", width: "auto", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.customer
                      ? _c("div", { staticClass: "label-content" }, [
                          _c("label", { attrs: { for: "" } }),
                          _c("span", { staticClass: "danger-color" }, [
                            _vm._v("GIFT (HÀNG TẶNG KHÔNG THU TIỀN)"),
                          ]),
                        ])
                      : _c("div", [
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Name: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.customer.name))]),
                          ]),
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Phone: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.customer.phone))]),
                          ]),
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Address: "),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(row.customer.receiverAddress) +
                                  ", " +
                                  _vm._s(row.customer.receiverWardName) +
                                  ", " +
                                  _vm._s(row.customer.receiverDistrictName) +
                                  ", " +
                                  _vm._s(row.customer.receiverCityName)
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Group: "),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  row.customer.customerGroup &&
                                    row.customer.customerGroup.name
                                )
                              ),
                            ]),
                          ]),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "200px", label: "Products" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.orderDetails, function (orderDetail) {
                    return _c(
                      "div",
                      { key: orderDetail.id, staticClass: "space-between" },
                      [
                        _c("label", [_vm._v(_vm._s(orderDetail.product.name))]),
                        _c("span", { staticStyle: { "min-width": "50px" } }, [
                          _vm._v(
                            "x" +
                              _vm._s(_vm.$formatNumberVN(orderDetail.quantity))
                          ),
                        ]),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Price", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "space-between" }, [
                      _c("label", [_vm._v("Origin: ")]),
                      _c("span", { staticClass: "retail-price font-bold" }, [
                        _vm._v(
                          _vm._s(_vm.$formatNumberVN(row.totalOriginPrice)) +
                            "đ"
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "space-between" }, [
                      _c("label", [_vm._v("Total: ")]),
                      _c(
                        "span",
                        { staticClass: "retail-price font-bold color-green" },
                        [
                          _vm._v(
                            _vm._s(_vm.$formatNumberVN(row.totalPrice)) + "đ"
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "space-between" }, [
                      _c("label", [_vm._v("Profit: ")]),
                      _c(
                        "span",
                        { staticClass: "retail-price font-bold color-yellow" },
                        [_vm._v(_vm._s(_vm.$formatNumberVN(row.profit)) + "đ")]
                      ),
                    ]),
                    _c("div", { staticClass: "space-between" }, [
                      _c("label", [_vm._v("% VAT: ")]),
                      _c("span", { staticClass: "retail-price font-bold" }, [
                        _vm._v(
                          _vm._s(_vm.$formatNumberVN(row.vatFactor * 100)) + "%"
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Staff", width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.staff && row.staff.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm.type == "dashboard"
            ? _c("el-table-column", {
                attrs: { label: "Handled", width: "70" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c("el-checkbox", {
                                attrs: { value: row.isHandled, label: "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.onClickHandle(row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  818804810
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Remind Date", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm.type == "dashboard"
                      ? _c(
                          "div",
                          {
                            staticClass: "font-bold",
                            class: row.dateLeft < 0 && "danger-color",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.formatDateDuration(row.dateLeft)) +
                                " "
                            ),
                          ]
                        )
                      : _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$formatDateTime(row.remindDate)) +
                              " "
                          ),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm.type == "order"
            ? _c("el-table-column", {
                attrs: { label: "Action", width: "140" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "font-size--medium font-bold",
                                  attrs: { size: "medium", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onClickAction(row)
                                    },
                                  },
                                },
                                [_vm._v("Histories")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  169261623
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.query.page,
          limit: _vm.query.limit,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.$emit("fetchData")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.$t("search"))),
                ]),
                _c("el-input", {
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.query.page = 1
                      _vm.fetchData()
                    },
                  },
                  model: {
                    value: _vm.query.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "search", $$v)
                    },
                    expression: "query.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "font-bold font-size--medium",
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.handleSync },
              },
              [_vm._v("Sync Shopee Order")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.data, fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Code", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [_vm._v(_vm._s(row.code))]),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.tagOptions[row.status]
                            ? _vm.tagOptions[row.status].type
                            : "",
                          effect: _vm.tagOptions[row.status]
                            ? _vm.tagOptions[row.status].effect
                            : "dark",
                          size: "small",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.status.replace("_", " ")) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "150", label: "Customer Info" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.customerInfo
                      ? _c("div", [
                          _c("div", [
                            _c("label", [_vm._v("Name: ")]),
                            _c(
                              "span",
                              { staticStyle: { "min-width": "50px" } },
                              [_vm._v(_vm._s(row.customerInfo.name))]
                            ),
                          ]),
                          _c("div", [
                            _c("label", [_vm._v("Phone: ")]),
                            _c(
                              "span",
                              { staticStyle: { "min-width": "50px" } },
                              [_vm._v(_vm._s(row.customerInfo.phone))]
                            ),
                          ]),
                          _c("div", [
                            _c("label", [_vm._v("Address: ")]),
                            _c(
                              "span",
                              { staticStyle: { "min-width": "50px" } },
                              [_vm._v(_vm._s(row.customerInfo.full_address))]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "250px", label: "Products" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(row.shopeeOrderDetails, function (orderDetail) {
                    return _c(
                      "div",
                      {
                        key: orderDetail.id,
                        staticStyle: { "margin-bottom": "10px" },
                      },
                      [
                        _c("div", { staticClass: "space-between" }, [
                          _c("label", [_vm._v(_vm._s(orderDetail.itemName))]),
                          _c("span", { staticStyle: { "min-width": "50px" } }, [
                            _vm._v(
                              "x" +
                                _vm._s(
                                  _vm.$formatNumberVN(orderDetail.quantity)
                                )
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm.$formatNumberVN(orderDetail.finalPrice)
                            ) + "đ"
                          ),
                        ]),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Payment Method", prop: "paymentMethod" },
          }),
          _c("el-table-column", {
            attrs: { label: "Status Link" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.order && !row.order.isDeleted
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              effect: "dark",
                              size: "small",
                            },
                          },
                          [_vm._v(" Linked " + _vm._s(row.order.code) + " ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Total amount", width: "100", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$formatNumberVN(row.moneyProduct)) +
                          "đ "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date created", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$formatDateTime(row.createdTime)) + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Action", width: "150" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return row.status != "CANCELLED"
                      ? [
                          !row.order || (row.order && row.order.isDeleted)
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "font-size--medium font-bold",
                                  attrs: { size: "medium", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleLink(row)
                                    },
                                  },
                                },
                                [_vm._v("Link Order")]
                              )
                            : _c(
                                "el-button",
                                {
                                  staticClass: "font-size--medium font-bold",
                                  attrs: { size: "medium", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleShowOrder(row)
                                    },
                                  },
                                },
                                [_vm._v("Show Order")]
                              ),
                          row.shopeeOrderDetails.some(function (e) {
                            return e.mainItem
                          })
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  !row.giveaway ||
                                  (row.giveaway && row.giveaway.isDeleted)
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass:
                                            "font-size--medium font-bold",
                                          attrs: {
                                            size: "medium",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleLinkGift(row)
                                            },
                                          },
                                        },
                                        [_vm._v("Link Gift")]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          staticClass:
                                            "font-size--medium font-bold",
                                          attrs: {
                                            size: "medium",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleShowGift(row)
                                            },
                                          },
                                        },
                                        [_vm._v("Show Gift")]
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          pageSizes: [12, 24, 48, 64],
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("order-modal", {
        ref: "modal",
        attrs: { disabled: false },
        on: { "submit:ok": _vm.fetchData },
      }),
      _c("give-away-modal", {
        ref: "giftModal",
        attrs: { disabled: false, type: _vm.GiveawayType.Giveaway },
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Order" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "order-info-form",
          attrs: {
            model: _vm.order,
            rules: _vm.rules,
            "label-position": "top",
            disabled: _vm.disabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Order status", prop: "orderStatus" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(_vm.compare, "orderStatus") &&
                    "form-highlight",
                  model: {
                    value: _vm.order.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.order, "status", $$v)
                    },
                    expression: "order.status",
                  },
                },
                _vm._l(_vm.OrderStatus, function (item, key) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: key, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { lazy: true, label: "Gift", name: "gift" } },
            [
              _c("giveaway-table", {
                attrs: { type: _vm.GiveawayType.Giveaway },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { lazy: true, label: "Consign", name: "consign" } },
            [
              _c("giveaway-table", {
                attrs: { type: _vm.GiveawayType.Consign },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
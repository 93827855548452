var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
                _c("el-input", {
                  attrs: {
                    icon: "el-icon-search",
                    placeholder: "Search order code,...",
                  },
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.query.page = 1
                      _vm.fetchOrders()
                    },
                  },
                  model: {
                    value: _vm.query.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "search", $$v)
                    },
                    expression: "query.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  size: "medium",
                  type: "primary",
                  icon: "el-icon-download",
                  loading: _vm.loadingExcel,
                },
                on: { click: _vm.handleExportExcel },
              },
              [_vm._v(" Export excel ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                attrs: { size: "medium", icon: "el-icon-set-up" },
                on: {
                  click: function ($event) {
                    return _vm.$refs.filter.handleOpen()
                  },
                },
              },
              [_vm._v("Filters")]
            ),
            _c(
              "el-button",
              {
                staticClass: "font-bold font-size--medium",
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v(" Add new ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.orders, fit: "" },
          on: {
            "row-click": function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleUpdate($event)
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Code", width: "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [_vm._v(_vm._s(row.code))]),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.tagOptions[row.status]
                            ? _vm.tagOptions[row.status].type
                            : "",
                          effect: _vm.tagOptions[row.status]
                            ? _vm.tagOptions[row.status].effect
                            : "dark",
                          size: "small",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.status) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Customer", width: "auto", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.customer
                      ? _c("div", { staticClass: "label-content" }, [
                          _c("label", { attrs: { for: "" } }),
                          _c("span", { staticClass: "danger-color" }, [
                            _vm._v("GIFT (HÀNG TẶNG KHÔNG THU TIỀN)"),
                          ]),
                        ])
                      : _c("div", [
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Name: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.receiverName))]),
                          ]),
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Phone: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.receiverPhone))]),
                          ]),
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Address: "),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(row.receiverAddress) +
                                  ", " +
                                  _vm._s(row.customer.receiverWardName) +
                                  ", " +
                                  _vm._s(row.customer.receiverDistrictName) +
                                  ", " +
                                  _vm._s(row.customer.receiverCityName)
                              ),
                            ]),
                          ]),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "200px", label: "Products" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(
                    row.depositOrderDetails,
                    function (orderDetail) {
                      return _c(
                        "div",
                        { key: orderDetail.id, staticClass: "space-between" },
                        [
                          _c("label", [
                            _vm._v(_vm._s(orderDetail.product.name)),
                          ]),
                          _c("span", { staticStyle: { "min-width": "50px" } }, [
                            _vm._v(
                              "x" +
                                _vm._s(
                                  _vm.$formatNumberVN(orderDetail.quantity)
                                )
                            ),
                          ]),
                        ]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Price", "min-width": "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "space-between" }, [
                      _c("label", [_vm._v("Total: ")]),
                      _c(
                        "span",
                        { staticClass: "retail-price font-bold color-green" },
                        [
                          _vm._v(
                            _vm._s(_vm.$formatNumberVN(row.moneyProduct)) + "đ"
                          ),
                        ]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$formatDateTime(row.createdAt)) + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.query.page,
          limit: _vm.query.limit,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchOrders,
        },
      }),
      _c("filter-order", { ref: "filter", on: { apply: _vm.handleFilter } }),
      _c("order-modal", {
        ref: "modal",
        attrs: { disabled: false, type: _vm.type },
        on: { "submit:ok": _vm.fetchOrders },
      }),
      _c("SelectDateModal", {
        ref: "SelectDateModal",
        attrs: { typeSelect: "month" },
        on: { submitOk: _vm.handleSelectDateOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
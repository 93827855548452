var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "order-modal",
      attrs: {
        title: _vm.status == "create" ? "New gift" : "Update gift",
        visible: _vm.visible,
        fullscreen: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-row",
            {
              style:
                _vm.type == _vm.GiveawayType.Consign
                  ? "flex-direction: column-reverse; row-gap: 40px;"
                  : "flex-direction: column; row-gap: 40px;",
              attrs: { gutter: 20, type: "flex" },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c("product-order-form", {
                    ref: "product",
                    attrs: {
                      form: _vm.form,
                      statusForm: _vm.status,
                      giveawayStatus: _vm.giveawayStatus,
                      isLinkShopee: _vm.isLinkShopee,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c("customer-order-form", {
                    ref: "customer",
                    attrs: {
                      formStatus: _vm.status,
                      form: _vm.form,
                      giveawayStatus: _vm.giveawayStatus,
                      type: _vm.type,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          [
            _vm.status == "update"
              ? _c(
                  "el-button",
                  {
                    staticClass: "btn-delete",
                    attrs: { loading: _vm.loading },
                    on: { click: _vm.handleDelete },
                  },
                  [_vm._v("Delete")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticClass: "btn-save",
                attrs: { loading: _vm.loading, type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.status == "create" ? _vm.createData() : _vm.updateData()
                  },
                },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Tips",
            visible: _vm.addCustomerDialogVisible,
            width: "30%",
            "before-close": function () {
              _vm.addCustomerDialogVisible = false
            },
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addCustomerDialogVisible = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "Do you want to create a new customer with existing information ?"
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addCustomerDialogVisible = false
                    },
                  },
                },
                [_vm._v("No")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCreateCustomer },
                },
                [_vm._v("Yes")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("customer-dialog", {
        ref: "customerModal",
        on: {
          "submit:success": _vm.handleFetchShopifyOrderAfterCreateCustomer,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ghnApi = void 0;
var _axios = _interopRequireDefault(require("axios"));
var ghnApi = exports.ghnApi = {
  findCity: function findCity() {
    return _axios.default.get("/province", {
      baseURL: 'https://online-gateway.ghn.vn/shiip/public-api/master-data',
      headers: {
        token: '7e29e35b-c38b-11eb-8ea7-7ad2d1e1ce1c'
      },
      timeout: 30000
    });
  },
  findDistrict: function findDistrict(params) {
    return _axios.default.get("/district", {
      baseURL: 'https://online-gateway.ghn.vn/shiip/public-api/master-data',
      headers: {
        token: '7e29e35b-c38b-11eb-8ea7-7ad2d1e1ce1c'
      },
      timeout: 30000,
      params: params
    });
  },
  findWard: function findWard(params) {
    return _axios.default.get("/ward", {
      baseURL: 'https://online-gateway.ghn.vn/shiip/public-api/master-data',
      headers: {
        token: '7e29e35b-c38b-11eb-8ea7-7ad2d1e1ce1c'
      },
      timeout: 30000,
      params: params
    });
  },
  findService: function findService(params) {
    return _axios.default.get("/available-services", {
      baseURL: 'https://online-gateway.ghn.vn/shiip/public-api/v2/shipping-order',
      headers: {
        token: '7e29e35b-c38b-11eb-8ea7-7ad2d1e1ce1c'
      },
      timeout: 30000,
      params: params
    });
  }
};
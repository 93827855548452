var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, "label-position": "top", rules: _vm.rules },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Pickup Address", prop: "pickUpId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            remote: "",
                            "value-key": "id",
                            "remote-method": _vm.fetchData,
                            loading: _vm.loading,
                          },
                          model: {
                            value: _vm.form.pickUpId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "pickUpId", $$v)
                            },
                            expression: "form.pickUpId",
                          },
                        },
                        _vm._l(_vm.pickup, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.id,
                              attrs: {
                                label:
                                  item.address +
                                  " " +
                                  item.wardName +
                                  ", " +
                                  item.districtName +
                                  ", " +
                                  item.cityName,
                                value: item.id,
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(item.name))])]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c("h3", { staticClass: "color-primary font-size--medium" }, [
                    _vm._v("Receiver infomation"),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { margin: "16px 0" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.handleChangeUsingAddress },
                          model: {
                            value: _vm.usingCustomerAddress,
                            callback: function ($$v) {
                              _vm.usingCustomerAddress = $$v
                            },
                            expression: "usingCustomerAddress",
                          },
                        },
                        [_vm._v("Using from customer infomation")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Receiver name", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: _vm.usingCustomerAddress },
                                model: {
                                  value: _vm.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "Receiver phone", prop: "phone" },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: _vm.usingCustomerAddress },
                                model: {
                                  value: _vm.form.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "phone", $$v)
                                  },
                                  expression: "form.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "City", prop: "cityId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.usingCustomerAddress,
                                  },
                                  on: { change: _vm.handleChangeCity },
                                  model: {
                                    value: _vm.form.cityId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "cityId", $$v)
                                    },
                                    expression: "form.cityId",
                                  },
                                },
                                _vm._l(_vm.cities, function (item) {
                                  return _c("el-option", {
                                    key: item.ProvinceID,
                                    attrs: {
                                      label: item.ProvinceName,
                                      value: item.ProvinceID,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "District", prop: "districtId" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.usingCustomerAddress,
                                  },
                                  on: { change: _vm.handleChangeDistrict },
                                  model: {
                                    value: _vm.form.districtId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "districtId", $$v)
                                    },
                                    expression: "form.districtId",
                                  },
                                },
                                _vm._l(_vm.districts, function (item) {
                                  return _c("el-option", {
                                    key: item.DistrictID,
                                    attrs: {
                                      label: item.DistrictName,
                                      value: item.DistrictID,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Ward", prop: "wardCode" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.usingCustomerAddress,
                                  },
                                  model: {
                                    value: _vm.form.wardCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "wardCode", $$v)
                                    },
                                    expression: "form.wardCode",
                                  },
                                },
                                _vm._l(_vm.wards, function (item) {
                                  return _c("el-option", {
                                    key: item.WardCode,
                                    attrs: {
                                      label: item.WardName,
                                      value: item.WardCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Receiver address",
                                prop: "address",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: _vm.usingCustomerAddress },
                                model: {
                                  value: _vm.form.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "address", $$v)
                                  },
                                  expression: "form.address",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Service", prop: "serviceId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "" },
                          model: {
                            value: _vm.form.serviceId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "serviceId", $$v)
                            },
                            expression: "form.serviceId",
                          },
                        },
                        _vm._l(_vm.services, function (item) {
                          return _c("el-option", {
                            key: item.service_id,
                            attrs: {
                              label: item.short_name,
                              value: item.service_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c("h3", { staticClass: "color-primary font-size--medium" }, [
                    _vm._v("Package"),
                  ]),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Length (cm)", prop: "length" } },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: true,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.length,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "length", $$v)
                                  },
                                  expression: "form.length",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Width (cm)", prop: "width" } },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: true,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "width", $$v)
                                  },
                                  expression: "form.width",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Height (cm)", prop: "height" } },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: true,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "height", $$v)
                                  },
                                  expression: "form.height",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6, offset: 0 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Weight (g)", prop: "weight" } },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: true,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                model: {
                                  value: _vm.form.weight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "weight", $$v)
                                  },
                                  expression: "form.weight",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Shipping charge by",
                        prop: "isFreeship",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "medium" },
                          model: {
                            value: _vm.form.isFreeship,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isFreeship", $$v)
                            },
                            expression: "form.isFreeship",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: true } }, [
                            _vm._v("Shiratori"),
                          ]),
                          _c("el-radio-button", { attrs: { label: false } }, [
                            _vm._v("Customer"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Cod", prop: "cod" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: {
                              alias: "decimal",
                              groupSeparator: ".",
                              rightAlign: true,
                              autoGroup: true,
                              repeat: 20,
                              autoUnmask: true,
                              allowMinus: false,
                            },
                            expression:
                              "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                          },
                        ],
                        model: {
                          value: _vm.form.cod,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cod", $$v)
                          },
                          expression: "form.cod",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Required Note", prop: "requiredNote" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.requiredNote,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "requiredNote", $$v)
                            },
                            expression: "form.requiredNote",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "CHOTHUHANG" } }, [
                            _vm._v("Cho thử hàng"),
                          ]),
                          _c(
                            "el-radio",
                            { attrs: { label: "CHOXEMHANGKHONGTHU" } },
                            [_vm._v("Cho xem hàng không thử")]
                          ),
                          _c(
                            "el-radio",
                            { attrs: { label: "KHONGCHOXEMHANG" } },
                            [_vm._v("Không cho xem hàng")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c("el-form-item", { attrs: { label: "Estimated amount" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c("el-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: {
                                alias: "decimal",
                                groupSeparator: ".",
                                rightAlign: true,
                                autoGroup: true,
                                repeat: 20,
                                autoUnmask: true,
                                allowMinus: false,
                              },
                              expression:
                                "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: true, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                            },
                          ],
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.totalMoney,
                            callback: function ($$v) {
                              _vm.totalMoney = $$v
                            },
                            expression: "totalMoney",
                          },
                        }),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: {
                              type: "primary",
                              loading: _vm.loadingButton,
                            },
                            on: { click: _vm.handleEstimate },
                          },
                          [_vm._v("Estimate")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DepositOrderType = exports.DepositOrderStatus = void 0;
var DepositOrderType;
(function (DepositOrderType) {
  DepositOrderType["Order"] = "ORDER";
  DepositOrderType["Gift"] = "GIFT";
})(DepositOrderType || (exports.DepositOrderType = DepositOrderType = {}));
var DepositOrderStatus;
(function (DepositOrderStatus) {
  DepositOrderStatus["Pending"] = "PENDING";
  DepositOrderStatus["Delivering"] = "DELIVERING";
  DepositOrderStatus["Complete"] = "COMPLETE";
  DepositOrderStatus["Cancel"] = "CANCEL";
})(DepositOrderStatus || (exports.DepositOrderStatus = DepositOrderStatus = {}));
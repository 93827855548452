"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GiveawayType = exports.GiveawayStatus = void 0;
var GiveawayType;
(function (GiveawayType) {
  GiveawayType["Giveaway"] = "GIVEAWAY";
  GiveawayType["Consign"] = "CONSIGN";
})(GiveawayType || (exports.GiveawayType = GiveawayType = {}));
var GiveawayStatus;
(function (GiveawayStatus) {
  GiveawayStatus["Pending"] = "PENDING";
  GiveawayStatus["Complete"] = "COMPLETE"; // Da tang
})(GiveawayStatus || (exports.GiveawayStatus = GiveawayStatus = {}));
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Order" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "order-info-form",
          attrs: {
            model: _vm.order,
            rules: _vm.rules,
            "label-position": "top",
            disabled: _vm.disabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Order status", prop: "orderStatus" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(_vm.compare, "orderStatus") &&
                    "form-highlight",
                  model: {
                    value: _vm.order.orderStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.order, "orderStatus", $$v)
                    },
                    expression: "order.orderStatus",
                  },
                },
                _vm._l(_vm.OrderStatus, function (item, key) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: key, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Delivery appointment date",
                prop: "deliveryAppointmentDate",
              },
            },
            [
              _c("el-date-picker", {
                class:
                  _vm.$getValuePropObj(
                    _vm.compare,
                    "orderProgress.deliveryAppointmentDate"
                  ) && "form-highlight",
                staticStyle: { width: "100%" },
                attrs: { "value-format": "dd/MM/yyyy", format: "dd/MM/yyyy" },
                model: {
                  value: _vm.orderProgress.deliveryAppointmentDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderProgress, "deliveryAppointmentDate", $$v)
                  },
                  expression: "orderProgress.deliveryAppointmentDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Payment type estimate",
                prop: "paymentTypeEstimate",
              },
            },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(
                      _vm.compare,
                      "orderProgress.paymentTypeEstimate"
                    ) && "form-highlight",
                  model: {
                    value: _vm.orderProgress.paymentTypeEstimate,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderProgress, "paymentTypeEstimate", $$v)
                    },
                    expression: "orderProgress.paymentTypeEstimate",
                  },
                },
                _vm._l(_vm.PaymentType, function (item, key) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: key, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Delivery estimate", prop: "deliveryEstimate" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(
                      _vm.compare,
                      "orderProgress.deliveryEstimate"
                    ) && "form-highlight",
                  model: {
                    value: _vm.orderProgress.deliveryEstimate,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderProgress, "deliveryEstimate", $$v)
                    },
                    expression: "orderProgress.deliveryEstimate",
                  },
                },
                _vm._l(_vm.DeliveryPartner, function (item, key) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: key, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Package status", prop: "packageStatus" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(
                      _vm.compare,
                      "orderProgress.packageStatus"
                    ) && "form-highlight",
                  model: {
                    value: _vm.orderProgress.packageStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderProgress, "packageStatus", $$v)
                    },
                    expression: "orderProgress.packageStatus",
                  },
                },
                _vm._l(_vm.PackageStatus, function (item, key) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: key, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Export status", prop: "exportStatus" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(
                      _vm.compare,
                      "orderProgress.exportStatus"
                    ) && "form-highlight",
                  model: {
                    value: _vm.orderProgress.exportStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderProgress, "exportStatus", $$v)
                    },
                    expression: "orderProgress.exportStatus",
                  },
                },
                _vm._l(_vm.ExportStatus, function (item, key) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: key, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Return status", prop: "returnStatus" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(
                      _vm.compare,
                      "orderProgress.returnStatus"
                    ) && "form-highlight",
                  model: {
                    value: _vm.orderProgress.returnStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.orderProgress, "returnStatus", $$v)
                    },
                    expression: "orderProgress.returnStatus",
                  },
                },
                _vm._l(_vm.ReturnStatus, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: {
                      label: _vm.deliveryPartnerTrans[item],
                      value: item,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Invoice No" } },
            [
              _c("el-input", {
                class:
                  _vm.$getValuePropObj(_vm.compare, "invoice") &&
                  "form-highlight",
                model: {
                  value: _vm.order.invoice,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "invoice", $$v)
                  },
                  expression: "order.invoice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Note" } },
            [
              _c("el-input", {
                class:
                  _vm.$getValuePropObj(
                    _vm.compare,
                    "orderProgress.orderNote"
                  ) && "form-highlight",
                attrs: { rows: 3, type: "textarea" },
                model: {
                  value: _vm.orderProgress.orderNote,
                  callback: function ($$v) {
                    _vm.$set(_vm.orderProgress, "orderNote", $$v)
                  },
                  expression: "orderProgress.orderNote",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Invoice Link" } },
            [
              _c("el-input", {
                class:
                  _vm.$getValuePropObj(_vm.compare, "invoiceLink") &&
                  "form-highlight",
                attrs: { rows: 3, type: "textarea" },
                model: {
                  value: _vm.order.invoiceLink,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "invoiceLink", $$v)
                  },
                  expression: "order.invoiceLink",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Sale channel", prop: "saleChannelId" } },
            [
              _c(
                "el-select",
                {
                  class:
                    _vm.$getValuePropObj(_vm.compare, "saleChannelId") &&
                    "form-highlight",
                  model: {
                    value: _vm.order.saleChannelId,
                    callback: function ($$v) {
                      _vm.$set(_vm.order, "saleChannelId", $$v)
                    },
                    expression: "order.saleChannelId",
                  },
                },
                _vm._l(_vm.SaleChannel, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Remind Date", prop: "remindDate" } },
            [
              _c("el-date-picker", {
                class:
                  _vm.$getValuePropObj(_vm.compare, "remindDate") &&
                  "form-highlight",
                staticStyle: { width: "100%" },
                attrs: { "value-format": "timestamp", format: "dd/MM/yyyy" },
                model: {
                  value: _vm.order.remindDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.order, "remindDate", $$v)
                  },
                  expression: "order.remindDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Handled", prop: "paymentStatus" } },
            [
              _c(
                "el-radio-group",
                {
                  class:
                    _vm.$getValuePropObj(_vm.compare, "isHandled") &&
                    "form-highlight",
                  attrs: { size: "medium" },
                  model: {
                    value: _vm.order.isHandled,
                    callback: function ($$v) {
                      _vm.$set(_vm.order, "isHandled", $$v)
                    },
                    expression: "order.isHandled",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: true } }, [
                    _vm._v("〇"),
                  ]),
                  _c("el-radio-button", { attrs: { label: false } }, [
                    _vm._v("X"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
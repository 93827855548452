var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
                _c("el-date-picker", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "daterange",
                    "range-separator": "To",
                    format: "dd/MM/yyyy",
                    "value-format": "yyyy-MM-dd",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                    size: "medium",
                  },
                  on: {
                    change: function ($event) {
                      _vm.query.page = 1
                      _vm.fetchData()
                    },
                  },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                attrs: {
                  type: "primary",
                  icon: "el-icon-refresh",
                  loading: _vm.loadingExcel,
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v(" Refresh ")]
            ),
            _c(
              "el-dropdown",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { trigger: "click" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "font-size--medium font-bold",
                    attrs: {
                      size: "medium",
                      type: "primary",
                      icon: "el-icon-download",
                      loading: _vm.loadingExcel,
                    },
                  },
                  [_vm._v(" Export excel ")]
                ),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "menu-dropdown-custom",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleExport("")
                            },
                          },
                        },
                        [_vm._v(" Export report ")]
                      ),
                    ]),
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleExport(
                                _vm.ExportExcelType.RealPublish
                              )
                            },
                          },
                        },
                        [_vm._v(" Export report (publish) ")]
                      ),
                    ]),
                    _c("el-dropdown-item", [
                      _c(
                        "div",
                        {
                          staticClass: "font-bold font-size--medium",
                          on: {
                            click: function ($event) {
                              return _vm.handleExport(
                                _vm.ExportExcelType.RealNoPublish
                              )
                            },
                          },
                        },
                        [_vm._v(" Export report (not publish) ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.transformData,
            fit: "",
            "span-method": _vm.objectSpanMethod,
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Product", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "150", label: "Lot" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(row.lotNumber || "--")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "90", label: "Manufacture date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$formatDate(row.dateOfManufacture))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Expired date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.$formatDate(row.expired))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Expired" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.getExpired(row.expired)) + " months"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Expired Percent", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(_vm._s(_vm.getPercentExpired(row)) + "%"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "Start", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.quantityStart)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "right", label: "In stock", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$formatNumberVN(row.quantityImport)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "Export" } },
            [
              _c("el-table-column", {
                attrs: { align: "right", label: "Giveaway", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$formatNumberVN(row.quantityExportGiveaway)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", label: "Order", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$formatNumberVN(row.quantityExportOrder)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { align: "right", label: "Start Deposited", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.depositStart)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            { attrs: { align: "center", label: "Consignment" } },
            [
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "Deposited" } },
                [
                  _c("el-table-column", {
                    attrs: { align: "right", label: "Order", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$formatNumberVN(row.deposit)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "right", label: "Giveaway", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$formatNumberVN(row.depositGift)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "Sold" } },
                [
                  _c("el-table-column", {
                    attrs: { align: "right", label: "Order", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$formatNumberVN(row.saleConsign)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "right", label: "Giveaway", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatNumberVN(row.saleConsignGiveaway)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { align: "center", label: "Return" } },
                [
                  _c("el-table-column", {
                    attrs: { align: "right", label: "Order", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatNumberVN(row.quantityReturnOrder)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "right", label: "Giveaway", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$formatNumberVN(row.quantityReturnGift)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("el-table-column", {
                attrs: { align: "right", label: "Remaining", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$formatNumberVN(row.totalRemain)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: { align: "right", label: "End", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatNumberVN(row.quantityEnd)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "90", label: "Date Import" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._l(row.importDates, function (dates, indexDate) {
                      return _c("div", { key: indexDate }, [
                        _vm._v(_vm._s(_vm.$formatDate(dates))),
                        _c("br"),
                      ])
                    }),
                    !row.importDates ? _c("div", [_vm._v("--")]) : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          page: _vm.query.page,
          limit: _vm.query.limit,
          total: _vm.total,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "filter-container filter-drawer" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _vm._l(_vm.categoryLevels, function (categoryLevel, i) {
                return [
                  categoryLevel.level == 1 ||
                  _vm.queryLevel["parent" + (categoryLevel.level - 1) + "Id"]
                    ? _c(
                        "div",
                        {
                          key: i,
                          staticClass: "filter-item",
                          staticStyle: { "margin-right": "5px" },
                        },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Cấp " + _vm._s(categoryLevel.level)),
                          ]),
                          _c(
                            "el-select",
                            {
                              attrs: { disabled: categoryLevel.level == 3 },
                              on: {
                                change: function ($event) {
                                  return _vm.handleChangeCategoryLevel(
                                    $event,
                                    categoryLevel.level
                                  )
                                },
                              },
                              model: {
                                value:
                                  _vm.queryLevel[
                                    "parent" + categoryLevel.level + "Id"
                                  ],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.queryLevel,
                                    "parent" + categoryLevel.level + "Id",
                                    $$v
                                  )
                                },
                                expression:
                                  "queryLevel[`parent${categoryLevel.level}Id`]",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "All", value: undefined },
                              }),
                              _vm._l(categoryLevel.categories, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
          _c(
            "el-button",
            {
              staticClass: "font-bold font-size--medium",
              attrs: { type: "primary", size: "medium", icon: "el-icon-plus" },
              on: { click: _vm.handleCreate },
            },
            [_vm._v("Add new")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: { data: _vm.data, border: "", fit: "" },
          on: { "row-click": _vm.handleUpdate },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Category name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("span", { staticClass: "font-bold" }, [
                      _vm._v(" " + _vm._s(row.name) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "Level", prop: "level" } }),
          _c("el-table-column", {
            attrs: { label: "Description", prop: "description" },
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("category-product-modal", {
        ref: "categoryProductModal",
        attrs: { levelSelected: _vm.levelSelected, queryLevel: _vm.queryLevel },
        on: { "submit:success": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
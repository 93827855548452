var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Search")]),
                _c("el-date-picker", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    type: "monthrange",
                    "range-separator": "To",
                    format: "MM/yyyy",
                    "value-format": "yyyy-MM",
                    "start-placeholder": "Start date",
                    "end-placeholder": "End date",
                    size: "medium",
                  },
                  on: {
                    change: function ($event) {
                      _vm.query.page = 1
                      _vm.fetchData()
                    },
                  },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                attrs: {
                  type: "primary",
                  icon: "el-icon-refresh",
                  loading: _vm.loadingExcel,
                },
                on: { click: _vm.fetchData },
              },
              [_vm._v(" Refresh ")]
            ),
            _c(
              "el-button",
              {
                staticClass: "font-size--medium font-bold",
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  size: "medium",
                  type: "primary",
                  icon: "el-icon-download",
                  loading: _vm.loadingExcel,
                },
                on: { click: _vm.handleExportExcel },
              },
              [_vm._v(" Export excel ")]
            ),
          ],
          1
        ),
      ]),
      _vm._l(_vm.transformData, function (item) {
        return _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            key: item.customerId,
            staticStyle: { "margin-bottom": "30px" },
            attrs: { data: _vm.getDataProductByMonth(item.products), fit: "" },
          },
          [
            _c("el-table-column", {
              attrs: { fixed: "", label: item.customerName, "min-width": 200 },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v(_vm._s(row.productName)),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _vm._l(_vm.monthRange, function (month) {
              return _c(
                "el-table-column",
                { key: month, attrs: { align: "center", label: month } },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "right",
                      label: "Deposited",
                      "min-width": 100,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatNumberVN(
                                      row[
                                        month.replace(".", "") + "totalDeposit"
                                      ]
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "right",
                      label: "Gift goods",
                      "min-width": 100,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatNumberVN(
                                      row[
                                        month.replace(".", "") +
                                          "totalDepositGift"
                                      ]
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "right",
                      label: "Sold from consignment",
                      "min-width": 100,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatNumberVN(
                                      row[
                                        month.replace(".", "") +
                                          "totalSaleConsign"
                                      ]
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "right",
                      label: "Sold from gift goods",
                      "min-width": 100,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatNumberVN(
                                      row[
                                        month.replace(".", "") +
                                          "totalSaleGiveaway"
                                      ]
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "right",
                      label: "Remaining amount before recall",
                      "min-width": 100,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatNumberVN(
                                      row[
                                        month.replace(".", "") +
                                          "totalRemainBeforeReturn"
                                      ]
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "right",
                      label: "Consignment recovery",
                      "min-width": 100,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatNumberVN(
                                      row[
                                        month.replace(".", "") +
                                          "totalReturnConsignment"
                                      ]
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "right",
                      label: "Remaining Total",
                      "min-width": 100,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$formatNumberVN(
                                      row[
                                        month.replace(".", "") + "totalRemain"
                                      ]
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
      _c("SelectDateModal", {
        ref: "SelectDateModal",
        attrs: { typeSelect: "month" },
        on: { submitOk: _vm.handleSelectDateOk },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("label", { attrs: { for: "" } }, [_vm._v("Published")]),
            _c(
              "el-select",
              {
                attrs: { clearable: "" },
                on: {
                  change: function ($event) {
                    _vm.query.page = 1
                    _vm.fetchData()
                  },
                },
                model: {
                  value: _vm.query.isPublished,
                  callback: function ($$v) {
                    _vm.$set(_vm.query, "isPublished", $$v)
                  },
                  expression: "query.isPublished",
                },
              },
              [
                _c("el-option", { attrs: { value: true, label: "Yes" } }),
                _c("el-option", { attrs: { value: false, label: "No" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Code", width: "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("p", [_vm._v(_vm._s(row.code))]),
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm.tagOptions[row.orderStatus].type,
                          effect: _vm.tagOptions[row.orderStatus].effect,
                          size: "small",
                        },
                      },
                      [_vm._v(" " + _vm._s(row.orderStatus) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
          false
            ? _c("el-table-column", {
                attrs: { label: "Staff" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("div", { staticClass: "label-content" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Name: "),
                            ]),
                            _c("span", [_vm._v(_vm._s(row.staff.name))]),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2196804988
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { label: "Receiver" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Name: ")]),
                      _c("span", [_vm._v(_vm._s(row.receiverName))]),
                    ]),
                    _c("div", { staticClass: "label-content" }, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("Phone: ")]),
                      _c("span", [_vm._v(_vm._s(row.receiverPhone))]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " + _vm._s(_vm.$formatDate(row.createdAt)) + " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Published" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-checkbox", {
                      attrs: {
                        value:
                          row.orderProgress.deliveryFeeType ==
                          _vm.DeliveryFeeType.Publish,
                        disabled: "",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Price" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "space-between" }, [
                      _c("label", [_vm._v("Origin: ")]),
                      _c("span", { staticClass: "retail-price font-bold" }, [
                        _vm._v(
                          _vm._s(_vm.$formatNumberVN(row.totalOriginPrice)) +
                            "đ"
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "space-between" }, [
                      _c("label", [_vm._v("Total: ")]),
                      _c(
                        "span",
                        { staticClass: "retail-price font-bold color-green" },
                        [
                          _vm._v(
                            _vm._s(_vm.$formatNumberVN(row.totalPrice)) + "đ"
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "space-between" }, [
                      _c("label", [_vm._v("Profit: ")]),
                      _c(
                        "span",
                        { staticClass: "retail-price font-bold color-yellow" },
                        [_vm._v(_vm._s(_vm.$formatNumberVN(row.profit)) + "đ")]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
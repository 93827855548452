"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customerChart = customerChart;
exports.dashboardApi = void 0;
exports.getDashboardCustomer = getDashboardCustomer;
exports.getDashboardDriver = getDashboardDriver;
exports.getDashboardOrder = getDashboardOrder;
exports.getDashboardTransport = getDashboardTransport;
exports.orderBookingChart = orderBookingChart;
exports.orderShoppingChart = orderShoppingChart;
exports.orderTransportChart = orderTransportChart;
exports.today = void 0;
exports.top5Customer = top5Customer;
exports.top5Driver = top5Driver;
var _request = _interopRequireDefault(require("@/utils/request"));
var dashboardApi = exports.dashboardApi = {
  customerSummary: function customerSummary() {
    return (0, _request.default)({
      url: '/dashboard/customer/summary'
    });
  },
  orderSummary: function orderSummary() {
    return (0, _request.default)({
      url: '/dashboard/order/summary'
    });
  },
  orderDetailSummary: function orderDetailSummary() {
    return (0, _request.default)({
      url: '/dashboard/order/detail/summary'
    });
  },
  commonSummary: function commonSummary() {
    return (0, _request.default)({
      url: '/dashboard/common/summary'
    });
  },
  profitSummary: function profitSummary() {
    return (0, _request.default)({
      url: '/dashboard/profit/summary'
    });
  },
  customerGroup: function customerGroup(params) {
    return (0, _request.default)({
      url: '/dashboard/customer/group/date',
      params: params
    });
  },
  orderGroup: function orderGroup(params) {
    return (0, _request.default)({
      url: '/dashboard/order/group/date',
      params: params
    });
  },
  staffTop: function staffTop(params) {
    return (0, _request.default)({
      url: '/dashboard/staff/top',
      params: params
    });
  },
  productTop: function productTop(params) {
    return (0, _request.default)({
      url: '/dashboard/product/top',
      params: params
    });
  }
};
function getDashboardOrder(params) {
  return (0, _request.default)({
    url: "/dashboard/order",
    method: "get",
    params: params
  });
}
var today = exports.today = function today() {
  return (0, _request.default)({
    url: "/dashboard/today"
  });
};
function getDashboardTransport(params) {
  return (0, _request.default)({
    url: "/dashboard/orderTransport",
    method: "get",
    params: params
  });
}
function getDashboardDriver() {
  return (0, _request.default)({
    url: "/dashboard/driver",
    method: "get"
  });
}
function getDashboardCustomer(params) {
  return (0, _request.default)({
    url: "/dashboard/customer",
    method: "get",
    params: params
  });
}
function customerChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/customer/last30",
    method: "get",
    params: listQuery
  });
}
function orderShoppingChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/order/last30",
    method: "get",
    params: listQuery
  });
}
function orderTransportChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/orderTransport/last30",
    method: "get",
    params: listQuery
  });
}
function orderBookingChart(listQuery) {
  return (0, _request.default)({
    url: "/dashboard/bookingTransport/last30",
    method: "get",
    params: listQuery
  });
}
function top5Driver() {
  return (0, _request.default)({
    url: "/dashboard/driver/top5",
    method: "get"
  });
}
function top5Customer() {
  return (0, _request.default)({
    url: "/dashboard/customer/top5",
    method: "get"
  });
}
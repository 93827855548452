"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.productGalleryApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var productGalleryApi = exports.productGalleryApi = {
  delete: function _delete(id) {
    return (0, _request.default)({
      url: "/productGallery/".concat(id, "/delete"),
      method: "post"
    });
  }
};
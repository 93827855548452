var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Product" } },
    [
      _c("el-form", {
        ref: "form",
        staticClass: "order-info-form",
        attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
      }),
      _c(
        "div",
        {
          staticClass: "product-list",
          class: _vm.$getValuePropObj(_vm.compare, "productsLength")
            ? "form-highlight"
            : "",
        },
        [
          _c("div", { staticClass: "header-card" }, [
            _c("label", { attrs: { for: "" } }, [_vm._v("Product")]),
            _c("label", { attrs: { for: "" } }, [_vm._v("Published")]),
            _c("label", { attrs: { for: "" } }, [_vm._v("Lotnumber")]),
            _c("label", { attrs: { for: "" } }, [_vm._v("Quantity")]),
          ]),
          _c(
            "div",
            { staticClass: "body-card" },
            [
              [
                _vm.dataTable && _vm.dataTable.length
                  ? _vm._l(_vm.dataTable, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "product-item",
                          class:
                            _vm.compare.products &&
                            _vm.$getValuePropObj(
                              _vm.compare.products[index],
                              "id"
                            )
                              ? "form-highlight product-highlight"
                              : "",
                        },
                        [
                          _c("div", { staticClass: "product-name" }, [
                            _c("div", [
                              item.productGalleries &&
                              item.productGalleries.length
                                ? _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$baseUrlMedia +
                                        item.productGalleries[0].image,
                                      width: "50",
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _c("div", [
                              _c("span", [_vm._v(" " + _vm._s(item.name))]),
                              !_vm.disabled
                                ? _c(
                                    "div",
                                    [
                                      _vm.orderStatus !=
                                        _vm.DepositOrderStatus.Complete &&
                                      !_vm.checkIsSoldProduct(item)
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "Edit",
                                                placement: "top",
                                                effect: "dark",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-edit primary-color font-bold",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleEditProd(
                                                      index,
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.orderStatus !=
                                        _vm.DepositOrderStatus.Complete &&
                                      !_vm.checkIsSoldProduct(item)
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: "Remove",
                                                placement: "top",
                                                effect: "dark",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-close danger-color font-bold",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleRemoveProd(
                                                      index,
                                                      item
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                          _c("div", { staticClass: "price" }, [
                            item.isPublished
                              ? _c("label", [_vm._v("〇")])
                              : _c("label", [_vm._v("X")]),
                          ]),
                          _c("div", { staticClass: "price" }, [
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", [_vm._v(_vm._s(item.lotNumber))]),
                            ]),
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Expired: "),
                              ]),
                              _c("span", { staticClass: "origin-price" }, [
                                _vm._v(_vm._s(_vm.$formatDate(item.expired))),
                              ]),
                            ]),
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Manufacture date: "),
                              ]),
                              _c("span", { staticClass: "origin-price" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatDate(item.dateOfManufacture)
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "space-between" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Quantity: "),
                              ]),
                              _c("span", { staticClass: "origin-price" }, [
                                _vm._v(_vm._s(item.productQuantity)),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "column",
                              },
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: {
                                      alias: "decimal",
                                      groupSeparator: ".",
                                      rightAlign: false,
                                      autoGroup: true,
                                      repeat: 20,
                                      autoUnmask: true,
                                      allowMinus: false,
                                    },
                                    expression:
                                      "{alias: 'decimal' ,groupSeparator: '.' ,rightAlign: false, autoGroup: true, repeat: 20, autoUnmask: true, allowMinus: false}",
                                  },
                                ],
                                class:
                                  _vm.compare.products &&
                                  !_vm.$getValuePropObj(
                                    _vm.compare.products[index],
                                    "id"
                                  ) &&
                                  _vm.$getValuePropObj(
                                    _vm.compare.products[index],
                                    "quantity"
                                  ) &&
                                  "form-highlight",
                                attrs: {
                                  disabled:
                                    _vm.orderStatus ==
                                      _vm.DepositOrderStatus.Complete ||
                                    _vm.disabled,
                                },
                                model: {
                                  value: item.quantity,
                                  callback: function ($$v) {
                                    _vm.$set(item, "quantity", $$v)
                                  },
                                  expression: "item.quantity",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "margin-top": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          icon: "el-icon-minus",
                                          size: "mini",
                                          disabled:
                                            _vm.orderStatus ==
                                              _vm.DepositOrderStatus.Complete ||
                                            _vm.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            item.quantity -= 1
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          icon: "el-icon-plus",
                                          size: "mini",
                                          disabled:
                                            _vm.orderStatus ==
                                              _vm.DepositOrderStatus.Complete ||
                                            _vm.disabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            item.quantity++
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    })
                  : _vm._e(),
                !!_vm.form.customerId &&
                _vm.orderStatus != _vm.DepositOrderStatus.Complete &&
                !_vm.disabled
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "margin-top": "16px" },
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-plus",
                        },
                        on: { click: _vm.handleAddProduct },
                      },
                      [_vm._v("Add product")]
                    )
                  : _vm._e(),
                _vm.form.details && _vm.form.details.length
                  ? _c("div", { staticClass: "summary" }, [
                      _c("div", [
                        _c("h3", { staticStyle: { color: "black" } }, [
                          _vm._v("Summary"),
                        ]),
                      ]),
                      _c("div"),
                      _c("div"),
                      _c("div", [
                        _c("div", { staticClass: "space-between" }, [
                          _c("label", [_vm._v("Quantity: ")]),
                          _c(
                            "span",
                            {
                              staticClass: "retail-price font-bold color-green",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatNumberVN(_vm.summary.quantity)
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ],
              _vm.form.details && !_vm.form.details.length
                ? _c("no-data")
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c("add-product-order-modal", {
        ref: "productModal",
        attrs: {
          productsSelected: _vm.form.details,
          type: _vm.form.type,
          customerId: _vm.form.customerId,
        },
        on: { choose: _vm.handleChangeProduct },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Customer" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Choose customer", prop: "customerId" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-select",
                    {
                      class:
                        _vm.$getValuePropObj(_vm.compare, "customerId") &&
                        "form-highlight",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        disabled: _vm.disableForm,
                      },
                      on: { change: _vm.handleChangeCustomer },
                      model: {
                        value: _vm.form.customerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "customerId", $$v)
                        },
                        expression: "form.customerId",
                      },
                    },
                    _vm._l(_vm.customers, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            item.name +
                            " | Phone: " +
                            item.phone +
                            " | Code: " +
                            item.code +
                            " | Email: " +
                            item.email,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                  _c("el-button", {
                    staticStyle: {
                      position: "absolute",
                      right: "0",
                      top: "3px",
                    },
                    attrs: {
                      type: "primary",
                      icon: "el-icon-plus",
                      size: "mini",
                      disabled: _vm.disableForm,
                    },
                    on: { click: _vm.handleCreateCustomer },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.checkCustomerMonthBirthday
            ? _c("el-alert", {
                attrs: {
                  title:
                    "Customer's birthday: " +
                    _vm.form.detailCustomer.customer.birthday,
                  type: "success",
                },
              })
            : _vm._e(),
          _c("h3", { staticClass: "color-primary font-size--medium" }, [
            _vm._v("Receiver infomation"),
          ]),
          _c(
            "div",
            { staticStyle: { margin: "16px 0" } },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { disabled: _vm.disableForm },
                  on: { change: _vm.handleChangeUsingAddress },
                  model: {
                    value: _vm.usingCustomerAddress,
                    callback: function ($$v) {
                      _vm.usingCustomerAddress = $$v
                    },
                    expression: "usingCustomerAddress",
                  },
                },
                [_vm._v("Using from customer infomation")]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Receiver name", prop: "receiverName" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.disableForm },
                        model: {
                          value: _vm.form.receiverName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "receiverName", $$v)
                          },
                          expression: "form.receiverName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Receiver phone", prop: "receiverPhone" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.disableForm },
                        model: {
                          value: _vm.form.receiverPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "receiverPhone", $$v)
                          },
                          expression: "form.receiverPhone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 8, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "City", prop: "receiverCityId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", disabled: _vm.disableForm },
                          on: { change: _vm.handleChangeCity },
                          model: {
                            value: _vm.form.receiverCityId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "receiverCityId", $$v)
                            },
                            expression: "form.receiverCityId",
                          },
                        },
                        _vm._l(_vm.cities, function (item) {
                          return _c("el-option", {
                            key: item.ProvinceID,
                            attrs: {
                              label: item.ProvinceName,
                              value: item.ProvinceID,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "District", prop: "receiverDistrictId" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", disabled: _vm.disableForm },
                          on: { change: _vm.handleChangeDistrict },
                          model: {
                            value: _vm.form.receiverDistrictId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "receiverDistrictId", $$v)
                            },
                            expression: "form.receiverDistrictId",
                          },
                        },
                        _vm._l(_vm.districts, function (item) {
                          return _c("el-option", {
                            key: item.DistrictID,
                            attrs: {
                              label: item.DistrictName,
                              value: item.DistrictID,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ward", prop: "receiverWardCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", disabled: _vm.disableForm },
                          model: {
                            value: _vm.form.receiverWardCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "receiverWardCode", $$v)
                            },
                            expression: "form.receiverWardCode",
                          },
                        },
                        _vm._l(_vm.wards, function (item) {
                          return _c("el-option", {
                            key: item.WardCode,
                            attrs: {
                              label: item.WardName,
                              value: item.WardCode,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24, offset: 0 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Receiver address",
                        prop: "receiverAddress",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.disableForm },
                        model: {
                          value: _vm.form.receiverAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "receiverAddress", $$v)
                          },
                          expression: "form.receiverAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("customer-dialog", {
        ref: "customerModal",
        on: { "submit:success": _vm.fetchCustomers },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-form",
    { attrs: { title: "Customer" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Choose customer", prop: "customerId" } },
            [
              _c(
                "div",
                [
                  _c(
                    "el-select",
                    {
                      class:
                        _vm.$getValuePropObj(_vm.compare, "customerId") &&
                        "form-highlight",
                      attrs: {
                        clearable: "",
                        filterable: "",
                        disabled: _vm.disableForm,
                      },
                      on: { change: _vm.handleChangeCustomer },
                      model: {
                        value: _vm.form.customerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "customerId", $$v)
                        },
                        expression: "form.customerId",
                      },
                    },
                    _vm._l(_vm.customers, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            item.name +
                            " | Phone: " +
                            item.phone +
                            " | Code: " +
                            item.code +
                            " | Email: " +
                            item.email,
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                  _c("el-button", {
                    staticStyle: {
                      position: "absolute",
                      right: "0",
                      top: "3px",
                    },
                    attrs: {
                      type: "primary",
                      icon: "el-icon-plus",
                      size: "mini",
                      disabled: _vm.disableForm,
                    },
                    on: { click: _vm.handleCreateCustomer },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.checkCustomerMonthBirthday
            ? _c("el-alert", {
                attrs: {
                  title:
                    "Customer's birthday: " +
                    _vm.form.detailCustomer.customer.birthday,
                  type: "success",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("customer-dialog", {
        ref: "customerModal",
        on: { "submit:success": _vm.fetchCustomers },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trackingUseBirthdayDiscountApi = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var trackingUseBirthdayDiscountApi = exports.trackingUseBirthdayDiscountApi = {
  findAll: function findAll(params) {
    return (0, _request.default)({
      url: "/trackingUseBirthdayDiscount",
      params: params
    });
  }
};
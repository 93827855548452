var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "30px",
        title: "Choose Rank",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.form, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Rank", prop: "customerRankId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.form.customerRankId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "customerRankId", $$v)
                    },
                    expression: "form.customerRankId",
                  },
                },
                _vm._l(_vm.$store.state.customerRank.list, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("div"),
          _c(
            "el-button",
            {
              staticClass: "btn-save",
              attrs: { type: "primary", loading: _vm.loadingButton },
              on: {
                click: function ($event) {
                  _vm.status == "update" ? _vm.updateData() : _vm.approveData()
                },
              },
            },
            [_vm._v(_vm._s(_vm.status == "update" ? "Update" : "Approve"))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }